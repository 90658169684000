import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Box, Typography, ToggleButton, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DateCalendar } from "@mui/x-date-pickers-pro";
import { getCountries, getSportsByCountry } from "@services/Network";
import {
  useAdminDemoSessionPost,
  useAdminDemoSessionSlotGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { isValidEmail } from "@utils/isValidEmail";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { ORGANIZATION_TYPE_OPTIONS } from "@utils/constants";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { WEBSITE_REGEX } from "@utils/validation";
import { StyledFormLabel } from "@components/StyledFormLabel";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const StyledToggleButton = styled(ToggleButton)`
  background-color: #f3f4f6 !important;
  border: none;
  font-weight: 600 !important;
  line-height: 20px;
  border-radius: 4px !important;
  padding: 8px 16px 8px 16px;
  margin-top: 24px;
  font-size: 14px;
  color: #000;
  width: 100%;
  max-width: 110px;
  &.MuiToggleButton-root.Mui-selected {
    background-color: #2b337a !important;
    color: #fff;
  }
  &.MuiToggleButton-root.Mui-disabled {
    border: none;
  }
  @media (max-width: 400px) {
    font-size: 12px;
    padding: 6px;
    margin-top: 16px;
  }
`;

const StyledScheduledBox = styled(Box)`
  background-color: #e8eeff;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
`;

const getUserTimeZoneFormatted = () => {
  const date = new Date();
  const timeZoneName = date
    .toLocaleDateString(undefined, {
      timeZoneName: "long"
    })
    .split(", ")
    .pop();
  const timeZoneAbbreviation = date
    .toLocaleDateString(undefined, {
      timeZoneName: "short"
    })
    .split(", ")
    .pop();
  return `${timeZoneName} (${timeZoneAbbreviation})`;
};

const convertToAmPm = (timeStr) => {
  const [hourStr, minuteStr] = timeStr.split(":");
  let hour = parseInt(hourStr, 10);
  const minute = minuteStr;
  const ampm = hour >= 12 ? "PM" : "AM";

  hour = hour % 12 || 12; // Convert 0 to 12 for 12 AM/PM

  return `${hour}:${minute} ${ampm}`;
};

export const BookDemoSession = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [bookAppointment, setBookAppointment] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const isDesktop = useMediaQuery("(min-width: 500px)");
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      country: "",
      date: "",
      addressComponents: {},
      phoneType: "WORK",
      address: "",
      sportIds: []
    }
  });

  const country = watch("country");

  const { data: countryData } = getCountries({
    query: {
      staleTime: Infinity,
      cacheTime: 0
    }
  });
  const startDate = useMemo(() => new Date().toISOString(), []);
  const endDate = useMemo(
    () =>
      new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString(),
    []
  );

  const { data: demoSlots } = useAdminDemoSessionSlotGet({
    startDate,
    endDate
  });

  const selectedCountry = useMemo(() => {
    if (countryData?.length) {
      return countryData?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countryData]);

  const { data: sports, isFetching } = getSportsByCountry(country);

  const sportsOptions = sports?.map((sport) => ({
    label: !sport.name ? (sport.sport?.name as string) : (sport.name as string),
    value: sport.sportId as string
  }));

  const { mutate, status, isLoading } = useAdminDemoSessionPost();

  const submitForm = async (formValues) => {
    formValues["noOfAthletes"] = parseInt(formValues["noOfAthletes"]);
    formValues["primaryContact"] =
      formValues["firstName"] + " " + formValues["lastName"];
    const [year, month, d] = selectedDate.split("-");
    const date = new Date(Number(year), Number(month) + 1, Number(d));
    date.setHours(
      parseInt(selectedTime.split(":")[0]),
      parseInt(selectedTime.split(":")[1]),
      0,
      0
    );
    formValues["appointmentDate"] = date.toISOString();
    mutate(
      {
        data: formValues
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Demo Session booked successfully!`, {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar(`Failed to book demo session.`, {
            variant: "error"
          });
        }
      }
    );
  };

  const formatDateTimeRange = (dateStr: string, hour: string) => {
    const date = new Date();
    const [hours, minutes] = hour.split(":").map(Number);
    const [year, month, day] = dateStr.split("-").map(Number);
    date.setFullYear(year, month - 1, day);
    date.setHours(hours, minutes, 0, 0);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric"
    });
    const formattedStartTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    });
    const endTime = new Date(
      date.getTime() + (demoSlots?.data?.demoDuration || 60) * 60000
    );
    const formattedEndTime = endTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    });

    return `${formattedDate} - ${formattedStartTime} to ${formattedEndTime}`;
  };

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name || ""} ${
      route?.long_name || ""
    }`;
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    let city = addressComponents.find((c) => c.types.includes("locality"));
    if (!city)
      city = addressComponents.find(
        (c) =>
          c.types.includes("sublocality") || c.types.includes("postal_town")
      );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue("address", place.formatted_address);
    setValue("country", country?.short_name);
    setValue("addressComponents", {
      postalCode: zip?.short_name || zip?.long_name,
      locality: city?.short_name || city?.long_name,
      province: state.short_name,
      country: country.short_name,
      lines: [address1]
    });
  };

  useEffect(() => {
    setValue("sportIds", []);
  }, [country]);

  return (
    <>
      {!bookAppointment && (
        <FormContainer>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 300, mb: ".5rem" }}
          >
            Sports Organization - Book Demo
          </Typography>
          <Typography variant="body1" color="text.general.secondary">
            Enter your information below to book a demo and learn how
            SportsGravy can help your sports organization.
          </Typography>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="organizationName"
                type="text"
                required
                label="Organization Name"
                control={control}
                rules={{ required: "Organization Name is required" }}
                capitalizeWords
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormSelect
                name="organizationType"
                label="Type"
                required
                rules={{ required: "Type is required" }}
                options={ORGANIZATION_TYPE_OPTIONS}
                control={control}
              />
            </FormFieldContainer>
          </FormRow>

          <FormRow>
            <FormFieldContainer>
              <AddressAutocomplete
                name="address"
                control={control}
                selectSuggestion={selectAddressSuggestion}
                rules={{
                  required: "Office address is required"
                }}
                label="Office Address"
                required
                trigger={trigger}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormMultiSelect
                name="sportIds"
                options={sportsOptions}
                required
                label="Sports Offered"
                control={control}
                isLoading={isFetching}
                rules={{ required: "Sports Offered is required" }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="noOfAthletes"
                label="Number of Yearly Athlete Registrations"
                type="number"
                control={control}
                required
                rules={{
                  required: "Number of Yearly Athlete Registrations",
                  valueAsNumber: true,
                  validate: (value) => value >= 0 || "Enter a valid number"
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="website"
                label="Website"
                type="text"
                control={control}
                required
                rules={{
                  required: "Website is required",
                  validate: (value) =>
                    WEBSITE_REGEX.test(value) || "Enter a valid website"
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow style={{ flexDirection: "column" }}>
            <StyledFormLabel required style={{ display: "flex" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Primary Contact
              </Typography>
            </StyledFormLabel>
            <FormFieldContainer style={{ gap: "8px" }}>
              <FormInput
                name="firstName"
                label=""
                type="text"
                control={control}
                required
                rules={{
                  required: "First Name is required"
                }}
                sx={{
                  "& .label": {
                    display: "none !important"
                  }
                }}
                capitalizeWords
              />
              <FormInput
                name="lastName"
                label=""
                type="text"
                control={control}
                rules={{
                  required: "Last Name is required"
                }}
                sx={{
                  "& .label": {
                    display: "none !important"
                  }
                }}
                capitalizeWords
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="email"
                type="text"
                required
                label="Primary Contact Email"
                control={control}
                rules={{
                  required: "Primary Contact Email is required",
                  validate: {
                    validEmail: (email: string) =>
                      isValidEmail(email) ||
                      "Please enter a valid email address"
                  }
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow style={{ flexDirection: "column" }}>
            <StyledFormLabel required style={{ display: "flex" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Primary Contact Phone
              </Typography>
            </StyledFormLabel>
            <FormFieldContainer style={{ gap: "8px" }}>
              <div style={{ width: "33%" }}>
                <FormSelect
                  name="phoneType"
                  control={control}
                  options={[
                    {
                      label: "Work Phone",
                      value: "WORK"
                    },
                    {
                      label: "Mobile Phone",
                      value: "MOBILE"
                    }
                  ]}
                />
              </div>
              <FormInput
                name="phone"
                label={"\u00A0"}
                type="tel"
                control={control}
                rules={{
                  required: "Primary Contact Mobile Phone is required",
                  valueAsNumber: true
                }}
                country={selectedCountry}
                sx={{
                  "& .label": {
                    display: "none !important"
                  }
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="message"
                label="Why are you interested in SportsGravy?"
                type="text"
                multiline
                rows={3}
                control={control}
              />
            </FormFieldContainer>
          </FormRow>
          <ButtonContainer>
            <Button
              variant="primary"
              disabled={!isValid || isLoading}
              onClick={() => setBookAppointment(true)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ButtonContainer>
        </FormContainer>
      )}
      {bookAppointment && (
        <FormContainer>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 300, mb: ".5rem" }}
          >
            Select Demo Date and Time
          </Typography>
          <Typography variant="body1" color="text.general.secondary">
            Please choose a convenient date and time for your SportsGravy demo.
          </Typography>
          <Grid container sx={{ marginTop: "32px" }}>
            <Controller
              control={control}
              name="date"
              rules={{ required: "Date is required" }}
              render={({ field }) => (
                <DateCalendar
                  views={["day"]}
                  disablePast
                  sx={{
                    margin: 0,
                    height: "300px",
                    border: "1px solid #E2E8F0",
                    borderRadius: "5px"
                  }}
                  disabled={status === "success"}
                  shouldDisableDate={(date: Date) => {
                    const today = new Date(new Date().setHours(0, 0, 0, 0));
                    const d = `${(date as Date).getFullYear()}-${(
                      (date as Date).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${(date as Date)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                    const daySlot = demoSlots?.data?.slots?.find(
                      (s) => s.date == d
                    );
                    return (
                      !daySlot ||
                      (daySlot && daySlot?.slots?.length == 0) ||
                      date.getTime() == today.getTime() ||
                      date.getDay() == 6 ||
                      date.getDay() == 0
                    );
                  }}
                  onChange={(date) => {
                    const d = `${(date as Date).getFullYear()}-${(
                      (date as Date).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${(date as Date)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                    setSelectedDate(d as string);
                    setSelectedTime("");
                    field.onChange(date);
                  }}
                />
              )}
            />
          </Grid>
          <Grid container md={12} xs={12} gap="10px">
            {demoSlots?.data?.slots
              ?.find((s) => s.date == selectedDate)
              ?.slots?.map((slot) => (
                <Grid key={slot} xs={isDesktop ? 2.5 : 3.5}>
                  <StyledToggleButton
                    selected={selectedTime === slot}
                    value={slot}
                    onClick={() => setSelectedTime(slot)}
                    disabled={status === "success"}
                  >
                    {convertToAmPm(slot)}
                  </StyledToggleButton>
                </Grid>
              ))}
          </Grid>
          <FormRow>
            <Typography sx={{ fontWeight: 700, fontSize: "12px" }}>
              Time Zone:
            </Typography>{" "}
            &nbsp;
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {getUserTimeZoneFormatted()}
            </Typography>
          </FormRow>
          {selectedDate && selectedTime && status === "success" && (
            <>
              <FormRow>
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  You have scheduled your demo on:
                </Typography>
              </FormRow>
              <FormRow sx={{ marginTop: "6px" }}>
                <StyledScheduledBox>
                  <Typography
                    sx={{ color: "#2B337A", fontSize: "14px", fontWeight: 500 }}
                  >
                    {formatDateTimeRange(selectedDate, selectedTime)}
                  </Typography>
                </StyledScheduledBox>
              </FormRow>
            </>
          )}
          <ButtonContainer>
            <Button
              variant="primary"
              disabled={!isValid || isLoading || !selectedTime}
              type="submit"
              isLoading={isLoading}
              onClick={
                status === "success"
                  ? () => window.close()
                  : handleSubmit(submitForm)
              }
            >
              {status === "success" ? "Close" : "Book Demo"}
            </Button>
          </ButtonContainer>
        </FormContainer>
      )}
    </>
  );
};
