import { TableView } from "@components/TableView";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { getHelpCategories } from "@services/Network";
import {
  ModelHelpCategory,
  useAdminHelpCategoryOrderPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";

import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Category",
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row.name
  },
  {
    headerName: "FAQ items",
    field: "faqs",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row._count.faqs
  },
  {
    headerName: "Help Articles",
    field: "articles",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row._count.helpArticles
  }
];

export const HelpCategory = () => {
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(0);
  const [sorting, setSorting] = useState(false);
  const [rows, setRows] = React.useState<ModelHelpCategory[]>([]);

  const onAdd = () => navigate("/help-category/create");
  const onEdit = (row) => navigate(`/help-category/${row.helpCategoryId}/edit`);
  const onView = (row) => navigate(`/help-category/${row.helpCategoryId}`);

  const orderMutation = useAdminHelpCategoryOrderPut();

  const useGet = (params, options) =>
    getHelpCategories(
      {},
      {
        ...options,
        query: {
          ...options?.query,
          onSuccess: (data) => {
            const sportsWithRowData = data?.data?.map(
              (category: ModelHelpCategory) => {
                return {
                  ...category,
                  id: category.helpCategoryId,
                  __reorder__: category.name
                };
              }
            );
            setRows(sportsWithRowData);
          }
        }
      }
    );

  const onSortButtonClick = () => {
    setRefreshKey((prev) => prev + 1);
    setSorting(true);
  };

  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setRows(rowsClone);
  };

  const onSubmit = () => {
    const helpCategories = rows.map((row, index) => {
      return {
        helpCategoryId: row.helpCategoryId!,
        order: index
      };
    });

    orderMutation.mutate(
      {
        data: helpCategories
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully sorted help categories!", {
            variant: "success"
          });
          setSorting(false);
        },
        onError: () => {
          enqueueSnackbar(
            "Something went wrong! Failed to sort help categories.",
            {
              variant: "error"
            }
          );
        }
      }
    );
  };

  const onCancel = () => {
    setRefreshKey((prev) => prev + 1);

    setSorting(false);
  };

  return (
    <Fragment key={refreshKey}>
      <TableView
        title={!sorting ? "Help Categories" : "Sort Help Categories"}
        useGet={useGet}
        columns={LIST_COLUMNS}
        hideFilter={true}
        hideLabelContainer={true}
        searchable={false}
        getRowId={(row) => row.helpCategoryId}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        hideFooterPagination
        {...(sorting
          ? {
              rowReordering: true,
              onRowOrderChange: onRowOrderChange,
              onSubmit: onSubmit,
              onCancel: onCancel,
              hasActionColumn: false,
              hideSortButton: true,
              hideFooter: true,
              hideSubmitFooter: false,
              isSubmitting: orderMutation.isLoading,
              rows: rows
            }
          : {
              onSortButtonClick: onSortButtonClick,
              hideSortButton: false,
              onAdd: onAdd,
              hideFooter: false
            })}
      />
    </Fragment>
  );
};
