import {
  ModelOrganization,
  ModelUser
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilPersist } from "@utils/recoilPersist";
import { atom } from "recoil";
import {
  CasbinPolicyJsonModel,
  CasbinModelJsonModel
} from "../services/Casbin";
import { Enforcer } from "casbin";
import { GalleryItemType } from "@pages/photos-videos/GalleryItem";
import { recoilPersist } from "recoil-persist";

const persistEffectFactory = useRecoilPersist({
  key: "auth"
});

const { persistAtom: sessionAtom } = recoilPersist({
  key: "organization",
  storage: sessionStorage
});

export const accessTokenAtom = atom<string | undefined>({
  key: "accessToken",
  default: undefined,
  effects: [persistEffectFactory<string | undefined>()]
});

export const refreshTokenAtom = atom<string | undefined>({
  key: "refreshToken",
  default: undefined,
  effects: [persistEffectFactory<string | undefined>()]
});

export const isKeepLoggedInAtom = atom<boolean>({
  key: "isKeepLoggedIn",
  default: false,
  effects: [persistEffectFactory<boolean>()]
});

export const isResetPasswordSuccessAtom = atom<boolean>({
  key: "isResetPasswordSuccess",
  default: false
});

export const profileAtom = atom<ModelUser | undefined>({
  key: "profile",
  default: undefined,
  effects: [persistEffectFactory<ModelUser | undefined>()]
});

// We cannot type the casbin model because it internally uses a Map and we cannot represent that in JSON
// consider using [devalue](https://www.npmjs.com/package/devalue) in the future but that uses eval so ehhhh
// other options include:
// * marshal-ts - low weekly downloads
// * class-transformer - much more complex than we probably want to have to do
// * typed-json
export const casbinModel = atom<CasbinModelJsonModel | null>({
  key: "casbinModel",
  default: null,
  effects: [persistEffectFactory<CasbinModelJsonModel | null>()]
});

export const casbinPolicy = atom<CasbinPolicyJsonModel>({
  key: "casbinPolicy",
  default: [],
  effects: [persistEffectFactory<CasbinPolicyJsonModel>()]
});

export const enforcerAtom = atom<Enforcer | null>({
  key: "enforcer",
  default: null,
  effects: [persistEffectFactory<Enforcer | null>()]
});

export const organizationsAtom = atom<ModelOrganization[]>({
  key: "organizations",
  default: [],
  effects: [persistEffectFactory<ModelOrganization[]>()]
});

export const organizationAtom = atom<string | undefined>({
  key: "organization",
  default: undefined,
  effects: [sessionAtom]
});

export const selectedFilterAtom = atom<string | undefined>({
  key: "selectedFilter",
  default: undefined,
  effects: [persistEffectFactory<string | undefined>()]
});

export const selectedPlatformAtom = atom<string | undefined>({
  key: "selectedPlatform",
  default: "IOS",
  effects: [persistEffectFactory<string | undefined>()]
});

export const selectedReleaseAtom = atom<string | undefined>({
  key: "selectedRelease",
  default: "",
  effects: [persistEffectFactory<string | undefined>()]
});

export const previousPathAtom = atom<string | undefined>({
  key: "previousPath",
  default: "",
  effects: [persistEffectFactory<string | undefined>()]
});

export const selectedTesterAtom = atom<string | undefined>({
  key: "selectedTester",
  default: "",
  effects: [persistEffectFactory<string | undefined>()]
});

export const selectedPaginationAtom = atom<
  Array<{ page: string; pageSize: number; pageNo: number }> | undefined
>({
  key: "selectedPagination",
  default: [],
  effects: [
    persistEffectFactory<
      Array<{ page: string; pageSize: number; pageNo: number }> | undefined
    >()
  ]
});

export const selectedBuildAtom = atom<string | undefined>({
  key: "selectedBuild",
  default: "",
  effects: [persistEffectFactory<string | undefined>()]
});

export const MediaPhotosVideosAtom = atom<GalleryItemType[]>({
  key: "mediaPhotosVideos",
  default: [],
  effects: [persistEffectFactory<GalleryItemType[]>()]
});
export const MediaPhotosVideosFilterAtom = atom<object>({
  key: "mediaPhotosVideosFilter",
  default: [],
  effects: [persistEffectFactory<object>()]
});

export const searchAtom = atom<
  Array<{ page: string; textSearch: string }> | undefined
>({
  key: "search",
  default: [],
  effects: [
    persistEffectFactory<
      Array<{ page: string; textSearch: string }> | undefined
    >()
  ]
});
