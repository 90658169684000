import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { TableViewInfinite } from "@components/TableViewInfinite";
import { styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { GridColDef } from "@mui/x-data-grid";
import { getEpicJiraIssues } from "@services/Network";
import {
  adminDirectoryGet,
  ModelJiraIssue
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { capitalize } from "@utils/capitalize";
import { ToolTip } from "@components/ToolTip";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@components/crud/Loader";

const StyledSearchInput = styled(Grid)`
  .MuiInputBase-root {
    background: #fff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 1px 2px 0px #0000001a;
    font-weight: 600;
    color: #64748b;
  }
`;

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const StyledFormSelect = styled("div")`
  padding: 15px 0;
  .MuiBox-root {
    justify-content: center;
  }
  .helper-text: {
    height: 10px;
  }
`;

const CardsTable = ({ epicId }: { epicId?: string }) => {
  const { control, watch, setValue } = useFormContext();
  const [textSearch, setTextSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState("ALL");
  const [resourceFilter, setResourceFilter] = useState("ALL");
  const { data: companyDirectory, isLoading: isCompanyDirectoryLoading } =
    useQuery(
      ["companyDirectory"],
      () =>
        adminDirectoryGet({
          pageSize: 1000
        }),
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: true
      }
    );

  const resourcesOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    if (companyDirectory?.data.persons) {
      options = companyDirectory.data.persons
        .filter((resource) => resource.sportsgravyUser)
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.sportsgravyUser?.jiraAccountId as string
        }));
    }
    options.unshift({ label: "All Resources", value: "ALL" });
    return options;
  }, [companyDirectory?.data]);

  const getCards = (params, options) => {
    if (epicId) params.epicId = epicId;
    const response = getEpicJiraIssues(params, options);
    return response;
  };

  const CARDS_COLUMNS: GridColDef<ModelJiraIssue>[] = [
    {
      headerName: "Card",
      field: "key",
      minWidth: 50,
      flex: 1,
      renderHeader: () => (
        <div style={{ paddingLeft: "20px", fontWeight: 600 }}>Card</div>
      ),
      renderCell: (params) => {
        return (
          <div style={{ paddingLeft: "20px" }}>
            <FormCheckbox
              control={control}
              name={`${params.row.key}_card`}
              label={params.row.key}
              onChange={() => setValue(`${params.row.key}_platform`, undefined)}
            />
          </div>
        );
      }
    },
    {
      headerName: "Platform",
      field: "platform",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const cardAdded = watch(`${params.row.key}_card`);
        return (
          <StyledFormSelect>
            <FormSelect
              control={control}
              sx={{
                height: "36px",
                minWidth: "180px",
                justifyItems: "center",
                select: {
                  padding: "8px"
                }
              }}
              options={[
                {
                  label: "IOS",
                  value: "iOS"
                },
                {
                  label: "AND",
                  value: "AND"
                },
                {
                  label: "WEB",
                  value: "WEB"
                },
                {
                  label: "API",
                  value: "API"
                }
              ]}
              rules={{
                required: cardAdded ? "Platform is required" : undefined
              }}
              name={`${params.row.key}_platform`}
            />
          </StyledFormSelect>
        );
      }
    },
    {
      headerName: "Developer",
      field: "resource",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return params.row.assignee
          ? params.row.assignee?.user?.person?.firstName +
              " " +
              params.row.assignee?.user?.person?.lastName
          : "-";
      }
    },

    {
      headerName: "Type",
      field: "type",
      minWidth: 25,
      flex: 1,
      renderCell: (params) => {
        if (["STORY", "TASK"].includes(params.value)) {
          return (
            <>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "2px",
                  background: params.value === "STORY" ? "#1ABC9C" : "#4bade8",
                  marginRight: "8px"
                }}
              ></span>
              {capitalize(params.value)}
            </>
          );
        }
        if (params.value === "BUG") {
          return (
            <>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "99px",
                  background: "#E82C2C",
                  marginRight: "8px"
                }}
              ></span>
              Bug
            </>
          );
        }
      }
    },
    {
      headerName: "Use Case / Description",
      field: "title",
      sortable: false,
      minWidth: 500,
      flex: 1,
      renderCell: (params) => {
        return (
          <ToolTip title={params.row.summary}>
            <div style={{ padding: "20px 0" }}>
              <TwoLineText>{params.row.summary}</TwoLineText>
            </div>
          </ToolTip>
        );
      }
    }
  ];

  return (
    <Loader isLoading={isCompanyDirectoryLoading}>
      <Grid container direction="column" spacing={3} marginTop="24px">
        <Grid
          container
          spacing={3}
          sx={{ background: "#F3F4F7" }}
          padding="0px 12px"
        >
          <Grid xs={2.5}>
            <FormSelect
              sx={{
                select: {
                  background: "#fff",
                  fontWeight: 600,
                  color: "#64748B"
                },
                fieldSet: {
                  border: "1px solid #C4C4C4",
                  boxShadow: "0px 1px 2px 0px #0000001A"
                }
              }}
              options={[
                {
                  label: "All Types",
                  value: "ALL"
                },
                {
                  label: "Story",
                  value: "STORY"
                },
                {
                  label: "Bug",
                  value: "BUG"
                },
                {
                  label: "Task",
                  value: "TASK"
                }
              ]}
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              name="type"
            />
          </Grid>
          <Grid xs={2.5}>
            <FormSelect
              sx={{
                select: {
                  background: "#fff",
                  fontWeight: 600,
                  color: "#64748B"
                },
                fieldSet: {
                  border: "1px solid #C4C4C4",
                  boxShadow: "0px 1px 2px 0px #0000001A"
                }
              }}
              options={resourcesOptions}
              value={resourceFilter}
              onChange={(e) => setResourceFilter(e.target.value)}
              name="resource"
            />
          </Grid>
          <StyledSearchInput xs={7}>
            <SearchInput
              placeholder="Search"
              required
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </StyledSearchInput>
        </Grid>
        <Grid
          xs={12}
          padding="12px 0"
          marginLeft="-10px"
          width="calc(100% + 10px)"
          height="800px"
          overflow="auto"
        >
          <TableViewInfinite
            title=""
            hideFilter
            hideToolbar
            hideFooter
            columns={CARDS_COLUMNS}
            resourceId={resourceFilter === "ALL" ? undefined : resourceFilter}
            defaultSearch={textSearch === "" ? undefined : textSearch}
            cardType={typeFilter === "ALL" ? undefined : typeFilter}
            useGet={getCards}
            hasActionColumn={false}
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            isRowSelectable={() => false}
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default CardsTable;
