import {
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  CellTemplate,
  UncertainCompatible
} from "@silevis/reactgrid";
import React from "react";

export interface DateTimeCell extends Cell {
  type: "datetime";
  date: Date;
}

export class DateTimeTemplate implements CellTemplate<DateTimeCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DateTimeCell>
  ): Compatible<DateTimeCell> {
    const date = getCellProperty(uncertainCell, "date", "object");

    return {
      ...uncertainCell,
      date,
      value: date.getTime()
    };
  }

  update(
    cell: Compatible<DateTimeCell>,
    cellToMerge: UncertainCompatible<DateTimeCell>
  ): Compatible<DateTimeCell> {
    return this.getCompatibleCell({ ...cell, ...cellToMerge });
  }

  render(
    cell: Compatible<DateTimeCell>,
    _isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DateTimeCell>, commit: boolean) => void
  ): React.ReactNode {
    const formatDateTime = (date: Date) => date.toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:mm
    const now = new Date();
    const minDateTime = formatDateTime(now);

    return (
      <input
        type="datetime-local"
        defaultValue={formatDateTime(cell.date)}
        min={minDateTime}
        onChange={(e) => {
          const selectedDate = new Date(e.target.value);
          if (selectedDate >= now) {
            onCellChanged(
              {
                ...cell,
                date: selectedDate
              },
              true
            );
          } else {
            e.target.value = minDateTime;
          }
        }}
        onBlur={() => {
          onCellChanged(cell, true);
        }}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          outline: "none",
          font: "inherit",
          background: "transparent"
        }}
        autoFocus
      />
    );
  }
}
