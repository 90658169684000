/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactToolTip } from "@components/ToolTip";
import { OpenInNew } from "@mui/icons-material";

export const CustomColumnGraph = ({
  values,
  columns,
  showToolbar = true,
  showLabel = true,
  onLabelClick
}: {
  values: object[];
  columns: { title?: string; color: string; fontColor?: string }[];
  showToolbar?: boolean;
  showLabel?: boolean;
  onLabelClick?: (value: object) => void;
}) => {
  return (
    <>
      <div
        className="column-graph"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          gap: "24px",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
        {showToolbar &&
          columns.map((col: any) => (
            <div key={col.title} style={{ display: "flex" }}>
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: col.color,
                  marginRight: "8px"
                }}
              ></div>
              <div style={{ fontWeight: 600, fontSize: "12px" }}>
                {col.title}
              </div>
            </div>
          ))}
      </div>
      <div style={{ margin: "0px 40px 0px 40px" }} className="graph">
        {values.length > 0 &&
          values.map((value: any, index1: number) => {
            const total = Object.values(value.values?.[0]).reduce(
              //@ts-ignore
              (acc: number, curr: number) => acc + curr,
              0
            ) as number;
            return (
              <div key={index1} style={{ marginBottom: "16px" }}>
                {showLabel && (
                  <div
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: total == 0 ? 600 : 500,
                      fontSize: "13px",
                      cursor: onLabelClick && "pointer"
                    }}
                  >
                    {`${value.featureName} ${
                      total > 0
                        ? `(${total} ${total > 1 ? "Test Cases" : "Test Case"})`
                        : "(Heading)"
                    }`}
                    {onLabelClick && (
                      <OpenInNew
                        onClick={() => onLabelClick(value)}
                        style={{
                          color: "#2B337A",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    )}
                  </div>
                )}
                <div style={{ display: total > 0 ? "flex" : "none" }}>
                  {Object.entries(value.values[0]).map(
                    //@ts-ignore
                    ([key, val]: [string, number], index2: number) =>
                      val > 0 && (
                        <>
                          <div
                            key={key}
                            className={`graph-value-${index1}-${index2}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              height: "32px",
                              width: `${(val / total) * 100}%`,
                              minWidth: "20px",
                              background: columns[index2].color,
                              paddingRight: "5px",
                              fontSize: "12px",
                              fontWeight: 600
                            }}
                          >
                            <span
                              className={`graph-value-${index1}-${index2}`}
                              style={{
                                color: columns[index2].fontColor || "#000"
                              }}
                            >
                              {val}
                            </span>
                          </div>
                          <ReactToolTip
                            anchorSelect={`.graph-value-${index1}-${index2}`}
                          >
                            <span>{((val / total) * 100).toFixed(1)}%</span>
                          </ReactToolTip>
                        </>
                      )
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
