import { Backdrop, Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import colors from "theme/colors";
import { ModelSportsgravyUser } from "@sportsgravyengineering/sg-api-react-sdk";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { getQaMetric } from "@services/Network";
import { useEffect, useMemo, useState } from "react";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import { FormSelect } from "@components/FormSelect";

const columns = [
  {
    title: "TC Not Tested",
    color: "#E5E5E5"
  },
  {
    title: "TC Passed",
    color: "#1ABC9C"
  },
  {
    title: "TC Failed",
    color: "#E82C2C"
  },
  {
    title: "TC Onhold",
    color: "#FF710A"
  },
  {
    title: "TC Blocked",
    color: "#F48D8D"
  }
];

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  width: "110%",
  paddingBottom: "24px"
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

const StyledColumnGraph = styled("div")`
  margin-top: 32px;
  color: #000;
  .graph {
    margin: 0 !important;
    max-height: calc(100vh - 250px);
  }
`;

export const GanttChartBuildOverview = ({
  testerId,
  onClose,
  builds
}: {
  testerId: string;
  onClose: () => void;
  builds: {
    buildId: string | undefined;
    testers: ModelSportsgravyUser[];
    name: string;
    platform: string;
  }[];
}) => {
  const [featureBreakdown, setFeatureBreakdown] = useState({});
  const [selectedTesterId, setSelectedTesterId] = useState<string | undefined>(
    undefined
  );
  const [buildId, setBuildId] = useState<string>("");
  const [platfrom, setPlatform] = useState<string>("");

  const ganttChartQaMetric = (params, options) => {
    params.showOn = "GANTT_CHART";
    return getQaMetric(params, options);
  };

  useEffect(() => {
    const firstBuild = builds.find((build) =>
      build.testers.some((tester) => tester.userId === testerId)
    );
    setBuildId(firstBuild?.buildId || (builds[0]?.buildId as string));
    setPlatform(firstBuild?.platform as string);
    setSelectedTesterId(testerId);
  }, [builds]);

  const buildsOptions = useMemo(
    () =>
      (builds &&
        builds.map((build) => ({
          label: build.name,
          value: build.buildId
        }))) ||
      [],
    [builds]
  );

  const testerOptions = useMemo(() => {
    const testersFromBuilds =
      builds &&
      builds
        .find((r) => r.buildId === buildId)
        ?.testers.map((tester) => ({
          label: `${tester?.person?.firstName} ${tester?.person?.lastName}`,
          value: tester?.person?.userId
        }));

    const options = testersFromBuilds
      ? [{ label: "All Testers", value: "" }, ...testersFromBuilds]
      : [];

    return options;
  }, [buildId]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: 1200
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Build Overview
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box
            sx={{
              minHeight: "300px",
              maxHeight: "calc(100vh - 150px)",
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <Grid
              container
              paddingLeft="24px"
              marginTop="16px"
              columnSpacing={2}
            >
              <Grid xs={2}>
                <FormSelect
                  isReleaseSelect={true}
                  name="releaseFilter"
                  onChange={(e) => {
                    const newBuildId = e.target.value;
                    setBuildId(newBuildId);
                    const newRelease = builds.find(
                      (release) => release.buildId === newBuildId
                    );
                    if (
                      newRelease &&
                      !newRelease.testers.some(
                        (tester) => tester.userId === testerId
                      )
                    ) {
                      setSelectedTesterId(undefined);
                    }
                    if (
                      newRelease &&
                      newRelease.testers.some(
                        (tester) => tester.userId === testerId
                      )
                    ) {
                      setSelectedTesterId(testerId);
                    }
                    if (newRelease) {
                      setPlatform(newRelease.platform);
                    }
                  }}
                  options={buildsOptions}
                  value={buildId}
                />
              </Grid>
              <Grid xs={3}>
                <FormSelect
                  name="testerFilter"
                  options={testerOptions}
                  value={selectedTesterId}
                  onChange={(e) => {
                    setSelectedTesterId(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid padding="24px" paddingTop="0">
              {buildId && (
                <InsightSummaryBox
                  title="QA & Testing Insights (Builds)"
                  description="Quick Insights related to SportsGravy Product Builds"
                  isLoading={true}
                  buildId={buildId}
                  testerId={selectedTesterId}
                  platform={platfrom}
                  useGet={ganttChartQaMetric}
                  setFeatureBreakDown={setFeatureBreakdown}
                  isQaInsights={true}
                  defaultTimeFilter="TODAY"
                  colsInFirstRow={4}
                />
              )}
              <StyledColumnGraph>
                <CustomColumnGraph
                  columns={columns}
                  values={featureBreakdown}
                />
              </StyledColumnGraph>
            </Grid>
          </Box>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
