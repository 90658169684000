/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from "@components/crud/Form";
import Grid from "@mui/material/Unstable_Grid2";
import { FormSelect } from "@components/FormSelect";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import {
  useAdminImportTmplProviderIdVersionVersionIdGet,
  useAdminLevelGet,
  useAdminSeasonGet,
  useAdminSportGet,
  useAdminUploadPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useEffect, useMemo, useState } from "react";
import { FormInput } from "@components/FormInput";
import papaparse from "papaparse";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@components/Button";
import { DownloadIcon } from "@components/Icons";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../../recoil/auth";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { Workbook } from "exceljs";
import colors from "theme/colors";
import { MainContainer } from "@pages/crm/components/CreateMeetingModal";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Close } from "@mui/icons-material";

const DownloadBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));
export const ImportPlayerCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched",
    defaultValues: {
      sportId: "",
      for: "",
      seasonId: "",
      csvTemplate: undefined
    }
  });

  const {
    formState: { isValid },
    control,
    handleSubmit,
    setValue,
    clearErrors
  } = form;

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sport, setSport] = useState("");
  const [forSelect, setForSelect] = useState("");
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [missingHeaders, setMissingHeaders] = useState<
    { type: string; header: string }[]
  >([]);
  const currentOrganization = organizations.find(
    (org) => org.organizationId == organizationId
  );
  const onSportChange = (e) => {
    setSport(e.target.value);
    setValue("seasonId", "");
    clearErrors("seasonId");
  };
  const onForChange = (e) => {
    setForSelect(e.target.value);
  };
  const forOptions = useMemo(() => {
    if (currentOrganization?.offering == "TEAMS") {
      setValue("for", "TEAM");
      setForSelect("TEAM");
      return [{ label: "Team", value: "TEAM" }];
    }
    if (currentOrganization?.offering == "TRAINING_PROGRAMS") {
      setValue("for", "TRAINING_PROGRAM");
      return [{ label: "Training Program", value: "TRAINING_PROGRAM" }];
    } else {
      return [
        { label: "Team", value: "TEAM" },
        { label: "Training Program", value: "TRAINING_PROGRAM" }
      ];
    }
  }, [currentOrganization]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );

  const { options: seasonOptions, isLoading: seasonOptionLoading } =
    useApiSelectOptions({
      api: useAdminSeasonGet,
      params: { sportId: sport, organizationId, pageSize: 1000 },
      dataField: "results",
      labelField: "name",
      valueField: "seasonId"
    });
  useEffect(() => {
    if (sportOptions.length == 1) {
      setValue("sportId", sportOptions[0].value);
      setSport(sportOptions[0].value);
    }
    if (seasonOptions.length == 1 && forSelect == "TEAM") {
      setValue("seasonId", seasonOptions[0].value);
    }
  }, [sportOptions, seasonOptions]);
  const csvTemplateChangeHandler = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setCsvFile(undefined);
      field.onChange({ target: { value: undefined } });
      return;
    }
    papaparse.parse(file, {
      header: true,
      dynamicTyping: false,
      complete: (results: { meta: { fields: any[] } }, file: any) => {
        const fields = results?.meta?.fields?.map((v: any) => v) || [];

        setCsvFile(file);
        field.onChange({ target: { value: file } });

        const requiredHeaders: { type: string; header: string }[] = [];
        const fieldMap: any = data?.data.fieldMap;
        for (const category in fieldMap) {
          for (const field in fieldMap[category]) {
            if (
              !fieldMap[category][field].canBeEmpty ||
              fieldMap[category][field].required
            ) {
              requiredHeaders.push({
                header: fieldMap[category][field].header,
                type:
                  category === "parent1"
                    ? "Parent / Guardian 1 Details"
                    : category === "parent2"
                      ? "Parent / Guardian 2 Details"
                      : "Athlete / Player Details"
              });
            }
          }
        }
        const missingHeaders = requiredHeaders.filter(
          (header) => !fields.includes(header.header)
        );
        setMissingHeaders(missingHeaders);
      }
    });
  };
  const [headers, setHeaders] = useState<string[]>([]);
  const currentProviderId = currentOrganization?.providerId;
  const currentVersionId = currentOrganization?.versionId;
  const { data, isLoading: isCsvLoading } =
    useAdminImportTmplProviderIdVersionVersionIdGet(
      currentProviderId!,
      currentVersionId!
    );
  const levels = useAdminLevelGet({
    organizationId: organizationId!,
    pageSize: 1000
  });

  useEffect(() => {
    if (currentProviderId === "sportsgravy" && currentVersionId) {
      setHeaders(data?.data.csvTemplate || []);
    }
  }, [currentProviderId, currentVersionId, data]);

  const isSportsGravyTemplate = useMemo(() => {
    return currentProviderId === "sportsgravy";
  }, [currentProviderId]);

  const downloadExcel = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    worksheet.addRow(headers);
    worksheet.addRow([]);

    const dropdownOptions =
      levels.data?.data.levels?.map((level) => level.name).filter(Boolean) ||
      [];

    const fieldMap = data?.data.fieldMap;
    //@ts-expect-error
    const levelHeader = fieldMap.player.level.header;
    const levelIndex = headers.findIndex((header) => header === levelHeader);
    const levelColumn = String.fromCharCode(65 + levelIndex);

    const genderOptions = ["Male", "Female"];
    // @ts-expect-error
    const genderHeader = fieldMap.player.gender.header;
    const genderIndex = headers.findIndex((header) => header === genderHeader);
    const genderColumn = String.fromCharCode(65 + genderIndex);

    const firstRow = worksheet.getRow(2);

    firstRow.getCell(levelColumn).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${dropdownOptions.join(",")}"`]
    };
    firstRow.getCell(genderColumn).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${genderOptions.join(",")}"`]
    };

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "template.xlsx";
      a.click();
    });
  };

  const { mutate, isLoading: importPlayerLoading } = useAdminUploadPost();

  const onSave = (formValues) => {
    mutate(
      {
        data: {
          organizationId: organizationId!,
          sportId: formValues.sportId,
          ...(formValues.for === "TEAM"
            ? { seasonId: formValues.seasonId }
            : {}),
          for: formValues.for,
          fileName: csvFile?.name
        }
      },
      {
        onSuccess: async (data) => {
          setIsLoading(true);
          const formData = new FormData();
          if (
            data.data?.uploadParams &&
            data.data?.uploadParams.url &&
            data.data?.uploadParams?.fields
          ) {
            const fields = data.data?.uploadParams?.fields;
            for (const key in fields) {
              formData.append(key, fields[key]);
            }

            formData.append("file", csvFile!);
            try {
              await axios.post(data.data?.uploadParams.url, formData);
              setIsLoading(false);
              enqueueSnackbar("Uploaded successfully!", {
                variant: "success"
              });
              navigate("/import-players");
            } catch (error) {
              setIsLoading(false);
              enqueueSnackbar("Failed to upload!", { variant: "error" });
            }
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to upload!", { variant: "error" });
        }
      }
    );
  };
  return (
    <Container>
      <Toolbar title="Import Athletes / Players" />
      <Form data-testid="import-player-create-form">
        <Grid spacing={3}>
          <Grid data-testid="import-player-create-for-input">
            <FormSelect
              control={control}
              name="for"
              label="For"
              required={true}
              onChange={onForChange}
              disabled={forOptions.length == 1}
              options={forOptions}
              sx={{ maxWidth: "450px" }}
              rules={{
                required: "For is required"
              }}
            />
          </Grid>
          <Grid
            data-testid="import-player-create-sport-input"
            sx={{ marginTop: "25px" }}
          >
            <FormSelect
              control={control}
              name="sportId"
              label="Sports"
              required={true}
              onChange={onSportChange}
              disabled={sportOptions.length == 1}
              isLoading={isSportLoading}
              options={sportOptions}
              sx={{ maxWidth: "450px" }}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          {forSelect == "TEAM" && (
            <Grid
              data-testid="import-player-create-season-input"
              sx={{ marginTop: "25px" }}
            >
              <FormSelect
                control={control}
                name="seasonId"
                label="Season"
                required={true}
                disabled={sport == "" || seasonOptions.length == 1}
                isLoading={seasonOptionLoading}
                options={seasonOptions}
                sx={{ maxWidth: "450px" }}
                rules={{
                  required: "Season is required"
                }}
              />
            </Grid>
          )}
          <Grid
            data-testid="import-player-create-file-input"
            sx={{ maxWidth: "450px", marginTop: "25px" }}
          >
            <FormInput
              control={control}
              name="csvTemplate"
              type="file"
              label="Template File"
              required={true}
              value={csvFile}
              rules={{ required: "Template File is required" }}
              // @ts-ignore
              onChange={csvTemplateChangeHandler}
              InputProps={{
                accept: "text/csv"
              }}
            />
            <Typography
              variant="body1"
              sx={{ color: "#1E2941", fontSize: "13px", marginTop: "10px" }}
            >
              Supported formats: CSV
            </Typography>
          </Grid>
        </Grid>
        {isSportsGravyTemplate && (
          <Box
            style={{
              borderRadius: "6px",
              background: "#F8FAFC",
              margin: "24px 0",
              padding: "12px 16px",
              textAlign: "center",
              width: "85%"
            }}
            data-testid="import-player-download-btn"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid textAlign={"left"} xs={8}>
                <Typography>
                  Your organization is using SportsGravy's standard CSV file to
                  upload Athletes / Players
                </Typography>
              </Grid>
              <Grid
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <DownloadBtn
                  variant="admin-secondary"
                  onClick={downloadExcel}
                  isLoading={isCsvLoading}
                  disabled={isCsvLoading}
                  startIcon={<DownloadIcon sx={{ marginTop: "1.5px" }} />}
                >
                  <Typography
                    style={{
                      color: colors.info.main,
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "21px"
                    }}
                  >
                    Download
                  </Typography>
                </DownloadBtn>
              </Grid>
            </Grid>
          </Box>
        )}
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(onSave)}
        saveBtnLabel="Import"
        isDisabled={
          !isValid ||
          importPlayerLoading ||
          !!missingHeaders.length ||
          isLoading
        }
        isLoading={importPlayerLoading || isLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/import-players")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />

      <Modal open={!!missingHeaders.length}>
        <MainContainer
          style={{ maxWidth: "800px", width: "50vw", paddingBottom: 0 }}
        >
          <div style={{ padding: "32px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    background: colors.error.light,
                    height: "40px",
                    width: "40px",
                    borderRadius: "99px",
                    padding: "8px"
                  }}
                >
                  <WarningAmberIcon sx={{ color: colors.error.icon }} />
                </div>
                <Typography variant="h6" fontWeight={600}>
                  Missing fields
                </Typography>
              </div>
              <IconButton
                onClick={() => {
                  setMissingHeaders([]);
                  setCsvFile(undefined);
                  setValue("csvTemplate", undefined, {
                    shouldValidate: true
                  });
                }}
              >
                <Close htmlColor="#B3B3B3" />
              </IconButton>
            </div>
            <div style={{ paddingLeft: "60px", paddingTop: "8px" }}>
              <div>
                <Typography variant="body1">
                  Fix the missing fields listed below from your CSV and try
                  uploading again
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "16px",
                  height: "auto",
                  maxHeight: "40vh",
                  overflowY: "auto",
                  border: "1px solid #E2E8F0",
                  borderRadius: "6px"
                }}
              >
                {missingHeaders.map((header, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "8px 8px 8px 8px",
                      borderBottom:
                        index === missingHeaders.length - 1
                          ? "none"
                          : "1px solid #E2E8F0"
                    }}
                  >
                    <Typography variant="body1" color="#64748B">
                      {header.type}
                    </Typography>
                    <Typography
                      style={{
                        overflowWrap: "anywhere",
                        textAlign: "right"
                      }}
                    >
                      {header.header}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer
            cancelBtnLabel="Okay"
            cancelBtnClick={() => {
              setMissingHeaders([]);
              setCsvFile(undefined);
              setValue("csvTemplate", undefined, {
                shouldValidate: true
              });
            }}
          />
        </MainContainer>
      </Modal>
    </Container>
  );
};
