import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ContactResponse,
  ModelOrganizationContact,
  useAdminCrmContactContactIdDelete,
  useAdminCrmContactContactIdGet,
  useAdminCrmOrgContactContactIdDelete,
  useAdminCrmOrgContactContactIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { Form } from "@components/crud/Form";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { styled } from "@mui/material";

const StyledLinkText = styled("span")`
  color: #007aff;
  cursor: pointer;
  word-wrap: break-word;
`;

export const ContactView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { contactId } = useParams();
  const form = useForm({
    mode: "onTouched"
  });
  const [contactToDelete, setContactToDelete] = useState<
    ModelOrganizationContact | ContactResponse | undefined
  >(undefined);
  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.contacts", "DELETE");
      const edit = await checkPermission("crm.contacts", "EDIT");
      const view = await checkPermission("crm.contacts", "VIEW");
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  const { reset } = form;

  const [relatedAccounts, setRelatedAccounts] = useState<
    {
      accountId: string;
      account: string;
      jobTitle: string;
      isPrimaryContact: boolean;
      workLocation?: string;
      workPhoneType?: string;
      workPhone?: string;
      workEmail?: string;
    }[]
  >([]);

  const [homeLocationSameAs, setHomeLocationSameAs] = useState<string | null>(
    null
  );
  const [isHomeLocationSameAs, setIsHomeLocationSameAs] = useState(false);

  const [relatedLinks, setRelatedLinks] = useState<
    {
      url: string;
      name: string;
      otherName: string;
    }[]
  >([]);

  const { data: contactDetails, isLoading: isLoading } = organizationId
    ? useAdminCrmOrgContactContactIdGet(contactId!)
    : useAdminCrmContactContactIdGet(contactId!);
  useEffect(() => {
    if (contactDetails?.data) {
      const contact = contactDetails.data as
        | ModelOrganizationContact
        | ContactResponse;
      let locationSameAs: string | undefined;
      for (const acc of contact.accounts || []) {
        if (acc.account?.officeAddress === contact.location) {
          locationSameAs = acc.accountId;
          break;
        }
      }
      reset({
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
        suffix: contact.suffix,
        homeLocation: contact.location,
        homePhone: contact.phone,
        homeWhatsappNumber: contact.whatsappNumber,
        personalEmail: contact.email,
        isHomeLocationSameAs: !!locationSameAs
      });
      setIsHomeLocationSameAs(!!locationSameAs);
      setHomeLocationSameAs(locationSameAs as string);
      setRelatedLinks(
        contact.links?.map((l) => ({
          url: l.link || "",
          name: l.type || "",
          otherName: l.altName || ""
        })) || []
      );
      setRelatedAccounts(
        contact.accounts?.map((acc) => ({
          accountId: acc.accountId!,
          account: acc?.account?.name,
          jobTitle: acc.jobTitle!,
          isPrimaryContact: !!acc.isPrimary,
          workLocation: acc?.account?.officeAddress,

          workPhone: acc?.workPhone || "",
          workEmail: acc?.workEmail || "",
          accountPhone: acc?.account?.phone,
          accountEmail: acc?.account?.email
        })) || []
      );
    }
  }, [contactDetails]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = organizationId
    ? useAdminCrmOrgContactContactIdDelete()
    : useAdminCrmContactContactIdDelete();

  const onConfirmDelete = async () => {
    try {
      await deleteAsync({ contactId: contactId as string });
      enqueueSnackbar("Contact Deleted successfully", {
        variant: "success"
      });
      setContactToDelete(undefined);
      navigate("/crm/contacts");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Contact.", {
        variant: "error"
      });
      setContactToDelete(undefined);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Contact"
        backBtnClick={() => {
          navigate("/crm/contacts");
        }}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/crm/contacts/${contactId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () => setContactToDelete(contactDetails?.data)
        })}
      />
      <Loader isLoading={isLoading}>
        <Form>
          <ContactDetailsForm
            disabled
            form={form}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
            relatedLinks={relatedLinks}
            setRelatedLinks={setRelatedLinks}
            homeLocationSameAs={homeLocationSameAs}
            setHomeLocationSameAs={setHomeLocationSameAs}
            isHomeLocationSameAs={isHomeLocationSameAs}
            setIsHomeLocationSameAs={setIsHomeLocationSameAs}
            organizationId={organizationId}
          />
        </Form>
      </Loader>
      <ConfirmationDialog
        title={
          (contactToDelete as ContactResponse)?.orders?.length ||
          (contactToDelete as ContactResponse)?.opportunites?.length ||
          (contactToDelete as ContactResponse)?.leads?.length
            ? "Cannot Delete Contact"
            : "Delete Contact?"
        }
        body={
          (contactToDelete as ContactResponse)?.orders?.length ||
          (contactToDelete as ContactResponse)?.opportunites?.length ||
          (contactToDelete as ContactResponse)?.leads?.length ? (
            <div>
              <span>
                This Contact cannot be deleted because it is currently the
                selected contact on 1 or more{" "}
                {(contactToDelete as ContactResponse)?.orders?.length
                  ? "orders"
                  : (contactToDelete as ContactResponse)?.opportunites?.length
                    ? "opportunities"
                    : "leads"}{" "}
                in your organization.
              </span>
              <br />
              <br />
              <>
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto"
                  }}
                >
                  <ul style={{ marginLeft: "25px" }}>
                    {(contactToDelete as ContactResponse)?.orders?.length
                      ? (contactToDelete as ContactResponse)?.orders?.map(
                          (order) => (
                            <li key={order.orderId}>
                              <StyledLinkText
                                onClick={() =>
                                  window.open(
                                    `/crm/orders/${order.orderId}`,
                                    "_blank"
                                  )
                                }
                              >
                                {order?.orderId} - {order?.account?.name}
                              </StyledLinkText>
                            </li>
                          )
                        )
                      : (contactToDelete as ContactResponse)?.opportunites
                            ?.length
                        ? (
                            contactToDelete as ContactResponse
                          )?.opportunites?.map((opp) => (
                            <li key={opp.opportunityId}>
                              <StyledLinkText
                                onClick={() =>
                                  window.open(
                                    `/crm/opportunities/${opp.opportunityId}`,
                                    "_blank"
                                  )
                                }
                              >
                                {opp?.name} - {opp?.account?.name}
                              </StyledLinkText>
                            </li>
                          ))
                        : (contactToDelete as ContactResponse)?.leads?.map(
                            (lead) => (
                              <li key={lead.leadId}>
                                <StyledLinkText
                                  onClick={() =>
                                    window.open(
                                      `/crm/leads/${lead.leadId}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {lead?.name} - {lead?.account?.name}
                                </StyledLinkText>
                              </li>
                            )
                          )}
                  </ul>
                </div>
                <br />
              </>
            </div>
          ) : (
            <>Are you sure you want to delete this Contact?</>
          )
        }
        open={!!contactToDelete}
        close={() => setContactToDelete(undefined)}
        onCancel={() => setContactToDelete(undefined)}
        {...((contactToDelete as ContactResponse)?.orders?.length ||
        (contactToDelete as ContactResponse)?.opportunites?.length ||
        (contactToDelete as ContactResponse)?.leads?.length
          ? {
              cancelBtnText: "Okay"
            }
          : {
              onConfirm: onConfirmDelete
            })}
        confirmBtnVariant="admin-error"
        icon="error"
        isConfirming={isDeleting}
      />
    </Container>
  );
};
