import { Container } from "@components/crud/Container";
import { SearchAddContact } from "./components/SearchAddContact";
import { Toolbar } from "@components/crud/Toolbar";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { cleanObject } from "@utils/cleanObject";
import {
  getAdminCrmAccountAccountId,
  getAdminCrmOrgAccountAccountId,
  useAdminCrmContactPost,
  useAdminCrmOrgContactPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { Grid } from "@mui/material";
import { hasPermission } from "@services/Casbin";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";

export const CreateContact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get("accountId");
  const organizationId = useRecoilValue(organizationAtom);
  const [isContactSelected, setIsContactSelected] = useState(false);
  const [isNewContactSelected, setIsNewContactSelected] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.contacts", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const form = useForm({
    mode: "onTouched",
    shouldUnregister: true
  });

  const {
    formState: { isValid },
    getValues,
    reset
  } = form;
  const [relatedAccounts, setRelatedAccounts] = useState(
    organizationId
      ? []
      : [
          {
            account: "New Account",
            accountId: "",
            jobTitle: "",
            isPrimaryContact: true,
            workLocation: "",

            workPhone: "",
            workEmail: "",
            accountPhone: "",
            accountEmail: ""
          }
        ]
  );

  const [relatedLinks, setRelatedLinks] = useState<
    {
      url: string;
      name: string;
      otherName: string;
    }[]
  >([
    {
      url: "",
      name: "",
      otherName: ""
    }
  ]);
  const { mutate: save, isLoading: isSaving } = organizationId
    ? useAdminCrmOrgContactPost()
    : useAdminCrmContactPost();
  const onSave = (resetInsteadOfRoute: boolean) => {
    const data = {
      firstName: getValues().firstName,
      middleName: getValues().middleName || "",
      lastName: getValues().lastName,
      suffix: getValues().suffix || "",
      location: getValues().homeLocation,
      phone: getValues().homePhone || "",
      whatsappNumber: getValues().homeWhatsappNumber || "",
      email: getValues().personalEmail || "",
      accounts: relatedAccounts.map((acc) => ({
        accountId: acc.accountId,
        jobTitle: acc.jobTitle,
        isPrimary: acc.isPrimaryContact,
        workEmail: acc.workEmail,
        workPhone: acc.workPhone,
        workPhoneType: "MOB"
      })),
      links: relatedLinks
        .filter((rl) => rl.name)
        .map((rl) => ({
          type: rl.name,
          link: rl.url,
          altName: rl.otherName
        })),
      ...(organizationId && {
        organizationId: organizationId
      })
    };
    save(
      {
        data: cleanObject(data)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Contact Created successfully!", {
            variant: "success"
          });
          if (resetInsteadOfRoute) {
            setRefreshKey((prev) => prev + 1);
            reset();
            setRelatedLinks([
              {
                url: "",
                name: "FACEBOOK",
                otherName: ""
              }
            ]);
            setRelatedAccounts([
              {
                account: "New Account",
                accountId: "",
                jobTitle: "",
                isPrimaryContact: false,
                workLocation: "",
                accountPhone: "",
                accountEmail: "",
                workPhone: "",
                workEmail: ""
              }
            ]);
            setHomeLocationSameAs(null);
            setIsHomeLocationSameAs(false);
          } else {
            if (accountId) navigate(`/crm/accounts/${accountId}`);
            else navigate(`/crm/contacts`);
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Contact!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [homeLocationSameAs, setHomeLocationSameAs] = useState(null);
  const [isHomeLocationSameAs, setIsHomeLocationSameAs] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const data = organizationId
          ? await getAdminCrmOrgAccountAccountId(accountId)
          : await getAdminCrmAccountAccountId(accountId);
        if (data.data.accountId) {
          const account = {
            account: data.data.name!,
            accountId: data.data.accountId!,
            jobTitle: "",
            isPrimaryContact: false,
            workLocation: data.data.officeAddress!,
            workPhone: "",
            workEmail: "",
            accountPhone: data.data.phone!,
            accountEmail: data.data.email!
          };
          const accs = relatedAccounts;
          accs[0] = account;
          setRelatedAccounts([...accs]);
        }
      }
    };
    fetchData();
  }, [accountId]);
  return (
    <Container>
      <Toolbar title="Add Contact" />
      {!isContactSelected && !isNewContactSelected ? (
        <Grid container padding="12px 16px 12px 16px">
          <Grid item xs={12}>
            <SearchAddContact
              contactSelected={(
                contact,
                contactSelected,
                newContactSelected
              ) => {
                setIsContactSelected(contactSelected);
                setIsNewContactSelected(newContactSelected);
                if (accountId && contactSelected && !newContactSelected)
                  navigate(
                    `/crm/contacts/${contact.contactId}/edit?accountId=${accountId}`
                  );
                else if (contactSelected && !newContactSelected)
                  navigate(`/crm/contacts/${contact.contactId}/edit`);
              }}
              organizationId={organizationId}
            />
          </Grid>
        </Grid>
      ) : (
        <Form>
          <ContactDetailsForm
            form={form}
            key={refreshKey}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
            relatedLinks={relatedLinks}
            setRelatedLinks={setRelatedLinks}
            homeLocationSameAs={homeLocationSameAs}
            setHomeLocationSameAs={setHomeLocationSameAs}
            isHomeLocationSameAs={isHomeLocationSameAs}
            setIsHomeLocationSameAs={setIsHomeLocationSameAs}
            organizationId={organizationId}
          />
        </Form>
      )}
      {isNewContactSelected && (
        <>
          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            saveBtnClick={() => {
              onSave(false);
            }}
            saveAndNewBtnClick={() => {
              onSave(true);
            }}
            isDisabled={
              !isValid ||
              isSaving ||
              relatedAccounts.length === 0 ||
              relatedAccounts.filter((ra) => !ra.accountId).length > 0
            }
            isLoading={isSaving}
          />
          <ConfirmationDialog
            title="Are you sure you want to cancel?"
            body="All of your current changes will be lost."
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => navigate("/crm/contacts")}
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
          />
        </>
      )}
    </Container>
  );
};
