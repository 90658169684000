import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getCrmAccounts, getCrmOrgAccounts } from "@services/Network";
import {
  ModelAccount,
  ModelAccountContact,
  ModelPerson,
  useGetAdminCrmAccount,
  useGetAdminCrmOrgAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/Language";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, Typography } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import { QuickAddAccount } from "./components/QuickAddAccount";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { CRM_ACCOUNT_CATEGORIES, CRM_ACCOUNT_TYPES } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { VirtualMeetingIcon } from "@components/Icons";
import { CreateMeetingModal } from "../components/CreateMeetingModal";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { participant } from "../components/SearchParticipants";

const IconStyle = {
  height: "20px",
  width: "20px"
};

export const Accounts = () => {
  const user = useRecoilValue(profileAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const { setAccount, setEmailTo } = useContext(CallEmailTemplateContext);
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const [quickAdd, setQuickAdd] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [selectedMeetingAccount, setSelectedMeetingAccount] = useState<
    string | undefined
  >(undefined);
  const [selectedMeetingAutoSuggestions, setSelectedMeetingAutoSuggestions] =
    useState<participant[] | undefined>();

  const onAdd = () => navigate("create");
  const onQuickAdd = () => setQuickAdd(true);
  const onEdit = (account) =>
    navigate(`/crm/accounts/${account.accountId}/edit`);
  const onView = (account) =>
    navigate(`/crm/accounts/${account.accountId}?tab=Account+Details`);
  const onAccountDashboardView = (account) => navigate(`${account.accountId}`);
  const onQuickAddClose = () => setQuickAdd(false);
  const getPrimaryAndPersonalEmails = (
    c: ModelAccountContact,
    accountName: string,
    isPrimary: boolean
  ): participant[] => {
    const res = [] as participant[];
    if (c.workEmail) {
      res.push({
        label: formatFullName(c.contact as ModelPerson),
        text: c.workEmail,
        value: c.contactId!,
        isInternal: false,
        emailDetails: {
          email: c.workEmail,
          emailInfo: `Work Email For ${accountName}`,
          isPrimaryStar: isPrimary
        }
      });
    }
    if (c.contact?.email) {
      res.push({
        label: formatFullName(c.contact as ModelPerson),
        text: c.contact.email,
        value: c.contactId!,
        isInternal: false,
        emailDetails: {
          email: c.contact?.email,
          emailInfo: "Personal Email",
          isPrimaryStar: isPrimary
        }
      });
    }
    return res;
  };
  const getAutoSuggestOptions = (
    contacts: ModelAccountContact[],
    accountName: string
  ): participant[] => {
    let res = [] as participant[];
    contacts
      .filter((c) => c.isPrimary)
      .map((c) => {
        res = [...res, ...getPrimaryAndPersonalEmails(c, accountName, true)];
      });
    contacts
      .filter((c) => !c.isPrimary)
      .map((c) => {
        res = [...res, ...getPrimaryAndPersonalEmails(c, accountName, false)];
      });
    return res;
  };
  const ACCOUNTS_COLUMNS: GridColDef<ModelAccount>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: organizationId ? 200 : 300,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const primaryContactWithEmail = params.row.contacts?.find(
          (c) => c.isPrimary && (c.contact?.email || c.workEmail)
        );
        const primaryContactWithPhone = params.row.contacts?.find(
          (c) => c.isPrimary && (c.contact?.phone || c.workPhone)
        );
        return (
          <div style={{ padding: "20px 0" }}>
            <IconButton onClick={() => onAccountDashboardView(params.row)}>
              <ToolTip
                title="Click for Account Dashboard"
                placement="top-start"
              >
                <SpaceDashboardIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Account" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Account" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {!organizationId && (
              <>
                <IconButton
                  onClick={() => setAccount(params.row)}
                  disabled={
                    !params.row.contacts?.some(
                      (contact) => contact?.workPhone || contact.contact?.phone
                    )
                  }
                >
                  <ToolTip
                    title={`Click to Call ${
                      !primaryContactWithPhone
                        ? "Account"
                        : formatFullName(
                            primaryContactWithPhone.contact as ModelPerson
                          ) || "Primary Contact"
                    }`}
                    placement="top-start"
                  >
                    <CallIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
                {primaryContactWithEmail ||
                !!params.row.contacts?.find(
                  (c) => c.workEmail || c.contact?.email
                ) ? (
                  <IconButton
                    onClick={() => {
                      setShowMeeting(true);
                      setSelectedMeetingAccount(params.row.accountId);
                      setSelectedMeetingAutoSuggestions(
                        getAutoSuggestOptions(
                          params.row.contacts!,
                          params.row.name!
                        )
                      );
                    }}
                  >
                    <ToolTip
                      title={`Click to Connect with ${
                        primaryContactWithEmail
                          ? formatFullName(
                              primaryContactWithEmail.contact as ModelPerson
                            )
                          : "Account"
                      }`}
                      placement="top-start"
                    >
                      <span style={{ marginTop: "10px" }}>
                        <VirtualMeetingIcon
                          style={{
                            width: "25px",
                            height: "25px"
                          }}
                        />
                      </span>
                    </ToolTip>
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton
                  onClick={() => {
                    setEmailTo({
                      to: params.row.contacts?.[0]?.contact?.email,
                      name: `${params.row.contacts?.[0]?.contact?.firstName} ${params.row.contacts?.[0]?.contact?.lastName}`,
                      accountId: params.row.accountId,
                      relatesTo: "ACCOUNT",
                      accountName: params.row.name,
                      autoSuggestOptions: [
                        {
                          label: params.row.name!,
                          text: params.row.email,
                          value: params.row.contacts?.find(
                            (c) => c.workEmail === params.row.email
                          )?.contactId as string,
                          isInternal: false,
                          emailDetails: {
                            email: params.row.email
                          }
                        },
                        ...getAutoSuggestOptions(
                          params.row.contacts!,
                          params.row.name!
                        )
                      ]
                    });
                  }}
                >
                  <ToolTip
                    title={`Click to Email ${
                      !primaryContactWithEmail
                        ? "Account"
                        : formatFullName(
                            primaryContactWithEmail.contact as ModelPerson
                          ) || "Primary Contact"
                    }`}
                    placement="top-start"
                  >
                    <EmailIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
              </>
            )}
            <IconButton
              onClick={() => {
                const website = params.row.website || "";
                if (website.includes("http://") || website.includes("https://"))
                  window.open(website, "_blank");
              }}
            >
              <ToolTip title="Click to Visit Website" placement="top">
                <WebsiteIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      headerName: "Name",
      field: "name",
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      minWidth: 175,
      flex: 1,
      renderCell: (params) => {
        if (params.row.parentId) {
          const parent = params.row.parent;
          if (parent)
            return (
              <ToolTip
                title={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        navigate(`/crm/accounts/${parent.accountId}`)
                      }
                    >
                      <Typography color="#007AFF">{parent.name}</Typography>
                      <IconButton>
                        <LaunchIcon
                          style={{
                            color: "#007AFF",
                            height: "14px",
                            width: "14px"
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px"
                      }}
                    >
                      <Typography color="#64748B" variant="body2">
                        {`${
                          CRM_ACCOUNT_CATEGORIES.find(
                            (c) => c.value === parent.category
                          )?.label
                        } • ${
                          CRM_ACCOUNT_TYPES.find((c) => c.value === parent.type)
                            ?.label
                        }`}
                      </Typography>
                      <Typography color="#64748B" variant="body2">
                        {parent.officeAddress}
                      </Typography>
                    </div>
                  </div>
                }
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>{params.row.name}</span>
                  <span>
                    <img src={GoverningBodyIcon} />
                  </span>
                </div>
              </ToolTip>
            );
        }
        return <div>{params.row.name}</div>;
      }
    },
    {
      headerName: "Industry",
      field: "category",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_ACCOUNT_CATEGORIES.find((c) => c.value === row.category)?.label
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_ACCOUNT_TYPES.find((c) => c.value === row.type)?.label
    },
    {
      headerName: "Primary Contact",
      field: "contact",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        if (params.row.contacts?.filter((c) => c.isPrimary)?.length) {
          return (
            <>
              <span>
                {" "}
                {formatFullName(
                  params.row.contacts?.[0]?.contact as ModelPerson
                )}
              </span>
              {params.row.contacts.filter((c) => c.isPrimary).length > 1 && (
                <ToolTip
                  title={params.row.contacts
                    .slice(1)
                    .map((p) => formatFullName(p.contact as ModelPerson))
                    .join(", ")}
                  placement="top-start"
                >
                  <span style={{ marginLeft: "7px", color: "#007AFF" }}>
                    {" "}
                    {` +${
                      params.row.contacts.filter((c) => c.isPrimary).length - 1
                    }`}
                  </span>
                </ToolTip>
              )}
            </>
          );
        }
      }
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => row.officeAddress
    },
    ...(!organizationId
      ? [
          {
            headerName: "BDR Owner",
            field: "bdrOwner",
            minWidth: 250,
            flex: 1,
            renderCell: ({ row }) =>
              `${formatFullName(row.bdrOwner?.person as ModelPerson)} ${
                row?.bdrOwner?.userId === user?.userId ? "(You)" : ""
              }`
          },
          {
            headerName: "AE Owner",
            field: "aeOwner",
            minWidth: 250,
            flex: 1,
            renderCell: ({ row }) =>
              `${formatFullName(row.aeOwner?.person as ModelPerson)} ${
                row.aeOwner?.userId === user?.userId ? "(You)" : ""
              }`
          },
          {
            headerName: "CSM Owner",
            field: "csmOwner",
            minWidth: 250,
            flex: 1,
            renderCell: ({ row }) =>
              `${formatFullName(row.csmOwner?.person as ModelPerson)} ${
                row.csmOwner?.userId === user?.userId ? "(You)" : ""
              }`
          }
        ]
      : [
          {
            headerName: "Account Owner",
            field: "owner",
            minWidth: 250,
            flex: 1,
            renderCell: ({ row }) =>
              `${formatFullName(row.owner?.person as ModelPerson)} ${
                row.owner?.userId === user?.userId ? "(You)" : ""
              }`
          }
        ])
  ];
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.accounts", "ADD");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const view = await checkPermission("crm.accounts", "VIEW");

      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const { data: accounts } = !organizationId
    ? useGetAdminCrmAccount({
        userAccounts: true
      })
    : useGetAdminCrmOrgAccount({
        organizationId: organizationId,
        userAccounts: true
      });

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "My Accounts",
        value: "mine"
      },
      ...(accounts?.data.numRows && accounts?.data.numRows > 0
        ? [
            {
              label: "My Daily Plan",
              value: "daily_plan"
            }
          ]
        : [])
    ]
  };
  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Accounts"
          useGet={organizationId ? getCrmOrgAccounts : getCrmAccounts}
          columns={ACCOUNTS_COLUMNS}
          getRowId={(row) => row.accountId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          quickAdd={permissions?.create ? onQuickAdd || undefined : undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
          rowSelection={false}
        />
      </Loader>
      {quickAdd && (
        <QuickAddAccount
          onClose={onQuickAddClose}
          onSave={(res) => {
            const rk = refreshKey;
            setRefreshKey(rk + 1);
            if (res) navigate(`/crm/accounts/${res.accountId}`);
          }}
          organizationId={organizationId}
        />
      )}
      {showMeeting && selectedMeetingAccount && (
        <CreateMeetingModal
          open={showMeeting}
          setOpen={setShowMeeting}
          selectedAccount={selectedMeetingAccount}
          autoSuggestOptions={selectedMeetingAutoSuggestions}
        />
      )}
    </>
  );
};
