/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty
} from "@silevis/reactgrid";
import React from "react";

export class WrappedTextCellTemplate implements CellTemplate {
  getCompatibleCell(
    uncertainCell: Uncertain<WrappedTextCell>
  ): Compatible<WrappedTextCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const characterLimit = getCellProperty(
      uncertainCell,
      "characterLimit",
      "number"
    );
    return { ...uncertainCell, text, value: text, characterLimit };
  }

  //For some reason this only works when you leave the unused variables in
  handleKeyDown(
    cell: Compatible<WrappedTextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key?: string,
    capsLock?: boolean
  ): { cell: Compatible<WrappedTextCell>; enableEditMode: boolean } {
    return { cell, enableEditMode: true };
  }

  update(
    cell: Compatible<WrappedTextCell>,
    cellUpdate: Partial<WrappedTextCell>
  ): Compatible<WrappedTextCell> {
    return { ...cell, ...cellUpdate };
  }

  render(
    cell: Compatible<WrappedTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<WrappedTextCell>, commit: boolean) => void
  ) {
    const style: React.CSSProperties = {
      width: "100%",
      height: "100%",
      whiteSpace: "pre-wrap",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      display: "flex",
      alignItems: "center",
      padding: "4px",
      boxSizing: "border-box"
    };

    if (isInEditMode) {
      return (
        <input
          type="text"
          autoFocus
          defaultValue={cell.text}
          onBlur={(e) =>
            onCellChanged(
              { ...cell, text: e.target.value, value: e.target.value },
              true
            )
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onCellChanged(
                {
                  ...cell,
                  text: e.currentTarget.value,
                  value: e.currentTarget.value
                },
                true
              );
              e.preventDefault();
            } else if (e.key === "Backspace" || e.key === "Delete") {
              e.stopPropagation(); // Prevents ReactGrid from capturing Backspace
            }
          }}
          maxLength={cell.characterLimit}
          style={{ ...style, border: "none", outline: "none" }}
        />
      );
    }

    return <div style={style}>{cell.text}</div>;
  }
}

export interface WrappedTextCell extends Cell {
  type: "wrapped-text";
  text: string;
  characterLimit: number;
}
