import { Box, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React from "react";
import { ScoreScale } from "./ScoreScale";
import { FormInput } from "@components/FormInput";

export const IntervalFeedback = ({
  handleScore,
  isIntervalFeedback,
  control
}: {
  handleScore;
  isIntervalFeedback;
  control;
}) => {
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Typography
          sx={{ fontWeight: "300", fontSize: "26px", color: "#000000" }}
        >
          Share Your Experience
        </Typography>
        <Typography
          sx={{ fontWeight: "400", fontSize: "14px", color: "#666666" }}
        >
          We'd love to hear your thoughts! Please rate your experience below.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography
          sx={{ fontWeight: "600", fontSize: "20px", color: "#000000" }}
        >
          {isIntervalFeedback
            ? "Overall, how satisfied are you with SportsGravy?"
            : "How was your onboarding experience with SportsGravy?"}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <ScoreScale onClick={handleScore} />
      </Grid>
      <Grid xs={12}>
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "lightgrey"
          }}
        />
      </Grid>
      <Grid xs={12}>
        <FormInput
          name="description"
          label={
            isIntervalFeedback
              ? "Is there any other features you would like to see in SportsGravy?"
              : "Is there anything else you'd like to share about your experience?"
          }
          control={control}
          multiline={true}
          rows={4}
          type="text"
        />
      </Grid>
    </Grid>
  );
};
