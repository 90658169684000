/* eslint-disable @typescript-eslint/no-explicit-any */
import LocationIcon from "../../../../src/assets/icons/locationIcon.svg";
import MapIcon from "../../../../src/assets/icons/mapIcon.svg";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { useAdminSportLocationGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import GoogleMap from "@components/GoogleMap";
import { Controller } from "react-hook-form";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { Loader } from "@components/crud/Loader";
export const CalendarLocationSearch = (props: {
  name: string;
  disabled?: boolean;
  form?;
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const [openAddressPicker, setOpenAddressPicker] = useState(false);
  const [address, setAddress] = useState("");
  const { data: sportLocation, isLoading: sportLocationLoading } =
    useAdminSportLocationGet({
      organizationId: orgId!,
      pageSize: 1000
    });
  const generateAddress = (location) => {
    const { lines, locality, province, country, postalCode } = location;
    let address = lines.join(", ");
    address += `, ${locality}`;
    address += `, ${province}`;
    address += `, ${country}`;
    address += `, ${postalCode}`;
    return address;
  };

  const sportLocationOptions = useMemo(
    () =>
      sportLocation?.data.locations!.map((location) => ({
        name: location.name,
        value: location.locationId,
        address: generateAddress(location)
      })) || [],
    [sportLocation]
  );

  const closeAddressChange = () => {
    setOpenAddressPicker(false);
  };
  const onAddressChanges = (changedAddress) => {
    console.log("Changed Address", changedAddress);
    const streetNumber = changedAddress.find((c) =>
      c.types.includes("street_number")
    );
    const route = changedAddress.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name || ""} ${
      route?.long_name || ""
    }`;
    const country = changedAddress.find((c) => c.types.includes("country"));
    const state = changedAddress.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    let city = changedAddress.find((c) => c.types.includes("locality"));
    if (!city)
      city = changedAddress.find(
        (c) =>
          c.types.includes("sublocality") || c.types.includes("postal_town")
      );
    const zip = changedAddress.find((c) => c.types.includes("postal_code"));
    setAddress(address1);
    props.form.setValue("address_components", changedAddress);
    console.log("Address", address1, city, state, country, zip);
  };
  const formattedAddress = (address) => {
    setAddress(address);
    props.form.setValue(props.name, address, {
      shouldValidate: true,
      shouldDirty: true
    });
  };
  useEffect(() => {
    const locationId = props.form.getValues("locationId");
    const location = sportLocationOptions.find(
      (loc) => loc.value === locationId
    );
    if (location) {
      setAddress(location.address);
    }
  }, [props.form.getValues("locationId")]);
  return (
    <>
      <div
        style={{
          display: "flex",
          maxWidth: "fit-content",
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        <img src={LocationIcon} />
        <Controller
          name={props.name}
          control={props.form.control}
          rules={{
            required: "Location is required"
          }}
          render={({ field, fieldState }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px"
              }}
            >
              <Autocomplete
                options={sportLocationOptions}
                value={field.value}
                data-testId="event-location-input"
                onChange={(e, value) => {
                  field.onChange(value?.value);
                  setAddress(value?.address);
                  props.form.setValue("address_components", undefined);
                }}
                isOptionEqualToValue={(option, value) => option.value === value}
                getOptionLabel={(option) =>
                  option.name
                    ? `${option.name}, ${option.address}`
                    : sportLocationOptions.find(
                          (loc) => loc.value === field.value
                        )
                      ? sportLocationOptions.find(
                          (loc) => loc.value === field.value
                        )?.name +
                        ", " +
                        sportLocationOptions.find(
                          (loc) => loc.value === field.value
                        )?.address
                      : option
                }
                sx={{ width: "510px" }}
                renderOption={(props, option) => (
                  <li key={option.value} {...props}>
                    <div style={{ display: "flex" }}>
                      <img src={LocationIcon} />
                      <div style={{ marginLeft: "16px" }}>
                        <div style={{ fontSize: "13px", fontWeight: 600 }}>
                          {option.name}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#64748B"
                          }}
                        >
                          {option.address}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <Loader isLoading={sportLocationLoading}>
                    <TextField
                      style={{ maxWidth: "490px" }}
                      className="timezone shareWith"
                      {...params}
                      hiddenLabel
                      variant="standard"
                      placeholder="Select Location"
                    />
                  </Loader>
                )}
                onBlur={field.onBlur}
              />
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            </div>
          )}
        />
        <img
          src={MapIcon}
          style={{ cursor: "pointer" }}
          data-testId="mapIcon"
          onClick={() => setOpenAddressPicker(true)}
        />
      </div>
      {openAddressPicker && (
        <GoogleMap
          close={closeAddressChange}
          onAddressChange={onAddressChanges}
          formattedAddress={formattedAddress}
          address={address}
        />
      )}
    </>
  );
};

CalendarLocationSearch.defaultProps = {
  required: false,
  disabled: false
};
