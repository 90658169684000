import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { RenderRolesWithAliases } from "./RenderRolesWithAliases";
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import {
  ModelJobTitle,
  ModelPersonGuardian,
  ModelRole,
  ModelUserRole,
  ModelUserRoleAliasBan,
  useAdminDepartmentGet,
  useAdminJobTitleGet,
  useLookupCountryCountryIdSubdivisionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { FormTimePicker } from "@components/FormTimePicker";
import { EMPLOYMENT_STATUS_TYPES, EMPLOYMENT_TYPES } from "@utils/constants";
import { TwilioNumberSearchAdd } from "./TwilioNumberSearchAdd";
import { Launch } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { formatNumber } from "@utils/formatNumber";
export const UserDetailsForm = ({
  disabled,
  form,
  country,
  selectAddressSuggestion,
  isEditing,
  countries,
  isFetchingCountries,
  organizationId,
  roles,
  roleOptions,
  hideBanPortion,
  bannedRolesOptions,
  isUserBanned,
  isUnderAge,
  orgBannedRoles,
  sgBannedRoles,
  hideSGUserFields,
  onChangeRolesSelected,
  userChildren,
  userParentRoles,
  teamProgramRoles,
  setShowTPErrorModal,
  allBannedOrgRoles,
  userId
}: {
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, undefined>;
  country: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddressSuggestion: any;
  isEditing: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countries: any;
  isFetchingCountries: boolean;
  organizationId: string | undefined;
  roles?: ModelUserRole[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roleOptions?: any[];
  hideBanPortion?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bannedRolesOptions: any[];
  onChangeRolesSelected?: (roleId, checked) => void;
  isUserBanned?: boolean;
  isUnderAge?: boolean;
  orgBannedRoles?: string[];
  sgBannedRoles?: string[] | ModelUserRoleAliasBan[] | undefined;
  hideSGUserFields?: boolean;
  userChildren?: ModelPersonGuardian[];
  userParentRoles?: ModelRole[];
  teamProgramRoles?: string[];
  setShowTPErrorModal?: (val: boolean) => void;
  allBannedOrgRoles?: ModelUserRoleAliasBan[];
  userId?: string;
}) => {
  const { control, setValue, getValues, trigger, watch } = form;

  const departmentId = watch("sportsgravyUser.departmentId");

  const { data: provinces } = useLookupCountryCountryIdSubdivisionGet(
    country as string,
    {
      query: {
        enabled: !!country
      }
    }
  );
  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });
  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId as string,
        label: province.name as string
      }));
    }
    return [];
  }, [provinces]);
  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId,
        label: country.name
      }));
    }
    return [];
  }, [countries]);

  const selectedCountry = useMemo(() => {
    if (countries?.length) {
      return countries?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countries]);

  const [isBannedUser, setIsBannedUser] = React.useState(isUserBanned);
  const [isActive, setIsActive] = React.useState(true);
  useEffect(() => {
    setIsBannedUser(isUserBanned);
  }, [isUserBanned]);

  const [departments, setDepartments] = React.useState<
    { label: string; value: string }[]
  >([]);

  const { data: departmentsData, isLoading: isLoadingDepatrtments } =
    useAdminDepartmentGet({
      pageSize: 1000
    });

  useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(
        departmentsData.data.departments?.map((dep) => {
          return {
            label: dep.name!,
            value: dep.departmentId!
          };
        }) || []
      );
    }
  }, [departmentsData]);

  const [jobTitles, setJobTitles] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [jobTitle, setJobTitle] = React.useState<ModelJobTitle | undefined>(
    undefined
  );
  const { data: jobTitlesData, isLoading: isLoadingJobTitles } =
    useAdminJobTitleGet({ pageSize: 1000 });

  useEffect(() => {
    if (jobTitlesData?.data) {
      setJobTitles(
        jobTitlesData.data.jobTitles
          ?.filter((job) => !departmentId || job.departmentId === departmentId)
          ?.map((jt) => {
            return {
              label: jt.name!,
              value: jt.jobtitleId!
            };
          }) || []
      );
    }
  }, [jobTitlesData, departmentId]);

  useEffect(() => {
    if (getValues("sportsgravyUser.jobTitleId") && jobTitlesData?.data) {
      const jobTitle =
        jobTitlesData?.data?.jobTitles?.find(
          (jt) => jt.jobtitleId === getValues("sportsgravyUser.jobTitleId")
        ) || undefined;
      if (!jobTitle?.supportsCalls) setValue("sportsgravyUser.sgNumber", null);
      setJobTitle(jobTitle);
    }
    setIsActive(getValues("sportsgravyUser.isActive"));
  }, [
    control._formValues.sportsgravyUser?.jobTitleId,
    jobTitlesData?.data,
    getValues("sportsgravyUser.isActive")
  ]);

  const suffixOptions = [
    { value: "", label: "" },
    { value: "Jr", label: "Jr" },
    { value: "Sr", label: "Sr" },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" }
  ];

  const openRoleDesc = (roleId) => {
    window.open("/roles/" + roleId, "_blank");
  };

  const getOrgRoles = () => {
    const organizationRolesMap = roles!.reduce((map, role) => {
      const roleOrganizationId = role?.role?.organization?.organizationId
        ? role.role.organization.organizationId
        : "0";
      if (!map[roleOrganizationId]) {
        map[roleOrganizationId] = [];
      }
      if (
        role?.role?.alias !== "SPORTSFAN" &&
        ((roleOrganizationId === "0" && role?.role?.type === "SYSTEM") ||
          (roleOrganizationId !== "0" && role?.role?.type === "ORGANIZATION"))
      ) {
        if (isEditing) {
          if (roleOrganizationId === "0" && !organizationId)
            map[roleOrganizationId].push(role);
        } else {
          map[roleOrganizationId].push(role);
        }
      }
      return map;
    }, {});
    return organizationRolesMap;
  };
  const getBannedRolesOptions = (orgBannedRoles: ModelUserRoleAliasBan[]) => {
    const bannedRolesOptions = orgBannedRoles.map((role) => ({
      label: capitalize(role.alias!),
      value: role.alias!
    }));
    if (!isEditing)
      return [...bannedRolesOptions, { label: "All Roles", value: "ALL" }];
    const uniqueBannedRolesOptions: { label: string; value: string }[] = [];
    const seenValues = new Set();

    for (const role of bannedRolesOptions) {
      if (!seenValues.has(role.value)) {
        seenValues.add(role.value);
        uniqueBannedRolesOptions.push(role);
      }
    }
    return uniqueBannedRolesOptions;
  };

  const [isAdminBanDisabled, setIsAdminBanDisabled] = useState(true);
  const renderOrgRoles = (type) => {
    const organizationRolesMap = getOrgRoles();
    if (type === "ROLES") {
      let isFSOTitle = false;
      return (
        <Grid item container direction="column" spacing="30px">
          {Object.keys(organizationRolesMap).map((orgId, idx) => {
            if ((idx == 0 || idx == 1) && orgId !== "0" && !isFSOTitle)
              isFSOTitle = true;
            else isFSOTitle = false;
            return (
              organizationRolesMap[orgId].length > 0 && (
                <Grid
                  item
                  container
                  direction="column"
                  spacing="24px"
                  key={orgId}
                >
                  {isFSOTitle && orgId !== "0" && (
                    <Grid item>
                      <Typography data-testid={"ROLE_ORGNAME_" + orgId}>
                        For Sports Organizations
                      </Typography>
                    </Grid>
                  )}

                  <Grid item>
                    <Typography data-testid={"ROLE_ORGNAME_" + orgId}>
                      For{" "}
                      {orgId === "0"
                        ? " SportsGravy"
                        : organizationRolesMap[orgId][0].role.organization.name}
                    </Typography>
                  </Grid>
                  <Grid item container data-testid="roleContainer">
                    <RenderRolesWithAliases
                      orgRoles={organizationRolesMap[orgId]}
                      control={control}
                      isEditable={isEditing}
                      organizationId={organizationId}
                      disabled={disabled}
                      userChildren={userChildren}
                      userParentRoles={userParentRoles}
                      setValue={setValue}
                      userRoles={roles}
                    />
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      );
    } else if (type === "BAN") {
      if (!isEditing && !organizationId) {
        return (
          <Grid
            item
            container
            direction="column"
            spacing="24px"
            data-testid="UserDetailsBannedRolesDropdown"
          >
            <Grid item container direction="column" spacing="24px">
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={12}>
                  <FormMultiSelect
                    name={"bannedRolesSG"}
                    value={"bannedRolesSG"}
                    control={control}
                    required
                    label="Banned from SportsGravy for the following roles"
                    options={bannedRolesOptions}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            {Object.keys(organizationRolesMap)
              .filter((key) => key != "0")
              .map((orgId) => {
                const bannedRolesSelected =
                  allBannedOrgRoles?.filter(
                    (r) => r.organizationId === orgId
                  ) || [];
                if (bannedRolesSelected.length)
                  return (
                    <Grid
                      item
                      container
                      direction="column"
                      spacing="24px"
                      key={orgId}
                    >
                      <Grid item container direction="row" spacing="24px">
                        <Grid item xs={12}>
                          <FormMultiSelect
                            name="rolesBanned"
                            control={control}
                            preSelectedOptions={
                              (bannedRolesSelected?.length || 0) === 5
                                ? ["ALL"]
                                : bannedRolesSelected.map((r) => r.alias!)
                            }
                            required={orgId === "0"}
                            label={
                              "Banned from " +
                              organizationRolesMap[orgId][0].role.organization
                                .name +
                              " for the following roles"
                            }
                            options={getBannedRolesOptions(bannedRolesSelected)}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
              })}
          </Grid>
        );
      } else {
        return (
          <Grid
            item
            container
            direction="column"
            spacing="24px"
            data-testid="UserDetailsBannedRolesDropdown"
          >
            <Grid item container direction="column" spacing="24px">
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={12}>
                  <FormMultiSelect
                    name={!organizationId ? "bannedRolesSG" : "bannedRoles"}
                    control={control}
                    required
                    label="For the following roles"
                    options={bannedRolesOptions}
                    disabled={disabled}
                    disabledOptions={isAdminBanDisabled ? ["ADMIN"] : []}
                    onChange={(e) => {
                      const bannedRoles = e.target.value as string[];
                      if (!bannedRoles.includes("ADMIN"))
                        bannedRoles.push("ADMIN");
                      setValue(
                        !organizationId ? "bannedRolesSG" : "bannedRoles",
                        bannedRoles,
                        {
                          shouldDirty: true
                        }
                      );
                      setIsAdminBanDisabled(bannedRoles.length > 1);
                    }}
                    onRemove={(val, allOptions) => {
                      setIsAdminBanDisabled((allOptions?.length || 0) > 1);
                    }}
                  />
                </Grid>
              </Grid>

              {isEditing && (
                <Grid item xs={6} data-testid="USER_DETAILS_BAN_REASON">
                  <FormInput
                    name="bannedReason"
                    sideMessage="User will not be notified"
                    control={control}
                    rules={{ required: "Reason is required" }}
                    label="Reason"
                    type="text"
                    required={true}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      spacing="25px"
      data-testid="userDetailsForm"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={12} data-testid="UserDetailsAvatar">
          <FormInput
            name="photoUrl"
            control={control}
            label="Avatar"
            type="avatar"
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={3}>
          <FormInput
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            label="First Name"
            type="text"
            required={true}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name="middleName"
            control={control}
            label="Middle Name"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            label="Last Name"
            type="text"
            required={true}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name="suffix"
            control={control}
            label="Suffix"
            options={suffixOptions}
            disabled
          />
        </Grid>
      </Grid>
      {organizationId && (
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6}>
            <FormInput
              name="phone"
              type="tel"
              country={selectedCountry}
              required
              label="Mobile Phone"
              control={control}
              rules={{
                required: "Mobile Phone is required"
              }}
              disabled
            />
          </Grid>
          {isUnderAge && (
            <Grid item xs={6}>
              <FormDatePicker
                name="birthedAt"
                required
                control={control}
                label="Date of Birth"
                disabled
                rules={{
                  required: "Date of Birth is required"
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      {!organizationId && (
        <Grid item container direction="column" spacing="25px">
          <Grid item width="50%">
            <FormDatePicker
              name="birthedAt"
              required
              control={control}
              label="Date of Birth"
              disabled
              rules={{
                required: "Date of Birth is required"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px"
              }}
            >
              CONTACT DETAILS
            </Typography>
            <HeaderUnderLine />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item container direction="column" xs={6} spacing="10px">
              <Grid item>
                <FormInput
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  label="Email"
                  type="email"
                  required={true}
                  disabled
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  style={{
                    color: "#B3B3B3"
                  }}
                >
                  Note: This data point is controlled by the user, which is why
                  it's not editable.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6} spacing="10px">
              <Grid item data-testid="sgPhoneInput">
                <FormInput
                  name="phone"
                  type="tel"
                  country={selectedCountry}
                  required
                  label="Mobile Phone"
                  control={control}
                  rules={{
                    required: "Mobile Phone is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  style={{
                    color: "#B3B3B3"
                  }}
                >
                  Note: This data point is controlled by the user, which is why
                  it's not editable.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!hideSGUserFields && (
            <Grid
              item
              container
              direction="column"
              spacing="25px"
              data-testid="sgUserFields"
            >
              <Grid item xs={12}>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "10%",
                    lineHeight: "14.52px"
                  }}
                >
                  JOB DETAILS
                </Typography>
                <HeaderUnderLine />
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={12}>
                  <FormCheckbox
                    label="Is Active"
                    name="sportsgravyUser.isActive"
                    control={control}
                    disabled={!isEditing}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormSelect
                    name="sportsgravyUser.departmentId"
                    control={control}
                    label="Department"
                    options={departments}
                    isLoading={isLoadingDepatrtments}
                    required
                    disabled={!isEditing || !isActive}
                    onChange={() => setValue("sportsgravyUser.jobTitleId", "")}
                  />
                </Grid>
                <Grid item xs={6} container direction="column" spacing="20px">
                  <Grid item>
                    <FormSelect
                      name="sportsgravyUser.jobTitleId"
                      control={control}
                      label="Job"
                      options={jobTitles}
                      isLoading={isLoadingJobTitles}
                      onChange={(e) => {
                        const jobTitle =
                          jobTitlesData?.data?.jobTitles?.find(
                            (jt) => jt.jobtitleId === e.target.value
                          ) || undefined;
                        setJobTitle(jobTitle);
                        if (jobTitle) {
                          setValue(
                            "sportsgravyUser.departmentId",
                            jobTitle.departmentId,
                            {
                              shouldValidate: true
                            }
                          );
                        }
                      }}
                      required
                      rules={{
                        required: "Job is required"
                      }}
                      disabled={!isEditing || !isActive}
                    />
                  </Grid>
                  <Grid item>
                    <FormCheckbox
                      control={control}
                      name="sportsgravyUser.isAvailableSalesDemo"
                      label="Available For Sales Demo"
                      labelPadding="4px 0"
                      labelTypographyProps={{
                        variant: "body1"
                      }}
                      disabled={!isEditing || !isActive}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <TwilioNumberSearchAdd
                    control={control}
                    name="sportsgravyUser.sgNumber"
                    disabled={!isEditing || !jobTitle?.supportsCalls}
                    required={jobTitle?.supportsCalls}
                    id={userId}
                    defaultNumber={getValues("sportsgravyUser.sgNumber")}
                  />
                </Grid>
                <Grid item xs={6} data-testid="whatsappNumber">
                  <FormInput
                    name="sportsgravyUser.whatsappNumber"
                    type="tel"
                    country={selectedCountry}
                    label="Whatsapp Number"
                    control={control}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormDatePicker
                    name="sportsgravyUser.startDate"
                    control={control}
                    label="Start Date"
                    required
                    rules={{
                      required: "Start Date is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="sportsgravyUser.yearsOfService"
                    control={control}
                    label="Years of Service"
                    type="number"
                    required
                    rules={{
                      required: "Years of Service is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={3}>
                  <FormTimePicker
                    name="sportsgravyUser.startTime"
                    control={control}
                    label="Start Time"
                    required
                    rules={{
                      required: "Start Time is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormTimePicker
                    name="sportsgravyUser.endTime"
                    control={control}
                    label="End Time"
                    required
                    rules={{
                      required: "End Time is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    control={control}
                    required
                    name="sportsgravyUser.timeZone"
                    label="Time Zone"
                    disabled={!isEditing || !isActive}
                    options={timeZoneOptions}
                    rules={{
                      required: "Time Zone is required"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={3}>
                  <FormSelect
                    name="sportsgravyUser.employmentType"
                    control={control}
                    label="Employment Type"
                    options={EMPLOYMENT_TYPES}
                    required
                    rules={{
                      required: "Employment Type is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormSelect
                    name="sportsgravyUser.employmentStatus"
                    control={control}
                    label="Employment Status"
                    options={EMPLOYMENT_STATUS_TYPES}
                    required
                    rules={{
                      required: "Employment Status is required"
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    required
                    name="sportsgravyUser.jiraAccountId"
                    type="text"
                    label="Jira Id"
                    disabled={!isEditing || !isActive}
                    rules={{
                      required: "Jira Id is required"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormInput
                    name="sportsgravyUser.hourlyRate"
                    control={control}
                    rules={{ required: "Hourly rate is required" }}
                    label="Hourly Rate"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      )
                    }}
                    required={true}
                    disabled={!isEditing || !isActive}
                    onChange={(e) => {
                      let value = (e as ChangeEvent<HTMLInputElement>)?.target
                        .value;
                      value = value.replace(/[^0-9.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        value = parts[0] + "." + parts.slice(1).join("");
                      }
                      setValue("sportsgravyUser.hourlyRate", value);
                    }}
                    onBlur={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>)?.target
                        .value;
                      setValue(
                        "sportsgravyUser.hourlyRate",
                        formatNumber(value, { maxDecimalDigits: 2 })
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInput
                    name="sportsgravyUser.avgHoursPerMonth"
                    control={control}
                    type="number"
                    label="Avg Hours per Month"
                    required
                    rules={{
                      required: "Avg Hours per Month is required"
                    }}
                    onChange={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>).target
                        .value;
                      if (value && !isNaN(Number(value))) {
                        const hpm = parseInt(value);
                        if (hpm) {
                          setValue("sportsGravyUserAvgHoursPerWeek", hpm / 4);
                        }
                      }
                    }}
                    disabled={!isEditing || !isActive}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInput
                    name="sportsGravyUserAvgHoursPerWeek"
                    control={control}
                    type="number"
                    label="Avg Hours per Week"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {organizationId && (
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6}>
            <FormCheckbox
              key="isUnderAge"
              control={control}
              name="isUnderAge"
              label="This person is below 18 years of age"
              labelPadding="4px 0"
              labelTypographyProps={{
                variant: "body1"
              }}
              disabled
            />
          </Grid>
        </Grid>
      )}
      {/* All users by default have sports fan role, hence roles.length has to be more than 1 for roles to be displayed */}
      {!isEditing && !organizationId && roles && roles.length > 1 && (
        <Grid item container spacing="10px">
          <Grid item xs={6}>
            <Typography
              variant="permissionNames"
              sx={{
                color: "#00000",
                fontSize: "12px",
                font: "inter",
                lineHeight: "14.52px",
                letterSpacing: "10%",
                fontWeight: 400
              }}
            >
              ROLES & SUB ROLES
            </Typography>
          </Grid>
          <HeaderUnderLine width="100%" />
          <Grid item width="100%">
            {renderOrgRoles("ROLES")}
          </Grid>
        </Grid>
      )}

      {!hideBanPortion && (
        <>
          <Grid item xs={6}>
            <Typography
              variant="permissionNames"
              sx={{
                color: "#00000",
                fontSize: "12px",
                font: "inter",
                lineHeight: "14.52px",
                letterSpacing: "10%",
                fontWeight: 400
              }}
            >
              BAN
            </Typography>
            <HeaderUnderLine />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing="24px"
            style={{ marginLeft: "-1px" }}
          >
            <Grid
              xs={6}
              style={{ marginTop: "10px" }}
              data-testid={"userDetailsIsBanned"}
            >
              <FormCheckbox
                key="bannedCheckBox"
                control={control}
                name="isBanned"
                onChange={() => {
                  setValue("isBanned", !isBannedUser);
                  setIsBannedUser(!isBannedUser);
                }}
                label={
                  !organizationId
                    ? isEditing
                      ? "Ban this user from SportsGravy"
                      : "Banned from SportsGravy"
                    : isEditing
                      ? "Ban this user from the Organization"
                      : "Banned from Organization"
                }
                labelPadding="4px 8px"
                labelTypographyProps={{
                  variant: "body1"
                }}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </>
      )}

      {!hideBanPortion && isBannedUser && renderOrgRoles("BAN")}
      {(isEditing || organizationId) && roleOptions && roleOptions.length && (
        <>
          <Grid item xs={6}>
            <Typography
              variant="permissionNames"
              sx={{
                color: "#00000",
                fontSize: "12px",
                font: "inter",
                lineHeight: "14.52px",
                letterSpacing: "10%",
                fontWeight: 400
              }}
            >
              ROLES & SUB ROLES
            </Typography>
            <HeaderUnderLine />
          </Grid>
          <RenderRolesWithAliases
            orgRoles={roleOptions}
            control={control}
            isEditable={isEditing}
            organizationId={organizationId}
            disabled={disabled}
            onChangeRolesSelected={onChangeRolesSelected}
            openRoleDesc={openRoleDesc}
            sgBannedRoles={sgBannedRoles}
            orgBannedRoles={orgBannedRoles}
            userChildren={userChildren}
            setValue={setValue}
            userParentRoles={userParentRoles}
            teamProgramRoles={teamProgramRoles}
            setShowTPErrorModal={setShowTPErrorModal}
            userRoles={roles}
          />
        </>
      )}

      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <Typography
            variant="permissionNames"
            sx={{
              color: "#00000",
              fontSize: "12px",
              font: "inter",
              lineHeight: "14.52px",
              letterSpacing: "10%",
              fontWeight: 400
            }}
          >
            ADDRESS DETAILS
          </Typography>
        </Grid>
      </Grid>
      <HeaderUnderLine marginLeft="21px" />
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormSelect
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            options={countryOptions}
            isLoading={isFetchingCountries}
            label="Country"
            required
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <AddressAutocomplete
            name="address1"
            control={control}
            selectSuggestion={selectAddressSuggestion}
            rules={{
              required: "Address is required"
            }}
            label="Address Line 1"
            required
            disabled
            trigger={trigger}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormInput
            name="address2"
            control={control}
            label="Address Line 2"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="locality"
            control={control}
            rules={{ required: "City is required" }}
            label="City"
            type="text"
            required
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormSelect
            name="province"
            control={control}
            rules={{ required: "State/Province is required" }}
            label="State/Province"
            options={provincesOptions}
            required
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="postalCode"
            control={control}
            rules={{ required: "Zip is required" }}
            label="Zip Code"
            type="text"
            required
            disabled
          />
        </Grid>
      </Grid>
      {!organizationId && disabled && (
        <>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400
                }}
              >
                SUBSCRIPTION
              </Typography>
            </Grid>
          </Grid>
          <HeaderUnderLine marginLeft="21px" />
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormInput
                name="subscriptionPlan.name"
                control={control}
                label="Plan"
                type="text"
                required
                disabled
              />
            </Grid>
            {getValues("subscriptionPlan.planId") === "plan.family" && (
              <Grid item xs={6}>
                <FormInput
                  name="subscriptionPlan.ownerName"
                  control={control}
                  label="Plan owner"
                  type="text"
                  required
                  disabled
                  InputProps={{
                    ...(getValues("subscriptionPlan.ownerId") && {
                      endAdornment: (
                        <Link
                          to={`/users/${getValues("subscriptionPlan.ownerId")}`}
                          target="_blank"
                        >
                          <IconButton>
                            <Launch
                              style={{
                                color: "#007AFF"
                              }}
                            />
                          </IconButton>
                        </Link>
                      )
                    })
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

UserDetailsForm.defaultProps = {
  countries: [],
  isFetchingCountries: false,
  hideBanPortion: false,
  isUnderAge: false
};
