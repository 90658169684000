import { Container } from "@components/crud/Container";
import {
  Add,
  AddBox,
  DeleteOutline,
  IndeterminateCheckBox
} from "@mui/icons-material";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { Button as ButtonComponent } from "@components/Button";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PaymentDetailsForm } from "./PaymentDetailsForm";
import {
  adminBillingPaymentGet,
  adminCrmOrderOrderIdPaymentDetailGet,
  ModelPaymentDetail,
  PaymentDetailListResponse,
  useAdminCrmOrderOrderIdPaymentIdDelete,
  useAdminCrmOrderOrderIdPaymentIdPrimaryPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { NoRecords } from "@components/NoRecords";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationsAtom } from "@recoil/auth";

export const OrderPaymentDetails = ({
  orderId,
  organizationId
}: {
  orderId: string;
  organizationId?: string;
}) => {
  const form = useForm({
    mode: "onTouched"
  });
  const { reset } = form;
  const navigate = useNavigate();
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const convertExpiryToDate = (mmyy: string) => {
    const month = parseInt(mmyy.slice(0, 2), 10) - 1;
    const year = parseInt(mmyy.slice(2, 4), 10) + 2000;

    return new Date(year, month);
  };
  const [paymentDetail, setPaymentDetails] = useState<
    (ModelPaymentDetail & { paymentInfo })[]
  >([]);
  const [currentExpanded, setCurrentExpanded] = useState<string[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchAndReset = async () => {
      try {
        setIsLoading(true);
        const paymentDetailsData = organizationId
          ? await adminBillingPaymentGet({
              organizationId: organizationId
            })
          : await adminCrmOrderOrderIdPaymentDetailGet(orderId);
        const paymentDetails = !organizationId
          ? ((paymentDetailsData.data as PaymentDetailListResponse)
              .paymentDetails as (ModelPaymentDetail & { paymentInfo })[])
          : (paymentDetailsData.data as (ModelPaymentDetail & {
              paymentInfo;
            })[]);

        const sortedPaymentDetails = paymentDetails.sort(
          (a, b) => Number(b.isPrimary) - Number(a.isPrimary)
        );

        setIsLoading(false);

        reset({
          details: paymentDetails.map((pd) => ({
            type: pd.paymentType,
            number: "XXXX XXXX XXXX " + (pd.info?.payment?.number || ""),
            expirationDate:
              pd.paymentType !== "BANK_ACH" && pd.info?.expiration
                ? convertExpiryToDate(pd.info?.expiration)
                : "",

            cardName: `${pd.info?.customer?.first} ${pd.info?.customer?.last}`,
            country: org?.country || "US",
            zipCode: pd.info?.customer?.zip,
            billingInstructions: pd.billingInstructions,
            routingNumber:
              "XXXX XXXX XXXX " + (pd.info?.payment?.routing || ""),
            accountType: pd.info?.origin,
            accountName: `${pd.info?.customer?.first} ${pd.info?.customer?.last}`
          }))
        });
        setPaymentDetails(sortedPaymentDetails);
        if (sortedPaymentDetails.length > 0 && currentExpanded.length === 0) {
          setCurrentExpanded([sortedPaymentDetails[0].paymentId!]);
        }
      } catch {
        setIsLoading(false);
      }
    };

    fetchAndReset();
  }, [refreshKey]);

  const { mutate: save } = useAdminCrmOrderOrderIdPaymentIdPrimaryPut();
  const [paymentDetailToDelete, setPaymentToDelete] = useState<
    (ModelPaymentDetail & { paymentInfo }) | null
  >();
  const [paymentDetailToEdit, setPaymentToEdit] = useState<
    (ModelPaymentDetail & { paymentInfo }) | null
  >();
  const onMakePrimary = (paymentId: string) => {
    save(
      {
        orderId: orderId!,
        paymentId: paymentId
      },
      {
        onSuccess: () => {
          setRefreshKey(refreshKey + 1);
        },
        onError: () => {
          enqueueSnackbar("Unable to Update Primary Payment", {
            variant: "error"
          });
        }
      }
    );
  };
  const { mutate: del } = useAdminCrmOrderOrderIdPaymentIdDelete();
  const onDeletePaymentDetail = (paymentId: string) => {
    del(
      {
        orderId: orderId,
        paymentId: paymentId
      },
      {
        onSuccess: () => {
          setRefreshKey(refreshKey + 1);
        },
        onError: () => {
          enqueueSnackbar("Unable to Delete Payment Detail", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Container>
      <Loader isLoading={isLoading}>
        {paymentDetail.length > 0 && (
          <div
            style={{
              padding: "24px",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h2"
              style={{ fontSize: "32px", fontWeight: 300 }}
            >
              Payment Details
            </Typography>
            <ButtonComponent
              variant="admin-primary"
              startIcon={<Add style={{ height: "24px", width: "24px" }} />}
              onClick={() =>
                navigate(`/crm/orders/${orderId}/paymentDetail/create`)
              }
            >
              Add
            </ButtonComponent>
          </div>
        )}
        {paymentDetail.map((payment, idx) => {
          const isExpanded = currentExpanded.includes(payment.paymentId!);
          return (
            <Grid
              container
              direction="column"
              key={payment.paymentId}
              style={{
                borderBottom: "1px solid #E5E5E5"
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                style={{
                  backgroundColor: "#F1F5FF",
                  minHeight: "56px"
                }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  xs={9}
                >
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        if (isExpanded)
                          setCurrentExpanded([
                            ...currentExpanded.filter(
                              (c) => c !== payment.paymentId
                            )
                          ]);
                        else
                          setCurrentExpanded([
                            ...currentExpanded,
                            payment.paymentId!
                          ]);
                      }}
                    >
                      {isExpanded ? (
                        <IndeterminateCheckBox htmlColor="#2B337A" />
                      ) : (
                        <AddBox htmlColor="#2B337A" />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item marginTop="5px">
                    <Typography
                      style={{
                        color: "#666666",
                        fontSize: "18px",
                        fontWeight: 600
                      }}
                    >
                      {`${
                        CRM_ORDER_PAYMENT_TYPES.find(
                          (o) => o.value === payment.paymentType
                        )?.label
                      } ${
                        "XXXX XXXX XXXX " +
                        (payment.info?.payment?.number || "")
                      } ${payment.isPrimary ? " (Primary)" : ""}`}
                    </Typography>
                  </Grid>
                </Grid>
                {!payment.isPrimary && (
                  <Grid
                    item
                    container
                    direction="row"
                    xs={3}
                    alignItems="center"
                    spacing="20px"
                  >
                    <Grid item>
                      <Button
                        startIcon={<StarOutlineRoundedIcon color="primary" />}
                        onClick={() => {
                          setPaymentToEdit(payment);
                        }}
                      >
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontWeight: 600
                          }}
                        >
                          Mark as Primary
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<DeleteOutline htmlColor="#E82C2C" />}
                        onClick={() => {
                          setPaymentToDelete(payment);
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontWeight: 600,
                            color: "#E82C2C"
                          }}
                        >
                          Remove
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {isExpanded && (
                <Grid item container>
                  <PaymentDetailsForm
                    form={form}
                    disabled
                    paymentType={payment.paymentType!}
                    index={idx}
                    isFailedPayment={false}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
        {paymentDetail.length === 0 && (
          <NoRecords
            title="No Payment Details Found"
            buttonText="Add Payment Detail"
            buttonClick={() =>
              navigate(`/crm/orders/${orderId}/paymentDetail/create`)
            }
            buttonIcon={<Add />}
          />
        )}
      </Loader>
      <ConfirmationDialog
        title="Delete Payment Detail"
        body={
          <span style={{ fontSize: "14px" }}>
            Are you sure you want to delete payment detail{" "}
            <b>
              {
                CRM_ORDER_PAYMENT_TYPES.find(
                  (o) => o.value === paymentDetailToDelete?.paymentType
                )?.label
              }{" "}
              {"XXXX XXXX XXXX " +
                (paymentDetailToEdit?.info?.payment?.bin || "")?.slice(-4)}
            </b>{" "}
            for this order <b>{orderId!}</b>
          </span>
        }
        open={!!paymentDetailToDelete}
        close={() => setPaymentToDelete(null)}
        onCancel={() => setPaymentToDelete(null)}
        onConfirm={() => {
          if (paymentDetailToDelete?.paymentId) {
            onDeletePaymentDetail(paymentDetailToDelete.paymentId);
          }
        }}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
      <ConfirmationDialog
        title="Are You Sure?"
        body={`Are You Sure you want to make "${
          CRM_ORDER_PAYMENT_TYPES.find(
            (o) => o.value === paymentDetailToEdit?.paymentType
          )?.label
        } ${
          "XXXX XXXX XXXX " +
            (paymentDetailToEdit?.info?.payment?.bin || "")?.slice(-4) || ""
        }" as primary payment method for this order "${orderId!}"`}
        open={!!paymentDetailToEdit}
        close={() => setPaymentToEdit(null)}
        onCancel={() => setPaymentToEdit(null)}
        onConfirm={() => {
          if (paymentDetailToEdit?.paymentId)
            onMakePrimary(paymentDetailToEdit.paymentId);
        }}
        cancelBtnText="No"
        confirmBtnText="Yes"
      />
    </Container>
  );
};
