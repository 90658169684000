import { Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import {
  Header,
  HeaderText,
  MainContainer
} from "../components/CreateMeetingModal";
import { CloseIcon } from "@components/Icons";
import { Form } from "@components/crud/Form";
import { useState } from "react";
import { Button } from "@components/Button";

export const ConfirmOrderModale = ({
  onClose,
  contactName,
  contactEmail,
  onSave,
  isLoading
}: {
  onClose: () => void;
  contactName: string;
  contactEmail?: string;
  onSave: (workEmail?: string) => void;
  isLoading: boolean;
}) => {
  const [workEmail, setWorkEmail] = useState(contactEmail || "");
  const [helperText, setHelperText] = useState("");
  const validate = (value) => {
    {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(value) && value && value != "") {
        setHelperText("Invalid Email");
      } else setHelperText("");
    }
  };
  return (
    <Modal
      open={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <MainContainer
        style={{
          width: "500px"
        }}
      >
        <Header>
          <HeaderText>{`Send Order`}</HeaderText>
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <Form>
          <Grid container direction="column" spacing="10px" width="100%">
            <Grid item>
              <Typography
                style={{ fontWeight: "bold" }}
              >{`Send order to ${contactName}'s Work Email`}</Typography>
            </Grid>
            <Grid item>
              <TextField
                value={workEmail}
                style={{
                  width: "100%"
                }}
                disabled={!!contactEmail}
                onChange={(e) => {
                  setWorkEmail(e.target.value);
                  validate(e.target.value);
                }}
                onBlur={(e) => {
                  if (!e.target.value) setHelperText("Work Email is Required");
                }}
                helperText={helperText}
              />
              <Grid
                item
                container
                direction="row"
                spacing="10px"
                marginTop="20px"
                alignItems="center"
                justifyContent="end"
              >
                <Grid item>
                  <Button
                    variant="admin-secondary"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="admin-primary"
                    disabled={!workEmail || !!helperText || isLoading}
                    onClick={() => {
                      onSave(contactEmail ? undefined : workEmail);
                    }}
                    isLoading={isLoading}
                  >
                    {contactEmail
                      ? "Send Order"
                      : "Update Contact & Send Order"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </MainContainer>
    </Modal>
  );
};
