import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  styled,
  Typography
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";
import { CloseIcon } from "@components/Icons";
import { QuickAddContactForm } from "../components/QuickAddContactForm";
import { AccountDetailsForm } from "../components/AccountDetailsForm";
import { ContactDetailsSubForm } from "../components/ContactDetailsSubForm";
import { FormDatePicker } from "@components/FormDatePicker";
import formatFullName from "@utils/formatFullName";
import { QuickAddAccount } from "../accounts/components/QuickAddAccount";
import { Loader } from "@components/crud/Loader";
import { useParams } from "react-router-dom";
import { formatNumber } from "@utils/formatNumber";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

const billingOptionList = (billingOptions) => {
  return billingOptions.map((stage) => (
    <MenuItem
      key={stage.value}
      value={stage.value}
      style={{
        maxWidth: "39.5vw"
      }}
    >
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Typography>{stage.label}</Typography>
        </Grid>
        <Grid item>
          <Typography
            style={{
              display: "block",
              color: "#B3B3B3",
              fontSize: "12px",
              wordWrap: "break-word", // Ensures word wrapping
              overflowWrap: "break-word", // Modern equivalent
              whiteSpace: "normal" // Allow normal text wrapping
            }}
          >
            {stage.description}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  ));
};

export const OrderDetailsForm = ({
  disabled,
  form,
  mode,
  selectedAccount,
  setSelectedAccount,
  selectedContact,
  setSelectedContact,
  contacts,
  setContacts,
  billingOptions,
  startDateRequirement,
  organizationId
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  selectedAccount;
  setSelectedAccount;
  selectedContact;
  setSelectedContact;
  contacts;
  setContacts;
  billingOptions: { label: string; value: string; description?: string }[];
  startDateRequirement: number;
  organizationId?: string;
  mode?: "CREATE" | "EDIT" | "VIEW";
}) => {
  const { control, setValue, getValues, watch } = form;

  const amount = watch("amount");

  const { orderId } = useParams();
  useEffect(() => {
    if (selectedContact) {
      const contact = contacts.find((c) => c.value === selectedContact);
      if (contact) {
        setValue(
          "contact",
          {
            name: formatFullName(contact.details),
            personalEmail: contact.details.email,
            homeAddress: contact.details.location,
            phoneType: "MOB",
            phone: contact.details.phone,
            whatsappNumber: contact.details.whatsappNumber,
            workEmail: contact.workEmail,
            workPhone: contact.workPhone,
            jobTitle: contact.jobTitle
          },
          {
            shouldDirty: true,
            shouldValidate: true
          }
        );
      }
    } else {
      setValue(
        "contact",
        {
          name: "",
          personalEmail: "",
          homeAddress: "",
          phoneType: "MOB",
          phone: "",
          whatsappNumber: ""
        },
        {
          shouldDirty: true,
          shouldValidate: true
        }
      );
    }
  }, [selectedContact, contacts]);

  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const [showQuickAddAccount, setShowQuickAddAccount] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("$");
  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    setCurrencySymbol(formatter.formatToParts(1)[0].value);
  }, []);

  return (
    <Loader isLoading={false}>
      <Grid
        data-testid="order-detail-form"
        container
        direction="column"
        spacing="25px"
      >
        {showQuickAddAccount && (
          <QuickAddAccount
            onClose={() => {
              setShowQuickAddAccount(false);
            }}
            onSave={(resp) => {
              setValue("account", {
                name: resp.name,
                parent: resp.parent?.name,
                category: resp.category,
                type: resp.type,
                officeAddress: resp.officeAddress,
                email: resp.email,
                website: resp.website,
                sportsOffered: [],
                noOfAthletes: "",
                aeOwner: ""
              });
              setSelectedAccount(resp.accountId);
              setShowQuickAddAccount(false);
            }}
            addNewAccountOption={false}
          />
        )}

        <Modal
          open={showAddContactModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>{`Quick Add Contact for ${getValues(
                "account.name"
              )}`}</HeaderText>
              <IconButton
                onClick={() => {
                  setShowAddContactModal(false);
                  //setRefreshKey(refreshKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>

            {selectedAccount && (
              <QuickAddContactForm
                onClose={() => {
                  setShowAddContactModal(false);
                }}
                onSave={(resp) => {
                  const options = contacts;
                  setContacts([
                    ...options,
                    {
                      label: `${resp.firstName} ${resp.lastName}`,
                      value: resp.contactId,
                      isPrimary: !!resp.accounts?.[0]?.isPrimary,
                      details: resp,
                      jobTitle: resp.accounts?.[0]?.jobTitle || "",
                      workEmail: resp.accounts?.[0].workEmail || "",
                      workPhone: resp.accounts?.[0]?.workPhone || ""
                    }
                  ]);
                  setSelectedContact(resp.contactId);
                  setShowAddContactModal(false);
                }}
                accountId={selectedAccount}
                accountName={getValues("account.name")}
              />
            )}
          </MainContainer>
        </Modal>

        <Grid item container direction="row" spacing="24px">
          {!organizationId && (
            <Grid item xs={6}>
              <FormInput
                name="submittedBy"
                control={control}
                label="Order Submitted By"
                type="text"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormDatePicker
              name="orderDate"
              control={control}
              label="Order Date"
              disabled
              required
              rules={{ required: "Close Date is required" }}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px" alignItems="center">
          <Grid item xs={6}>
            <FormInput
              name="product"
              control={control}
              label="Product"
              type="text"
              disabled
              required
              rules={{ required: "Product is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="orderType"
              control={control}
              label="Order Type"
              type="text"
              disabled
              required
            />
          </Grid>
        </Grid>
        <Grid item marginTop="-20px" marginLeft="50%">
          <Typography
            style={{
              color: "#B3B3B3",
              marginTop: "5px"
            }}
          >
            Note: This subscription order is based on Per Athlete, Per
            Registration
          </Typography>
        </Grid>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6}>
            <FormDatePicker
              name="startDate"
              control={control}
              label="Start Date"
              disabled={disabled}
              disablePast={mode === "CREATE"}
              shouldDisableDate={(date) => {
                const today = new Date();
                const startDateRange = new Date(today);
                startDateRange.setDate(today.getDate() + startDateRequirement);
                const oneYearInFuture = new Date(today);
                oneYearInFuture.setFullYear(today.getFullYear() + 1);
                if (date <= startDateRange) {
                  return true;
                }
                if (date > oneYearInFuture) {
                  return true;
                }
                return false;
              }}
              required
              onChange={(val) => {
                const endDate = new Date();
                endDate.setDate(val.getDate());
                endDate.setFullYear(val.getFullYear() + 1);
                setValue("endDate", endDate);
              }}
              rules={{ required: "Start Date is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              name="endDate"
              control={control}
              label="End Date"
              disabled
              required
              rules={{ required: "End Date is required" }}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={3}>
            <FormInput
              name="noOfAthletes"
              control={control}
              rules={{
                required: "No. of Yearly Athlete Registrations is required"
              }}
              label="No. of Yearly Athlete Registrations"
              type="number"
              required={true}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              name="costOfAthletesPeryear"
              control={control}
              label={"\u00A0"}
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="amount"
              control={control}
              rules={{ required: "Amount is required" }}
              label="Estimated Amount"
              enteredValue={formatNumber(amount)}
              type="text"
              required={true}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing="24px" alignItems="center">
          <Grid item xs={6}>
            <FormSelect
              name="billingOption"
              control={control}
              label="Billing Option"
              options={billingOptions}
              required
              disabled={disabled}
              customOptionsComponent={billingOptionList(billingOptions)}
            />
          </Grid>
        </Grid>

        {!organizationId && (
          <Grid item xs={12}>
            <FormInput
              name="notes"
              control={control}
              disabled={disabled}
              label="Notes"
              type="text"
              multiline
              rows={4}
            />
          </Grid>
        )}
        {!organizationId && (
          <AccountDetailsForm
            disabled
            form={form}
            hideNoOfAthletes
            setSelectedAccount={setSelectedAccount}
            setIsNewAccountSelected={setShowQuickAddAccount}
          />
        )}
        {!organizationId && (
          <ContactDetailsSubForm
            disabled={disabled}
            form={form}
            contactOptions={contacts}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            setShowAddContactModal={setShowAddContactModal}
            selectedAccount={selectedAccount}
            relatesTo="ORDER"
            relatesToId={orderId as string}
            account={{
              accountId: selectedAccount,
              name: getValues("account.name"),
              type: getValues("account.type"),
              officeAddress: getValues("account.officeAddress")
            }}
          />
        )}
      </Grid>
    </Loader>
  );
};
