import {
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  marginTop: "8px",
  backgroundColor: "#E5E5E5",
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(90deg, #1ABC9C 0%, #00987A 100%)"
  }
}));

export const CustomProgressBar = ({
  title,
  percentageCompleted,
  recordsProcessed,
  totalRecords,
  elapsedTime
}: {
  title: string;
  percentageCompleted: number;
  recordsProcessed: number;
  totalRecords: number;
  elapsedTime: string;
}) => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px 32px",
        backgroundColor: "#F8FAFC",
        margin: "0 24px",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        gap: "8px"
      }}
    >
      {/* Header Information */}
      <Typography
        sx={{
          fontSize: "14px",
          color: "#374151",
          fontWeight: 400
        }}
      >
        {title}
      </Typography>

      {/* Progress Details */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid xs="auto">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#1F2937"
            }}
          >
            {percentageCompleted}% Completed ({recordsProcessed} of{" "}
            {totalRecords} Events Processed)
          </Typography>
        </Grid>
        <Grid xs="auto">
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#6B7280"
            }}
          >
            Time Elapsed: <span style={{ fontWeight: 600 }}>{elapsedTime}</span>
          </Typography>
        </Grid>
      </Grid>

      {/* Progress Bar */}
      <BorderLinearProgress variant="determinate" value={percentageCompleted} />
    </Grid>
  );
};
