import { FormSelect } from "@components/FormSelect";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import { Column, DataGridTable } from "@components/DataGridTable";
import { capitalize } from "@utils/capitalize";
import { JIRA_CARD_STATUSES } from "@utils/constants";
import { ToolTip } from "@components/ToolTip";
import { styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import {
  AdminEpicEpicIdGetPlatform,
  AdminEpicEpicIdGetResponseBreakdown,
  AdminEpicEpicIdGetResponseInsightsItem,
  ModelEpicIssue,
  ModelJiraIssue
} from "@sportsgravyengineering/sg-api-react-sdk";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { useMemo, useState } from "react";

const columns = [
  { label: "Backlog/Ready Commited", color: "#E5E5E5" },
  { label: "In Progress", color: "#FFC05E" },
  { label: "Done", color: "#1ABC9C", fontColor: "#fff" }
];

const statusOptions = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Backlog",
    value: "BACKLOG"
  },
  {
    label: "In Progress",
    value: "IN_PROGRESS"
  },
  {
    label: "Done",
    value: "DONE"
  }
];

const StyledCustomColumnGraph = styled("div")`
  .column-graph {
    margin: 8px 0 !important;
  }
  .graph {
    margin: 0px !important;
  }
`;
const StyledDiv = styled("div")`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  .pinned-body {
    background-color: #fff !important;
  }
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

const StyledTableValue = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const Overview = ({
  setResourceFilter,
  setPlatformFilter,
  platformFilter,
  resourceFilter,
  platformOptions,
  resourcesOptions,
  insights,
  issues,
  breakdown
}: {
  setResourceFilter: (resourceId: string) => void;
  setPlatformFilter: (platform: AdminEpicEpicIdGetPlatform) => void;
  platformFilter: AdminEpicEpicIdGetPlatform | "ALL";
  resourceFilter: string;
  platformOptions: Array<{ label: string; value: string }>;
  resourcesOptions: Array<{ label: string; value: string }>;
  insights: AdminEpicEpicIdGetResponseInsightsItem[] | undefined;
  issues: ModelEpicIssue[];
  breakdown: AdminEpicEpicIdGetResponseBreakdown[];
}) => {
  const [statusFilter, setStatusFilter] = useState("ALL");
  const COLUMNS: Column[] = [
    {
      field: "key",
      headerName: "Card",
      width: 90,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        return (
          <StyledLink
            style={{ fontWeight: 600 }}
            href={`https://sportsgravy.atlassian.net/browse/${params?.jiraIssueId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.issue.key}
          </StyledLink>
        );
      }
    },
    {
      field: "developer",
      headerName: "Developer",
      width: 120,
      sortable: false,
      align: "left",
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        return (
          <StyledTableValue>
            {params?.issue?.assignee?.user?.person?.firstName}{" "}
            {params?.issue.assignee?.user?.person?.lastName}
          </StyledTableValue>
        );
      }
    },
    {
      field: "jiraStatus",
      headerName: "Jira Status",
      width: 150,
      sortable: false,
      align: "left",
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        return (
          <StyledTableValue>
            {
              JIRA_CARD_STATUSES.find((jc) => jc.value === params.issue.status)
                ?.label
            }
          </StyledTableValue>
        );
      }
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      sortable: false,
      align: "left",
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {["STORY", "TASK"].includes(params.issue.type) && (
              <>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "2px",
                    background:
                      params.issue.type === "STORY" ? "#1ABC9C" : "#4bade8",
                    marginRight: "8px",
                    display: "inline-block"
                  }}
                ></span>
                <StyledTableValue>
                  {capitalize(params.issue.type)}
                </StyledTableValue>
              </>
            )}

            {["BUG"].includes(params.issue.type) && (
              <>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "99px",
                    background: "#E82C2C",
                    marginRight: "8px"
                  }}
                ></span>
                <StyledTableValue>Bug</StyledTableValue>
              </>
            )}
          </div>
        );
        return <></>;
      }
    },
    {
      field: "summary",
      headerName: "Use Case / Description",
      width: 300,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <ToolTip title={params.issue.summary}>
            <TwoLineText>{params.issue.summary}</TwoLineText>
          </ToolTip>
        );
      }
    }
  ];

  const filteredIssues = useMemo(() => {
    if (statusFilter === "ALL") {
      return issues;
    }
    if (statusFilter === "BACKLOG")
      return issues.filter(
        (issue) =>
          issue.issue?.status === "READY_COMMITTED" || !issue?.issue?.sprintId
      );
    if (statusFilter === "IN_PROGRESS")
      return issues.filter((issue) =>
        ["IN_PROGRESS", "TODAYS_PLAN", "ON_HOLD"].includes(
          issue.issue?.status as string
        )
      );
    if (statusFilter === "DONE")
      return issues.filter((issue) =>
        ["REVIEW", "QA", "DONE"].includes(issue.issue?.status as string)
      );
  }, [statusFilter]);

  return (
    <>
      <Grid xs={2}>
        <FormSelect
          options={platformOptions}
          name="platform"
          onChange={(e) => setPlatformFilter(e.target.value)}
          sx={{
            height: "40px"
          }}
          value={platformFilter}
        />
      </Grid>
      <Grid xs={2}>
        <FormSelect
          options={resourcesOptions}
          name="resource"
          onChange={(e) => setResourceFilter(e.target.value)}
          sx={{
            height: "40px"
          }}
          value={resourceFilter}
        />
      </Grid>
      <Grid xs={2}>
        <FormSelect
          options={statusOptions}
          name="status"
          onChange={(e) => setStatusFilter(e.target.value)}
          sx={{
            height: "40px"
          }}
          value={statusFilter}
        />
      </Grid>
      {issues.length > 0 ? (
        <>
          <Grid
            xs={12}
            container
            direction="column"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
              boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "10px",
              margin: "0px 10px",
              marginTop: "10px",
              overflow: "hidden" // Ensures inner grids don't show overlapping borders
            }}
          >
            {insights &&
              Array.from(
                {
                  length: 1
                },
                (_, index) => index
              ).map((row, index) => {
                return (
                  <Grid key={index} container direction="row" height="140px">
                    {insights &&
                      insights.map((con, index) => {
                        return (
                          <Grid
                            container
                            direction="column"
                            key={con.label}
                            xs={3}
                            minHeight="121px"
                            padding="24px"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                              border: "1px solid rgba(229, 229, 229, 1)",
                              borderLeft:
                                index === 0
                                  ? "none"
                                  : "1px solid rgba(229, 229, 229, 1)", // Remove left border for first item
                              borderRight:
                                index === insights.length - 1
                                  ? "none"
                                  : "1px solid rgba(229, 229, 229, 1)", // Remove right border for last item
                              borderRadius:
                                index === 0
                                  ? "10px 0 0 10px" // First inner grid
                                  : index === insights.length - 1
                                    ? "0 10px 10px 0" // Last inner grid
                                    : "0" // Other inner grids
                            }}
                          >
                            <Grid>
                              <Typography
                                style={{
                                  color: "#64748B",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "21px"
                                }}
                              >
                                {con.label}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: "32px",
                                  lineHeight: "48px",
                                  color:
                                    index === 3
                                      ? con.value?.includes("-") ||
                                        con.value === "0 hr"
                                        ? "#1ABC9C"
                                        : "#E82C2C"
                                      : "#1E293B"
                                }}
                              >
                                {con.value}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                );
              })}
          </Grid>
          <div style={{ paddingLeft: "10px", width: "calc(100% - 10px)" }}>
            <div
              style={{
                marginTop: "24px",
                display: "flex",
                gap: "16px",
                alignItems: "center"
              }}
            >
              <span style={{ fontWeight: 600 }}>
                Total Jira Cards ({issues?.length})
              </span>
              {columns.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center"
                    }}
                  >
                    <span
                      style={{
                        width: "16px",
                        height: "16px",
                        background: value.color
                      }}
                    ></span>
                    <span>{value.label}</span>
                  </div>
                );
              })}
            </div>
            <div>
              <StyledCustomColumnGraph>
                <CustomColumnGraph
                  columns={columns}
                  values={breakdown}
                  showLabel={false}
                  showToolbar={false}
                />
              </StyledCustomColumnGraph>
              <StyledDiv>
                <DataGridTable
                  rows={filteredIssues as ModelJiraIssue[]}
                  columns={COLUMNS}
                />
              </StyledDiv>
            </div>
          </div>
        </>
      ) : (
        <Grid
          xs={12}
          container
          direction="column"
          padding="25px"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "10px",
            margin: "0px 10px",
            marginTop: "10px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto"
            }}
          >
            <img src={NoDataIcon} style={{ width: "64px" }} />
            <Typography
              style={{
                color: "#64748b",
                fontSize: "14px",
                fontWeight: 500,
                padding: "16px 24px"
              }}
            >
              No data available
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
};

export default Overview;
