import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { hasPermission } from "@services/Casbin";
import {
  ModelHelpCategory,
  useAdminHelpCategoryCategoryIdDelete,
  useAdminHelpCategoryCategoryIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const HelpCategoryView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState({
    delete: false
  });
  const [toDelete, setToDelete] = useState<ModelHelpCategory | null>(null);
  const { helpCategoryId } = useParams();
  const { control, reset } = useForm({
    mode: "onTouched"
  });

  const { data: helpCategory, isLoading: helpCategoryLoading } =
    useAdminHelpCategoryCategoryIdGet(helpCategoryId!);

  useEffect(() => {
    if (helpCategory?.data) {
      reset({
        name: helpCategory.data.name
      });
    }
  }, [helpCategory]);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminHelpCategoryCategoryIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.helpCategoryId) return;
    try {
      await deleteAsync({ helpCategoryId: toDelete.helpCategoryId });
      enqueueSnackbar("Help category deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/help-category");
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete the help category.",
        {
          variant: "error"
        }
      );
      setToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("support.categories", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Help Category"
        backBtnClick={() => navigate("/help-category")}
        editBtnClick={() => navigate(`/help-category/${helpCategoryId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(helpCategory!.data)
        })}
      />
      <Loader isLoading={helpCategoryLoading}>
        <Form>
          <Grid data-testid="helpCategory-add-form" container spacing={3}>
            <Grid data-testid="helpCategory-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!toDelete}
        title={
          !!helpCategory?.data._count?.faqs ||
          !!helpCategory?.data._count?.helpArticles
            ? "Cannot Delete Help Category"
            : "Delete Help Category?"
        }
        body={
          !!helpCategory?.data._count?.faqs ||
          !!helpCategory?.data._count?.helpArticles
            ? `This help category cannot be deleted because one or more help articles or FAQs are currently associated with it. Please remove any associated help articles or FAQs before attempting to delete this help category.`
            : `Are you sure you want to delete ${toDelete?.name}?`
        }
        close={() => setToDelete(null)}
        {...(!!helpCategory?.data._count?.faqs ||
        !!helpCategory?.data._count?.helpArticles
          ? {
              cancelBtnText: "Okay"
            }
          : {
              onConfirm: onConfirmDelete
            })}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
