import React, { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { FormSelect } from "@components/FormSelect";
import { getQaMetric } from "@services/Network";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { TestcaseBreakdown } from "../TestcaseBreakdown";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import {
  AdminMetricQaGet200FeatureBreakdownItem,
  useAdminReleaseAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

const columns = [
  {
    title: "TC Not Tested",
    color: "#E5E5E5"
  },
  {
    title: "TC Passed",
    color: "#1ABC9C"
  },
  {
    title: "TC Failed",
    color: "#E82C2C"
  },
  {
    title: "TC Onhold",
    color: "#FF710A"
  },
  {
    title: "TC Blocked",
    color: "#F48D8D"
  }
];

export const QATestingReleaseInsights = () => {
  const [releaseId, setReleaseId] = useState<string | undefined>(undefined);
  const [testerId, setTesterId] = useState<string | undefined>("");
  const [platfrom, setPlatform] = useState<string | undefined>("MOB");
  const [featureFilter, setFeatureFilter] = useState<string>("");
  const [featureBreakdown, setFeatureBreakdown] = useState({});
  const [isFeatureBreakdownExpanded, setIsFeatureBreakdownExpanded] =
    useState<boolean>(false);
  const [showFailedBreakdown, setShowFailedBreakdown] =
    useState<boolean>(false);
  const [showBlockedBreakdown, setShowBlockedBreakdown] =
    useState<boolean>(false);
  const [showHoldBreakdown, setShowHoldBreakdown] = useState<boolean>(false);

  const { data: releases, isLoading: isLoading } = useAdminReleaseAllGet();
  const releasesOptions = useMemo(
    () =>
      (releases?.data.releases &&
        releases?.data?.releases.map((release) => ({
          label: release.name!,
          value: release.releaseId
        }))) ||
      [],
    [releases]
  );

  const testerOptions = useMemo(() => {
    const testersFromRelease =
      releases?.data.releases &&
      releases?.data?.releases
        ?.find((r) => r.releaseId === releaseId)
        ?.testers.map((tester) => ({
          label: `${tester?.person?.firstName} ${tester?.person?.lastName}`,
          value: tester?.person?.userId
        }));

    const options = testersFromRelease
      ? [{ label: "All Testers", value: "" }, ...testersFromRelease]
      : [];

    return options;
  }, [releaseId]);
  const handleInsightBoxClick = (type: "FAILED" | "BLOCKED" | "HOLD") => {
    if (type === "FAILED") {
      setShowFailedBreakdown(!showFailedBreakdown);
    } else if (type === "BLOCKED") {
      setShowBlockedBreakdown(!showBlockedBreakdown);
    } else if (type === "HOLD") {
      setShowHoldBreakdown(!showHoldBreakdown);
    }
  };
  const platformOptions: { label: string; value: string }[] = useMemo(() => {
    if (releases?.data.releases && releaseId) {
      const release = releases.data.releases.find(
        (r) => r.releaseId === releaseId
      );
      if (release) {
        const releasePlatforms = Array.from(new Set(release.platforms));
        if (
          releasePlatforms.includes("AND") ||
          releasePlatforms.includes("IOS")
        ) {
          releasePlatforms.push("MOB");
        }
        const platforms = releasePlatforms.map((platform) => ({
          label: platform,
          value: platform
        }));
        platforms.sort((a, b) => {
          const order = ["MOB", "IOS", "AND", "WEB"];
          return order.indexOf(a.value) - order.indexOf(b.value);
        });
        return platforms;
      }
    }
    return [];
  }, [releaseId]);

  useEffect(() => {
    if (!releaseId) setReleaseId(releasesOptions[0]?.value);
    if (platformOptions.length == 1) setPlatform(platformOptions[0]?.value);
    if (!platformOptions.some((option) => option.value === "MOB"))
      setPlatform(platformOptions[0]?.value);
    if (platformOptions.some((option) => option.value === "MOB"))
      setPlatform("MOB");
  }, [platformOptions, releasesOptions]);

  return (
    <Loader isLoading={isLoading}>
      <Grid container paddingLeft="55px" marginTop="16px" columnSpacing={2}>
        <Grid item xs={1.5}>
          <FormSelect
            isReleaseSelect={true}
            name="releaseFilter"
            onChange={(e) => {
              setReleaseId(e.target.value);
            }}
            options={releasesOptions}
          />
        </Grid>
        <Grid item xs={2}>
          <FormSelect
            name="testerFilter"
            options={testerOptions}
            onChange={(e) => {
              setTesterId(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1.5}>
          <FormSelect
            name="platformFilter"
            options={platformOptions}
            onChange={(e) => {
              setPlatform(e.target.value);
            }}
          />
        </Grid>
        <Grid item paddingRight="40px" xs={7}>
          <FilterTreeSearchComponent
            onFilterChange={(featureId) => setFeatureFilter(featureId)}
            onClearFilter={() => setFeatureFilter("")}
            release={releaseId}
          />
        </Grid>
      </Grid>
      <div style={{ paddingLeft: "15px" }}>
        <Grid item marginLeft="25px" padding="15px" marginRight="25px">
          <InsightSummaryBox
            title="QA & Testing Insights (Releases)"
            description="Quick Insights related to SportsGravy Product Releases"
            isLoading={true}
            releaseId={releaseId}
            testerId={testerId}
            platform={platfrom}
            featureId={featureFilter}
            useGet={getQaMetric}
            setFeatureBreakDown={setFeatureBreakdown}
            isQaInsights={true}
            handleInsightClick={handleInsightBoxClick}
          />
        </Grid>

        <TestcaseBreakdown
          type="FAILED"
          releaseId={releaseId as string}
          platform={platfrom as "MOB" | "AND" | "IOS" | "WEB"}
          isDefualtExpanded={showFailedBreakdown}
        />
        <TestcaseBreakdown
          type="BLOCKED"
          releaseId={releaseId as string}
          platform={platfrom as "MOB" | "AND" | "IOS" | "WEB"}
          isDefualtExpanded={showBlockedBreakdown}
        />
        <TestcaseBreakdown
          type="HOLD"
          releaseId={releaseId as string}
          platform={platfrom as "MOB" | "AND" | "IOS" | "WEB"}
          isDefualtExpanded={showHoldBreakdown}
        />

        <Grid container direction="column" marginTop="32px" marginBottom="25px">
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              alignItems: "center"
            }}
          >
            <IconButton
              onClick={() => {
                setIsFeatureBreakdownExpanded(!isFeatureBreakdownExpanded);
              }}
            >
              {isFeatureBreakdownExpanded ? (
                <IndeterminateCheckBox color="primary" />
              ) : (
                <AddBox color="primary" />
              )}
            </IconButton>
            <Typography style={{ fontWeight: 700, fontSize: "16px" }}>
              Features & Test Cases Breakdown
            </Typography>
          </div>
          <HeaderUnderLine marginLeft="20px" />
          {isFeatureBreakdownExpanded && (
            <CustomColumnGraph
              columns={columns}
              values={featureBreakdown}
              onLabelClick={(value) => {
                const feature =
                  value as AdminMetricQaGet200FeatureBreakdownItem;
                window.open(
                  `/qa-features/${feature.featureId}?platform=${platfrom === "MOB" ? "IOS" : platfrom}&releaseId=${releaseId}`,
                  "_blank"
                );
              }}
            />
          )}
        </Grid>
      </div>
    </Loader>
  );
};
