import { Autocomplete } from "@react-google-maps/api";
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  Uncertain,
  UncertainCompatible
} from "@silevis/reactgrid";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .pac-container {
    width: 300px !important;
    font-size: 14px;
    z-index: 10000;
    margin-left: -5px !important;
  }
`;

interface GoogleAutocompleteCell extends Cell {
  type: "google-autocomplete";
  text: string;
  rowId: number;
  defaultAddress: string;
  placeFound?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;

export class GoogleAutocompleteCellTemplate implements CellTemplate {
  getCompatibleCell(
    uncertainCell: Uncertain<GoogleAutocompleteCell>
  ): Compatible<GoogleAutocompleteCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const rowId = getCellProperty(uncertainCell, "rowId", "number");
    const defaultAddress = getCellProperty(
      uncertainCell,
      "defaultAddress",
      "string"
    );

    const placeFound = getCellProperty(uncertainCell, "placeFound", "boolean");
    return {
      ...uncertainCell,
      text,
      rowId,
      defaultAddress,
      value: text,
      placeFound
    };
  }

  //For some reason this only works when the unused variables are left in
  handleKeyDown(
    cell: Compatible<GoogleAutocompleteCell>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    keyCode: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ctrl: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shift: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alt: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    key?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    capsLock?: boolean
  ): { cell: Compatible<GoogleAutocompleteCell>; enableEditMode: boolean } {
    return { cell, enableEditMode: true };
  }
  update(
    cell: Compatible<GoogleAutocompleteCell>,
    cellToMerge: UncertainCompatible<GoogleAutocompleteCell>
  ): Compatible<GoogleAutocompleteCell> {
    return this.getCompatibleCell({ ...cell, ...cellToMerge });
  }

  render(
    cell: Compatible<GoogleAutocompleteCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<GoogleAutocompleteCell>,
      commit: boolean
    ) => void
  ) {
    const hasRouteOrStreetNumber = (addressComponents) => {
      return addressComponents.some(
        (component) =>
          component.types.includes("route") ||
          component.types.includes("street_number")
      );
    };

    const onPlaceChanged = () => {
      if (autocompleteService.current) {
        const place = autocompleteService.current.getPlace();

        if (place && place.formatted_address) {
          const placeId = place.place_id || "";
          const addressComponents = place.address_components;
          const validAddress = hasRouteOrStreetNumber(addressComponents);
          if (!validAddress) {
            enqueueSnackbar(
              "Invalid address entered. Needs to be a specific place.",
              { variant: "error" }
            );
            onCellChanged(
              {
                ...cell,
                text: "Invalid Address",
                value: "Invalid Address",
                placeFound: false
              },
              true
            );
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (typeof (window as any).onPlaceSelected === "function") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).onPlaceSelected(cell.rowId, placeId);
          }
        } else {
          console.warn("onPlaceChanged triggered but no valid place selected.");
        }
      } else {
        console.error("Autocomplete instance not available.");
      }
    };

    const validateAddress = (inputValue: string) => {
      if (inputValue === "") {
        if (autocompleteService.current) {
          const service = new google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            { input: cell.defaultAddress, types: ["geocode"] },
            (predictions, status) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                predictions &&
                predictions.length > 0
              ) {
                const placeId = predictions[0].place_id;
                const placesService = new google.maps.places.PlacesService(
                  document.createElement("div")
                );

                placesService.getDetails({ placeId }, (place, status) => {
                  if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place
                  ) {
                    onCellChanged(
                      {
                        ...cell,
                        text: place.formatted_address,
                        value: place.formatted_address,
                        placeFound: true
                      },
                      true
                    );

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (typeof (window as any).onPlaceSelected === "function") {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (window as any).onPlaceSelected(
                        cell.rowId,
                        place.place_id
                      );
                    }
                  } else {
                    console.warn("Could not retrieve place details.");
                  }
                });
              } else {
                console.warn("No predictions found for default address.");
              }
            }
          );
        } else {
          console.error("Google Autocomplete service is not available.");
        }
        return;
      }

      if (!cell.placeFound) {
        enqueueSnackbar(
          "Invalid address entered. Please select a valid location.",
          { variant: "error" }
        );

        onCellChanged(
          {
            ...cell,
            text: "Invalid Address",
            value: "Invalid Address",
            placeFound: false
          },
          true
        );
      } else {
        onCellChanged(
          { ...cell, text: inputValue, value: inputValue, placeFound: true },
          true
        );
      }
    };

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
      validateAddress(event.target.value);
      if (event.target.value === "") {
        onCellChanged(
          { ...cell, text: event.target.value, value: event.target.value },
          true
        );
      }
    };

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        validateAddress(e.currentTarget.value.trim());
      } else if (e.key === "Backspace" || e.key === "Delete") {
        e.stopPropagation();
      }
    };

    const style: React.CSSProperties = {
      width: "100%",
      height: "100%",
      whiteSpace: "pre-wrap",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      display: "flex",
      alignItems: "center",
      padding: "4px",
      boxSizing: "border-box"
    };

    if (isInEditMode) {
      return (
        <>
          <GlobalStyles />
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteService.current = autocomplete;
              autocomplete.addListener("place_changed", onPlaceChanged);
            }}
          >
            <input
              type="text"
              value={cell.text}
              ref={(input) => input && input.focus()}
              onChange={(event) => {
                onCellChanged({ ...cell, text: event.target.value }, false);
              }}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDownHandler}
              style={{
                width: "100%",
                height: "100%",
                fontSize: "14px",
                padding: "4px",
                border: "none",
                outline: "none",
                background: "white",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            />
          </Autocomplete>
        </>
      );
    }

    return <div style={style}>{cell.text || ""}</div>;
  }
}
