/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountContactList } from "@pages/crm/call-module/AccountContactList";
import { createContext, Fragment, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Draggable from "react-draggable";
import { QuickAddAccount } from "@pages/crm/accounts/components/QuickAddAccount";
import { PastCall } from "@pages/crm/call-module/PastCall";
import { SendEmail } from "@pages/crm/email-module/SendEmail";
import { Call as TwilioCall, Device } from "@twilio/voice-sdk";
import { Call } from "@pages/crm/call-module/Call";
import {
  adminTwilioCallGet,
  adminTwilioTokenGet,
  ModelAccount,
  ModelActivityRelatesTo,
  ModelContact,
  ModelMedia
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ContactAccountList } from "@pages/crm/call-module/ContactAccountList";
import { updateCRMActivity } from "@services/Network";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { MergeCall } from "@pages/crm/call-module/MergeCall";
import { participant } from "@pages/crm/components/SearchParticipants";
import Sentry from "@services/Sentry";

export interface AccountCallerDetails {
  accountId?: string;
  opportunityId?: string;
  leadId?: string;
  activityId?: string;
  contactId?: string;
  accountName?: string;
  location?: string;
  phone?: string;
  email?: string;
  isConnected?: boolean;
  callDirection?: "inbound" | "outbound";
  phoneType?: "WORK" | "PERSONAL";
  relatesTo?: ModelActivityRelatesTo;
  callId?: string;
  showContactDetails?: boolean;
  call?: {
    participants?: {
      name?: string;
      role?: string;
    }[];
    to?: string;
    url?: string;
  };
  contact?: {
    contactName?: string;
    contactPhone?: string;
    isPrimary?: boolean;
  };
}

export const CallEmailTemplateContext = createContext<{
  account: ModelAccount | undefined;
  callAccepted: boolean;
  callStatus: "CONNECTING" | "ACCEPTED" | "DISCONNECTED" | undefined;
  callInstance: TwilioCall | undefined;
  setCallInstance: (callInstance: TwilioCall | undefined) => void;
  setAccount: (selectedAccount: ModelAccount | undefined) => void;
  setContact: (selectedContact: ModelContact | undefined) => void;
  setAddNewContact: (newContact: any | undefined) => void;
  setCallAccepted: (callAccepted: boolean) => void;
  setPastCall: (pastCall: AccountCallerDetails | undefined) => void;
  setEmailTo: (emailTo: {
    to: string | undefined;
    name: string | undefined;
    accountId: string | undefined;
    relatesTo: ModelActivityRelatesTo;
    contactId?: string;
    leadId?: string;
    opportunityId?: string;
    orderId?: string;
    accountName?: string;
    autoSuggestOptions?: participant[];
    opportunityQuoteId?: string;
    files?: { id: string; file: File | ModelMedia }[];
  }) => void;
  connectToCall: (number: string, activityId?: string) => void;
  disconnectCall: (resetStats?: boolean) => void;
  setCallerDetails: (callerDetails: AccountCallerDetails | undefined) => void;
  setMergeCall: (details: AccountCallerDetails) => void;
  setCallStatus: (
    status: "CONNECTING" | "ACCEPTED" | "DISCONNECTED" | undefined
  ) => void;
  emailTo:
    | {
        to: string | undefined;
        name: string | undefined;
        accountId: string | undefined;
        relatesTo: ModelActivityRelatesTo;
        contactId?: string;
        leadId?: string;
        opportunityId?: string;
        orderId?: string;
        accountName?: string;
        autoSuggestOptions?: participant[];
        opportunityQuoteId?: string;
        files?: { id: string; file: File | ModelMedia }[];
      }
    | undefined;
}>({
  account: undefined,
  callAccepted: false,
  callStatus: undefined,
  callInstance: undefined,
  setCallInstance: () => {},
  setAccount: () => {},
  setContact: () => {},
  setCallAccepted: () => {},
  setAddNewContact: () => {},
  setPastCall: () => {},
  setEmailTo: () => {},
  connectToCall: () => {},
  disconnectCall: () => {},
  setCallerDetails: () => {},
  setMergeCall: () => {},
  setCallStatus: () => {},
  emailTo: undefined
});

export const CallEmailTemplate = () => {
  const user = useRecoilValue(profileAtom);
  const orgId = useRecoilValue(organizationAtom);
  const deviceRef = useRef<Device | null>(null);
  const updateCallMutation = updateCRMActivity();
  dayjs.extend(timezone);
  const [account, setAccount] = useState<ModelAccount | undefined>(undefined);
  const [contact, setContact] = useState<ModelContact | undefined>(undefined);
  const [callerDetails, setCallerDetails] = useState<
    AccountCallerDetails | undefined
  >(undefined);
  const [callAccepted, setCallAccepted] = useState(false);
  const [addNewContact, setAddNewContact] = useState<any | undefined>(
    undefined
  );
  const [pastCall, setPastCall] = useState<AccountCallerDetails | undefined>(
    undefined
  );
  const [mergeCall, setMergeCall] = useState<AccountCallerDetails | undefined>(
    undefined
  );
  const [emailTo, setEmailTo] = useState<
    | {
        to: string | undefined;
        name: string | undefined;
        accountId: string | undefined;
        relatesTo: ModelActivityRelatesTo;
        contactId?: string;
        leadId?: string;
        opportunityId?: string;
        accountName?: string;
        autoSuggestOptions?: participant[];
        opportunityQuoteId?: string;
        files?: { id: string; file: File | ModelMedia }[];
      }
    | undefined
  >(undefined);
  const [callStatus, setCallStatus] = useState<
    "CONNECTING" | "ACCEPTED" | "DISCONNECTED" | undefined
  >(undefined);
  const [callInstance, setCallInstance] = useState<TwilioCall | undefined>(
    undefined
  );
  useEffect(() => {
    const initializeDevice = async () => {
      const generateToken = async () => {
        return (await adminTwilioTokenGet()).data.token as string;
      };
      if (!orgId) {
        const token = await generateToken();

        if (!deviceRef.current) {
          deviceRef.current = new Device(token, {
            allowIncomingWhileBusy: true,
            maxCallSignalingTimeoutMs: 10000,
            closeProtection: true,
            enableImprovedSignalingErrorPrecision: true,
            tokenRefreshMs: 60000
          });

          deviceRef.current.register();

          deviceRef.current.on("registering", () => {
            console.log("Twilio Device registering");
          });

          deviceRef.current.on("registered", () =>
            console.log("Twilio Device registered")
          );

          deviceRef.current.on("unregistered", () =>
            console.log("Twilio Device unregistered")
          );

          deviceRef.current.on("tokenWillExpire", async () => {
            const newToken = await generateToken();
            deviceRef.current?.updateToken(newToken);
          });

          deviceRef.current.on("error", (twilioError) => {
            Sentry.captureException(twilioError);
          });

          deviceRef.current.on("incoming", async (call: TwilioCall) => {
            setCallStatus(undefined);
            const incomingCall = await adminTwilioCallGet(
              call.parameters.CallSid
            );

            if (!deviceRef.current?.isBusy) {
              setCallInstance(call);
              setCallerDetails({
                callDirection: "inbound",
                callId: call.parameters.CallSid,
                phone: incomingCall.data.caller,
                isConnected: true,
                contact: {
                  contactPhone: call.parameters.To,
                  isPrimary: false
                }
              });
              if (call) {
                setCallInstance(call);
                call.on("ringing", () => {
                  setCallStatus("CONNECTING");
                });
                call.on("cancel", () => {
                  setCallerDetails(undefined);
                  setCallStatus("DISCONNECTED");
                });
                call.on("disconnect", () => {
                  setCallStatus("DISCONNECTED");
                });
                call.on("accept", () => {
                  setCallStatus("ACCEPTED");
                });
              }
            }
          });
        }
      }
    };

    initializeDevice();

    return () => {
      deviceRef.current?.disconnectAll();
      deviceRef.current?.destroy();
    };
  }, []);

  const requestMicrophoneAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (error) {
      alert(
        "Microphone access is required to make a call. Please enable it in your browser settings and try again."
      );
      return false;
    }
  };

  const connectToCall = async (number: string, activityId?: string) => {
    setCallStatus(undefined);

    const micAccessGranted = await requestMicrophoneAccess();
    if (!micAccessGranted) {
      return;
    }

    if (deviceRef.current) {
      const params = {
        To: number,
        From: user?.sportsgravyUser?.sgNumber as string
      };
      const call = await deviceRef.current.connect({ params });
      setTimeout(() => {
        if (activityId && call.parameters.CallSid) {
          updateCallMutation.mutate({
            activityId: activityId,
            data: {
              callId: call.parameters.CallSid,
              date: new Date(),
              timezone: dayjs.tz.guess(),
              relatesTo: "ACCOUNT",
              type: "CALL"
            }
          });
        }
      }, 2000);

      setCallInstance(call);
      call.on("ringing", () => {
        setCallStatus("CONNECTING");
      });
      call.on("disconnect", () => {
        setCallStatus("DISCONNECTED");
      });
      call.on("accept", () => {
        setCallStatus("ACCEPTED");
      });
      call.on("error", (error) => {
        Sentry.captureException(error);
      });
    }
  };

  const mergeToCall = async (details: AccountCallerDetails) => {
    setCallStatus(undefined);
    if (deviceRef.current) {
      setMergeCall(details);
      const params = {
        RoomId: details.callId as string,
        isCoaching: true
      };
      const call = await deviceRef.current.connect({ params });
      call.mute(true);

      setCallInstance(call);
      call.on("ringing", () => {
        setCallStatus("CONNECTING");
      });
      call.on("disconnect", () => {
        setCallStatus("DISCONNECTED");
      });
      call.on("accept", () => {
        setCallStatus("ACCEPTED");
      });
      call.on("error", (error) => {
        Sentry.captureException(error);
      });
    }
  };

  const disconnectCall = (resetStatus?: boolean) => {
    if (deviceRef.current) {
      deviceRef.current.disconnectAll();
    }
    setMergeCall(undefined);
    if (resetStatus) {
      setCallStatus(undefined);
    }
  };

  const updateContacts = (selectedAccount: any | undefined) => {
    setAccount(selectedAccount);
  };

  return (
    <CallEmailTemplateContext.Provider
      value={{
        account,
        callAccepted,
        setAccount: updateContacts,
        setContact,
        setCallAccepted,
        setAddNewContact,
        setPastCall,
        setEmailTo,
        connectToCall: connectToCall,
        disconnectCall: disconnectCall,
        setCallerDetails,
        callStatus,
        callInstance,
        setMergeCall: mergeToCall,
        setCallStatus,
        setCallInstance,
        emailTo
      }}
    >
      {!orgId && (
        <Fragment>
          <Draggable
            cancel="input[type='range'], .quill"
            defaultPosition={{ x: 500, y: 0 }}
            bounds={{
              left: -window.innerWidth + 600,
              top: 0,
              right: 900,
              bottom: -1000
            }}
          >
            <div
              style={{
                position: "fixed",
                bottom: 0,
                right: "500px",
                zIndex: 99999,
                cursor: "move"
              }}
            >
              {account && <AccountContactList account={account} />}
              {contact && <ContactAccountList contact={contact} />}
              {callerDetails && <Call account={callerDetails} />}
              {pastCall && <PastCall account={pastCall} />}
              {mergeCall && <MergeCall account={mergeCall} />}
              {emailTo && <SendEmail sendTo={emailTo} />}
            </div>
          </Draggable>
          {addNewContact && (
            <QuickAddAccount
              account={addNewContact}
              onClose={() => setAddNewContact(undefined)}
              onSave={(resp: ModelAccount, values) => {
                setCallerDetails({
                  ...callerDetails,
                  accountName: resp.name,
                  contactId: resp.contacts?.[0].contactId,
                  location: resp.officeAddress,
                  accountId: resp.accountId,
                  isConnected: true,
                  phone: callerDetails?.contact?.contactPhone,
                  ...callerDetails?.call,
                  contact: {
                    ...callerDetails?.contact,
                    contactName:
                      values.contact?.firstName + " " + values.contact?.lastName
                  }
                });
                setAddNewContact(undefined);
              }}
            />
          )}
        </Fragment>
      )}
      <Outlet />
    </CallEmailTemplateContext.Provider>
  );
};
