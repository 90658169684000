/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from "@files-ui/react";
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  InputProps,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { styled, SxProps } from "@mui/material/styles";
import { ModelCountry } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { omitProperties } from "@utils/objectFunctions";
import { formatPhone } from "@utils/phoneFormatters";
import Compressor from "compressorjs";
import parsePhoneNumberFromString from "libphonenumber-js";
import { MuiFileInput } from "mui-file-input";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  useController
} from "react-hook-form";
import InfoIcon from "@assets/icons/info.svg";
import { ToolTip } from "./ToolTip";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledPhoneNumberField = styled(TextField)`
  input {
    padding-left: 10px;
  }
  .country-code {
    width: 50px;
    input {
      padding: 0px !important;
    }
    fieldset {
      border: none;
      border-radius: 0px;
      border-right: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

const StyledFileInput = styled(MuiFileInput)(() => ({
  label: {
    width: "100%"
  },
  input: {
    padding: 0
  },
  "label span": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block"
  },
  "& .MuiInputAdornment-positionEnd": {
    flexGrow: 1,
    justifyContent: "end"
  },
  "& .MuiInputBase-root": {
    padding: "16.5px"
  }
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FormInput = (props: {
  name: string;
  control: Control<any, any>;
  country?: ModelCountry | undefined;
  rules: any | undefined;
  label: string;
  type: string | undefined;
  required: boolean | undefined;
  onChange?: (
    event: ChangeEvent | File | File[] | null,
    field?: ControllerRenderProps<any, string>,
    countryCode?: string
  ) => void;
  onBlur?: (e) => void;
  InputProps?: InputProps | any;
  TextProps?: TextFieldProps;
  disabled?: boolean;
  rows?: number;
  multiline?: boolean;
  capitalize?: boolean;
  capitalizeWords?: boolean;
  sideMessage?: string;
  enteredValue?: string;
  placeHolder?: string;
  triggerCountryCodeChange?: boolean;
  tooltip?: string;
  id?: string;
  autoFocus?: boolean;
  min?: number;
  allowNegativeNumber?: boolean;
  sx?: SxProps;
}) => {
  const { field } = useController({ name: props.name, control: props.control });
  const [countryCode, setCountryCode] = useState<string>("+1");
  useEffect(() => {
    if (props.enteredValue && props.enteredValue.length > 0)
      field.onChange(props.enteredValue);
  }, [props.enteredValue]);

  useEffect(() => {
    if (props.country) {
      if (field.value && field.value.length > 0) {
        let value = field.value?.replace(countryCode, "");
        value = `+${props.country?.dialCode}${value}`;
        field.onChange(value);
      }
      setCountryCode("+" + props.country?.dialCode);
    }
  }, [props.country]);

  useEffect(() => {
    if (field.value && props.type == "tel" && field.value.length > 0) {
      const parsedNumber = parsePhoneNumberFromString(field.value);
      if (parsedNumber?.countryCallingCode) {
        setCountryCode(`+${parsedNumber.countryCallingCode}`);
      }
    }
  }, [field.value]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ fieldState, field }) => (
        <FormInputContainer className="input" sx={props.sx}>
          {props.sideMessage ? (
            <Grid item container direction="row" spacing="24px">
              <Grid item>
                <StyledFormLabel required={props.required}>
                  <Typography display="inline" variant="formLabel">
                    {props.label}
                  </Typography>
                </StyledFormLabel>
              </Grid>
              <Grid item>
                <Typography>{props.sideMessage}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              style={{
                display: "flex"
              }}
              className="label"
            >
              <StyledFormLabel required={props.required}>
                <Typography display="inline" variant="formLabel">
                  {props.label}
                </Typography>
              </StyledFormLabel>
              {props.tooltip && (
                <ToolTip title={props.tooltip}>
                  <img
                    src={InfoIcon}
                    style={{ paddingLeft: "8px", height: "24px" }}
                  />
                </ToolTip>
              )}
            </Grid>
          )}
          {props.type === "file" ? (
            <StyledFileInput
              {...omitProperties(field, ["ref"])}
              placeholder=""
              error={!!fieldState.error}
              value={field.value || ""}
              onBlur={field.onBlur}
              helperText={fieldState.error?.message}
              inputProps={props.InputProps}
              disabled={props.disabled}
              // @ts-ignore
              onChange={(event) => {
                if (props.onChange) props.onChange(event, field);
              }}
              getInputText={(value) =>
                Array.isArray(value)
                  ? value.map((v) => v.name).join(", ")
                  : value instanceof File
                    ? value?.name
                    : "Click to Upload File"
              }
              {...(props.TextProps || {})}
            />
          ) : props.type === "avatar" ? (
            <>
              <Avatar
                emptyLabel={"No avatar selected"}
                changeLabel={"Change avatar"}
                onChange={async (file) => {
                  const compressedFile: File = await new Promise(
                    (resolve, reject) => {
                      new Compressor(file, {
                        mimeType: "image/png",
                        width: 200,
                        height: 200,
                        resize: "cover",
                        convertSize: Infinity,
                        success(file) {
                          return resolve(file as File);
                        },
                        error(err) {
                          return reject(err);
                        }
                      });
                    }
                  );

                  const dataUrl = await new Promise<string>(
                    (resolve, reject) => {
                      const reader = new FileReader();
                      reader.onload = () => resolve(reader.result as string);
                      reader.onerror = () => reject(reader.error);
                      reader.onabort = () => reject(new Error("Read aborted"));
                      reader.readAsDataURL(compressedFile);
                    }
                  );

                  field.onChange(dataUrl);
                  if (props.onChange) {
                    props.onChange(null, field);
                  }
                }}
                src={field.value || ""}
                {...{
                  readOnly: props.disabled
                }}
              />
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error?.message}
              </FormHelperText>
            </>
          ) : props.type === "tel" ? (
            <StyledPhoneNumberField
              disabled={props.disabled}
              {...(props.TextProps || {})}
              helperText={fieldState.error?.message}
              onBlur={(e) => {
                if (field.value && field.value.length > 0) {
                  field.onChange(
                    `${countryCode}${
                      field?.value?.replace(countryCode, "").replace(" ", "") ||
                      ""
                    }`
                  );
                }
                if (field.value && field.value.length === 0) {
                  field.onChange("");
                }
                field.onBlur();
                if (props.onBlur) props.onBlur(e);
              }}
              error={!!fieldState.error}
              variant="outlined"
              value={
                props.enteredValue
                  ? !props.enteredValue.includes(
                      parsePhoneNumberFromString(props.enteredValue)
                        ?.countryCallingCode || "+1"
                    )
                    ? formatPhone(`${countryCode}${props.enteredValue}`)
                    : props.disabled ||
                        props.enteredValue?.startsWith(countryCode)
                      ? formatPhone(props.enteredValue)
                      : props.enteredValue
                  : field.value &&
                      field.value.length > 0 &&
                      !field.value.includes(
                        parsePhoneNumberFromString(field.value)
                          ?.countryCallingCode
                      )
                    ? formatPhone(`${countryCode}${field.value}`)
                    : props.disabled || field?.value?.startsWith(countryCode)
                      ? formatPhone(field.value)
                      : field.value
              }
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                field.onChange(value);
                if (props.onChange) props.onChange(e, field, countryCode);
              }}
              InputProps={{
                ...(props.InputProps || {}),
                startAdornment: (
                  <TextField
                    className="country-code"
                    disabled={props.disabled || !!props.country}
                    inputProps={{
                      maxLength: 4
                    }}
                    value={
                      field.value &&
                      field.value.length > 0 &&
                      parsePhoneNumberFromString(field.value)
                        ?.countryCallingCode
                        ? `+${
                            parsePhoneNumberFromString(field.value)
                              ?.countryCallingCode
                          }`
                        : countryCode
                    }
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/[^0-9]/g, "");
                      if (!inputValue.startsWith("+")) {
                        inputValue = `+${inputValue.replace(/[^0-9]/g, "")}`;
                      }
                      field.onChange("");
                      setCountryCode(inputValue);
                      if (props.onChange && props.triggerCountryCodeChange)
                        props.onChange(null, field, inputValue);
                    }}
                  />
                )
              }}
            />
          ) : (
            <TextField
              id={props.id}
              placeholder={props.placeHolder || ""}
              data-testid={"FORM_INPUT_" + props.name}
              error={!!fieldState.error}
              onKeyDown={(e) => {
                if (!props.allowNegativeNumber) {
                  if (e.key === "-" || e.key === "+") {
                    e.preventDefault();
                  }
                }
              }}
              type={props.type}
              rows={props.rows}
              value={props.enteredValue || field.value?.toString() || ""}
              onChange={(event) => {
                if (props.capitalize)
                  event.target.value = event.target.value.toUpperCase();
                if (props.capitalizeWords)
                  event.target.value = capitalizeEveryWord(event.target.value);
                field.onChange(event);
                if (props.onChange) props.onChange(event, field);
              }}
              onBlur={(e) => {
                field.onBlur();
                if (props.onBlur) props.onBlur(e);
              }}
              helperText={fieldState.error?.message}
              InputProps={props.InputProps}
              disabled={props.disabled}
              multiline={props.multiline}
              autoFocus={props.autoFocus}
              {...(props.TextProps || {})}
              {...(props.type === "number"
                ? { inputProps: { min: props.min || 1 } }
                : {})}
            />
          )}
        </FormInputContainer>
      )}
    />
  );
};

FormInput.defaultProps = {
  rules: undefined,
  required: false,
  disabled: false,
  multiline: false,
  allowNegativeNumber: true
};
