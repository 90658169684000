import { Button } from "@components/Button";
import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useAdminCrmOnboardingEventIdGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { OnboardingDashboard } from "./OnboardingDashboard";
import {
  CRM_ACCOUNT_CATEGORIES,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import { useState } from "react";

export const OnboardingReviewView = () => {
  const { eventId } = useParams();
  const [refreshKey, setRefreshKey] = useState(1);
  const { data: eventDetails, isLoading: isLoading } =
    useAdminCrmOnboardingEventIdGet(eventId!, {
      query: {
        queryKey: [refreshKey]
      }
    });
  const eventType = eventDetails?.data?.type || "REVIEW";
  const navigate = useNavigate();
  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Toolbar
          title={`${
            eventType === "ONBOARDING" ? "Onboarding" : "Review"
          } Dashboard`}
          additionalBtns={[
            {
              button: !eventDetails?.data?.account?.organization ? (
                <Button
                  variant="admin-secondary"
                  onClick={() => {
                    navigate(
                      `/organizations/create?accountId=${eventDetails?.data?.accountId}`
                    );
                  }}
                  isLoading={false}
                >
                  Add Organization
                </Button>
              ) : (
                <Button
                  variant="admin-secondary"
                  onClick={() => {
                    navigate(
                      `/organizations/${eventDetails?.data?.account?.organization?.organizationId}`
                    );
                  }}
                  isLoading={false}
                >
                  View Organization
                </Button>
              )
            },
            {
              button: (
                <Button
                  variant="admin-primary"
                  onClick={() => {
                    if (eventDetails?.data?.accountId)
                      navigate(
                        `/crm/accounts/${eventDetails?.data?.accountId}`
                      );
                  }}
                  isLoading={false}
                >
                  View Account
                </Button>
              )
            },
            {
              button: (
                <Button
                  variant="admin-primary"
                  onClick={() => {
                    if (eventDetails?.data?.orderId)
                      navigate(`/crm/orders/${eventDetails?.data?.orderId}`);
                  }}
                  isLoading={false}
                >
                  View Order
                </Button>
              )
            }
          ]}
          backBtnClick={() => window.history.back()}
        />
        <OnboardingDashboard
          details={{
            name: eventDetails?.data?.account?.name,
            type:
              CRM_ACCOUNT_CATEGORIES.find(
                (a) => a.value === eventDetails?.data?.account?.category
              )?.label || "",
            orgType:
              ORGANIZATION_TYPE_OPTIONS.find(
                (o) => o.value === eventDetails?.data?.account?.type
              )?.label || "",
            officeAddress: eventDetails?.data?.account?.officeAddress,
            billingAddress: eventDetails?.data?.account?.billingAddress,
            shippingAddress: eventDetails?.data?.account?.shippingAddress,
            phone: eventDetails?.data?.account?.phone,
            fax: eventDetails?.data?.account?.fax,
            email: eventDetails?.data?.account?.email,
            website: eventDetails?.data?.account?.website,
            parent: {
              name: eventDetails?.data?.account?.parent?.name || ""
            },
            accountId: eventDetails?.data?.accountId,
            contacts: eventDetails?.data?.account?.contacts || [],
            contactId: eventDetails?.data?.order?.contactId,
            activityId: eventDetails?.data?.activityId,
            meetingDetails: eventDetails?.data
          }}
          accountId={eventDetails?.data?.accountId || ""}
          onRefresh={() => {
            setRefreshKey(refreshKey + 1);
          }}
        />
      </Container>
    </Loader>
  );
};
