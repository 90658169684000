import { Loader } from "@components/crud/Loader";
import { FixIcon } from "@components/Icons";
import { RenderTableView } from "@components/RenderTableView";
import { Chip, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import {
  ModelOrganizationUploadFailedRecord,
  useAdminLevelGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { formatDateForDisplay } from "@utils/formatDate";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const convertCamelCaseToSentence = (str) =>
  str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

export const FailedRowsView = (props: {
  failedRows: ModelOrganizationUploadFailedRecord[];
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const { data: levels, isLoading: isLevelLoading } = useAdminLevelGet({
    organizationId: orgId!,
    pageSize: 1000
  });
  const levelOptions = useMemo(() => {
    if (levels?.data.levels && levels?.data?.levels.length > 0) {
      return levels.data.levels.map((level) => ({
        value: level.levelId as string,
        label: level.name as string
      }));
    }
    return [];
  }, [levels]);
  const navigate = useNavigate();
  const IMPORTED_ROWS_COLUMNS: GridColDef[] = [
    {
      headerName: "Status",
      field: "status",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: () => {
        return (
          <Chip
            style={{
              fontSize: "10px",
              padding: "4px 8px",
              marginLeft: "-10px"
            }}
            label="Error"
            sx={{ background: "#FECACA", color: "#991B1B" }}
          />
        );
      }
    },
    {
      headerName: "Status Description",
      field: "error",
      minWidth: 250,
      flex: 1,
      sortable: false,
      valueFormatter: (params) => {
        return params.value
          .split("; ")
          .map(convertCamelCaseToSentence)
          .join(", ");
      }
    },
    {
      headerName: "Athlete / Player First Name",
      field: "player.firstName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.firstName || ""
    },
    {
      headerName: "Athlete / Player Middle Name",
      field: "player.middleName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.middleName || ""
    },
    {
      headerName: "Athlete / Player Last Name",
      field: "player.lastName",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.lastName || ""
    },
    {
      headerName: "Athlete / Player Suffix",
      field: "player.suffix",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.suffix || ""
    },
    {
      headerName: "Athlete / Player Date of Birth",
      field: "player.birthedAt",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        params.row.record?.player?.birthedAt
          ? formatDateForDisplay(new Date(params.row.record?.player?.birthedAt))
          : ""
    },
    {
      headerName: "Athlete / Player Gender",
      field: "player.gender",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        capitalize(params.row?.record?.player?.gender) || ""
    },
    {
      headerName: "Athlete / Player Level",
      field: "player.level",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        levelOptions.find((l) => l.value == params.row?.record?.player?.level)
          ?.label || ""
    },
    {
      headerName: "Athlete / Player Phone",
      field: "player.phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        params.row.record?.player?.phone
          ? formatPhoneWithCountryCode(params.row.record?.player?.phone)
          : ""
    },
    {
      headerName: "Athlete / Player Email",
      field: "player.email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.email || ""
    },
    {
      headerName: "Address Line 1",
      field: "player.address1",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.address1 || ""
    },
    {
      headerName: "Address Line 2",
      field: "player.address2",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.address2 || ""
    },
    {
      headerName: "City",
      field: "city",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.city || ""
    },
    {
      headerName: "State",
      field: "state",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.state || ""
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.country || ""
    },
    {
      headerName: "Zip Code",
      field: "zipCode",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.player?.zip || ""
    },
    {
      headerName: "Parent / Guardian 1 First Name",
      field: "parent1FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent1?.firstName || ""
    },
    {
      headerName: "Parent / Guardian 1 Last Name",
      field: "parent1LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent1?.lastName || ""
    },
    {
      headerName: "Parent / Guardian 1 Mobile Phone",
      field: "parent1Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        params.row.record?.parent1?.phone
          ? formatPhoneWithCountryCode(params.row.record?.parent1?.phone)
          : ""
    },
    {
      headerName: "Parent / Guardian 1 Email",
      field: "parent1Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent1?.email || ""
    },
    {
      headerName: "Parent / Guardian 2 First Name",
      field: "parent2FirstName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent2?.firstName || ""
    },
    {
      headerName: "Parent / Guardian 2 Last Name",
      field: "parent2LastName",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent2?.lastName || ""
    },
    {
      headerName: "Parent / Guardian 2 Mobile Phone",
      field: "parent2Phone",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        params.row.record?.parent2?.phone
          ? formatPhoneWithCountryCode(params.row.record?.parent2?.phone)
          : ""
    },
    {
      headerName: "Parent / Guardian 2 Email",
      field: "parent2Email",
      minWidth: 300,
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row.record?.parent2?.email || ""
    }
  ];
  const onFix = (id) => {
    navigate(`edit/${id}`);
  };
  return (
    <Loader isLoading={isLevelLoading}>
      <RenderTableView
        title=""
        rows={props.failedRows}
        columns={IMPORTED_ROWS_COLUMNS}
        hideFilter
        getRowId={(row) => row?.recordId}
        searchable={false}
        hideToolbar={true}
        hideFooter={true}
        pinnedColumns={{ left: [] }}
        customActions={[
          (params) => (
            <IconButton onClick={() => onFix(params.row.recordId)}>
              <FixIcon />
            </IconButton>
          )
        ]}
      />
    </Loader>
  );
};
