import React, { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { FormSelect } from "@components/FormSelect";
import { getQaMetric } from "@services/Network";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import { useAdminBuildAllGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

const columns = [
  {
    title: "TC Not Tested",
    color: "#E5E5E5"
  },
  {
    title: "TC Passed",
    color: "#1ABC9C"
  },
  {
    title: "TC Failed",
    color: "#E82C2C"
  },
  {
    title: "TC Onhold",
    color: "#FF710A"
  },
  {
    title: "TC Blocked",
    color: "#F48D8D"
  }
];

export const QATestingBuildInsights = () => {
  const [buildId, setBuildId] = useState<string | undefined>(undefined);
  const [testerId, setTesterId] = useState<string | undefined>("");
  const [featureFilter, setFeatureFilter] = useState<string>("");
  const [featureBreakdown, setFeatureBreakdown] = useState({});
  const [isFeatureBreakdownExpanded, setIsFeatureBreakdownExpanded] =
    useState<boolean>(false);

  const { data: builds, isLoading: isLoading } = useAdminBuildAllGet();
  const buildsOptions = useMemo(
    () =>
      (builds?.data.builds &&
        builds?.data?.builds.map((build) => ({
          label: `${build.buildNumber} - ${build.platform}`,
          value: build.buildId,
          platform: build.platform
        }))) ||
      [],
    [builds]
  );

  const testerOptions = useMemo(() => {
    const testersFromBuild =
      builds?.data?.builds
        ?.find((r) => r.buildId === buildId)
        ?.testers?.map((tester) => ({
          label: `${tester?.person?.firstName ?? ""} ${
            tester?.person?.lastName ?? ""
          }`,
          value: tester?.person?.userId ?? ""
        })) || [];

    const options = testersFromBuild
      ? [{ label: "All Testers", value: "" }, ...testersFromBuild]
      : [];

    return options;
  }, [buildId]);

  useEffect(() => {
    if (!buildId) setBuildId(buildsOptions[0]?.value);
  }, [buildsOptions]);

  return (
    <Loader isLoading={isLoading}>
      <Grid container paddingLeft="55px" marginTop="16px" columnSpacing={2}>
        <Grid item xs={2}>
          <FormSelect
            isReleaseSelect={true}
            name="releaseFilter"
            onChange={(e) => {
              setBuildId(e.target.value);
            }}
            options={buildsOptions}
          />
        </Grid>
        <Grid item xs={2}>
          <FormSelect
            name="testerFilter"
            options={testerOptions}
            onChange={(e) => {
              setTesterId(e.target.value);
            }}
          />
        </Grid>

        <Grid item paddingRight="40px" xs={8}>
          <FilterTreeSearchComponent
            onFilterChange={(featureId) => setFeatureFilter(featureId)}
            onClearFilter={() => setFeatureFilter("")}
            release={buildId}
            type="BUILD"
          />
        </Grid>
      </Grid>
      <div style={{ paddingLeft: "15px" }}>
        <Grid item marginLeft="25px" padding="15px" marginRight="25px">
          <InsightSummaryBox
            title="QA & Testing Insights (Builds)"
            description="Quick Insights related to SportsGravy Product Builds"
            isLoading={true}
            buildId={buildId}
            testerId={testerId}
            platform={buildsOptions.find((b) => b.value === buildId)?.platform}
            featureId={featureFilter}
            useGet={getQaMetric}
            setFeatureBreakDown={setFeatureBreakdown}
            isQaInsights={true}
          />
        </Grid>

        <Grid container direction="column" marginTop="32px" marginBottom="25px">
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              alignItems: "center"
            }}
          >
            <IconButton
              onClick={() => {
                setIsFeatureBreakdownExpanded(!isFeatureBreakdownExpanded);
              }}
            >
              {isFeatureBreakdownExpanded ? (
                <IndeterminateCheckBox color="primary" />
              ) : (
                <AddBox color="primary" />
              )}
            </IconButton>
            <Typography style={{ fontWeight: 700, fontSize: "16px" }}>
              Features & Test Cases Breakdown
            </Typography>
          </div>
          <HeaderUnderLine marginLeft="20px" />
          {isFeatureBreakdownExpanded && (
            <CustomColumnGraph columns={columns} values={featureBreakdown} />
          )}
        </Grid>
      </div>
    </Loader>
  );
};
