import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { useForm } from "react-hook-form";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  useAdminBillingDetailGet,
  useAdminCrmOrderOrderIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import formatFullName from "@utils/formatFullName";
import { Form } from "@components/crud/Form";
import { OrderAgreements } from "./OrderAgreements";
import { OrderPaymentDetails } from "./OrderPaymentDetails";
import { OrderBillingHistory } from "./OrderBillingHistory";
import { FullName } from "@utils/types";
import { hasPermission } from "@services/Casbin";
import { ORDER_BILLING_OPTIONS } from "@utils/constants";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const OrderView = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const form = useForm({
    mode: "onTouched"
  });
  const { reset } = form;

  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
    }[]
  >([]);

  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.orders", "DELETE");
      const edit = await checkPermission("crm.orders", "EDIT");
      const view = await checkPermission("crm.orders", "VIEW");
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  const { data: orderData, isLoading: isLoading } = organizationId
    ? useAdminBillingDetailGet({
        organizationId: organizationId
      })
    : useAdminCrmOrderOrderIdGet(orderId!);
  useEffect(() => {
    if (orderData?.data) {
      const order = orderData.data;
      reset({
        submittedBy: formatFullName(order.submittedBy?.person as FullName),
        orderDate: new Date(order.date),
        product: order.product,
        orderType: order.type,
        noOfAthletes: order.initialNumberOfAthletes,
        costOfAthletesPeryear: `$ ${order.ratePerAthlete} Per Athlete, Per Registration`,
        amount: order.totalAmount,
        billingOption: order.billingType,
        startDate: order.revisedStartDate
          ? new Date(order.revisedStartDate)
          : new Date(order.originalStartDate),
        endDate: order.revisedEndDate
          ? new Date(order.revisedEndDate)
          : new Date(order.originalEndDate),
        notes: order.notes,
        account: {
          name: order.account?.name || "",
          parent: order.account?.parent?.name || "",
          category: order.account?.category,
          type: order.account?.type,
          officeAddress: order.account?.officeAddress,
          email: order.account?.email,
          website: order.account?.website,
          sportsOffered: order.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: order.account?.numberOfAthletes,
          aeOwner: order.account?.aeOwner?.person
            ? formatFullName(order.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: order.contact?.firstName,
          lastName: order.contact?.lastName,
          personalEmail: order.contact?.email,
          homeAddress: order.contact?.location,
          phoneType: "MOB",
          phone: order.contact?.phone,
          whatsappNumber: order.contact?.whatsappNumber
        }
      });
      setSelectedAccount(order.accountId || "");
    }
  }, [orderData]);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && orderData?.data) {
        try {
          const data =
            await getAdminCrmAccountAccountIdContact(selectedAccount);
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || ""
            }))
          );
          if (orderData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(orderData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, orderData]);

  const [tab, setTab] = useState(searchParams.get("tab") || "Order Details");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };
  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);
  const getTabs = () => {
    return [
      "Order Details",
      "Agreements & Contracts",
      "Payment Details",
      "Billing History"
    ];
  };

  return (
    <Container>
      <Toolbar
        title={
          organizationId ? "Billing & Subscription" : `View Order: ${orderId}`
        }
        backBtnClick={() => {
          navigate("/crm/orders");
        }}
        {...(!organizationId &&
          permissions.edit &&
          tab === "Order Details" && {
            editBtnClick: () => navigate(`/crm/orders/${orderId}/edit`)
          })}
        tabs={{
          tabs: getTabs(),
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Loader isLoading={isLoading}>
        {tab === "Order Details" && (
          <Form>
            <OrderDetailsForm
              form={form}
              disabled
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              contacts={contacts}
              billingOptions={ORDER_BILLING_OPTIONS}
              startDateRequirement={3}
              setContacts={setContacts}
              organizationId={organizationId}
              mode="VIEW"
            />
          </Form>
        )}
        {tab === "Agreements & Contracts" && (
          <OrderAgreements orderId={orderId!} organizationId={organizationId} />
        )}
        {tab === "Payment Details" && (
          <Form style={{ padding: 0 }}>
            <OrderPaymentDetails
              orderId={orderId || orderData?.data.orderId}
              organizationId={organizationId}
            />
          </Form>
        )}
        {tab === "Billing History" && (
          <OrderBillingHistory organizationId={organizationId} />
        )}
      </Loader>
    </Container>
  );
};
