import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { SearchInput } from "@components/SearchInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  ModelLevel,
  useAdminLevelLevelIdDelete,
  useAdminLevelLevelIdGet,
  useAdminSportGet,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { GENDERS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CountryList from "./CountryList";
import styled from "styled-components";
import { Typography } from "@mui/material";

const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const LevelView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelLevel | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });
  const [search, setSearch] = useState("");

  const { levelId } = useParams();
  const [organizationId] = useRecoilState(organizationAtom);

  const {
    data: level,
    isFetching: isLevelFetching,
    error: error
  } = useAdminLevelLevelIdGet(levelId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { control, reset } = useForm({
    mode: "onTouched"
  });
  const { data: countries, isLoading: isCountriesLoading } =
    useLookupCountryGet();

  const defaultValues = useMemo<{
    name: string;
    abbv: string;
    description?: string;
    sportId?: string[];
    genders?: string[];
    inheritedFrom?: string;
    countries: {
      countryId: string;
      countryName: string;
      selected: boolean;
    }[];
  }>(() => {
    if (!countries || !level) return { name: "", countries: [] };
    return {
      name: level?.data?.name,
      abbv: level?.data?.abbv,
      description: level?.data?.description,
      sportId: level?.data?.sports
        ?.filter((sport) =>
          sports?.data.some((dataSport) => dataSport.sportId === sport.sportId)
        )
        .map((sport) => sport.sportId),
      genders: level?.data?.genders,
      inheritedFrom:
        organizationId && level?.data?.isInherited
          ? level?.data?.inheritedFrom
            ? level?.data?.inheritedFrom.name
            : "SportsGravy"
          : undefined,
      countries:
        level.data?.countries &&
        level.data?.countries.length > 0 &&
        countries.data
          .filter((country) =>
            level.data?.countries.some((c) => c.countryId === country.countryId)
          )
          .reduce((acc, country) => {
            const levelCountry = level.data?.countries.find(
              (c) => c.countryId === country.countryId
            );
            acc[country.countryId!] = {
              countryId: country.countryId!,
              countryName: country.name!,
              selected: levelCountry ? true : false
            };
            return acc;
          }, {})
    };
  }, [countries, level, sports]);

  useEffect(
    () =>
      reset(defaultValues, {
        keepDirtyValues: true
      }),
    [level, sportOptions, countries]
  );
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminLevelLevelIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.levelId) return;
    try {
      await deleteAsync({ levelId: toDelete.levelId });
      enqueueSnackbar("Level deleted successfully", { variant: "success" });
      setToDelete(null);
      navigate("/levels");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete level.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  const isDeleteDisabled = (level) => {
    return (
      (level?._count?.organizations == 0 && level?._count?.users == 0) ||
      level?.isInherited
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("admin.levels", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearch = useDebounce(search, 100);

  const renderCountryList = useMemo(() => {
    return (
      <CountryList
        countryList={defaultValues.countries}
        control={control}
        search={debouncedSearch}
        disabled={true}
      />
    );
  }, [defaultValues.countries, debouncedSearch]);

  return (
    <Container>
      <Loader isLoading={isCountriesLoading}>
        <Toolbar
          title="View Level"
          backBtnClick={() => navigate("/levels")}
          editBtnClick={
            organizationId && level?.data?.isInherited
              ? undefined
              : () => navigate(`/levels/${levelId}/edit`)
          }
          {...(permissions.delete &&
            !isDeleteDisabled(level?.data) && {
              deleteBtnClick: () => setToDelete(level!.data)
            })}
        />
        <Form data-testid="level-view-form">
          <Loader isLoading={isLevelFetching || isSportLoading}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormInput
                  control={control}
                  name="name"
                  type="text"
                  label="Name"
                  required={true}
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormInput
                  control={control}
                  name="abbv"
                  type="text"
                  label="Abbreviation"
                  required={true}
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormMultiSelect
                  control={control}
                  name="sportId"
                  label="Sports"
                  value="sportId"
                  required={true}
                  options={sportOptions}
                  isLoading={isSportLoading}
                  disabled
                  rules={{
                    required: "Sport is required"
                  }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormMultiSelect
                  control={control}
                  name="genders"
                  value="gender"
                  label="Gender"
                  required={true}
                  options={GENDERS}
                  disabled={true}
                />
              </Grid>
              <Grid
                xs={12}
                md={organizationId && level?.data?.isInherited ? 6 : 12}
              >
                <FormInput
                  control={control}
                  name="description"
                  type="text"
                  label="Description"
                  required={true}
                  multiline={true}
                  disabled={true}
                />
              </Grid>
              {organizationId && level?.data?.isInherited && (
                <Grid xs={12} md={6} data-testid="level-sharedBy">
                  <FormInput
                    control={control}
                    name="inheritedFrom"
                    type="text"
                    label="Shared By"
                    disabled={true}
                  />
                </Grid>
              )}
              {!organizationId && defaultValues?.countries && (
                <>
                  <Grid xs={12}>
                    <StyledLabel>Countries</StyledLabel>
                    <HeaderUnderLine width="100%" />
                    <Box sx={{ marginTop: "8px" }}>
                      <SearchInput
                        placeholder="Search"
                        required={false}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  {renderCountryList}
                </>
              )}
            </Grid>
          </Loader>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!toDelete}
        title={
          !!level?.data.teams?.length || !!level?.data.trainingPrograms?.length
            ? "Cannot Delete Level"
            : "Delete Level?"
        }
        body={
          !!level?.data.teams?.length ||
          !!level?.data.trainingPrograms?.length ? (
            <div>
              <span>
                This level cannot be deleted because one or more teams or
                training programs are currently associated with it. Please
                remove any associated teams or training programs before
                attempting to delete this level.
              </span>
              {organizationId && (
                <>
                  <br />
                  <br />
                  {!!toDelete?.teams?.length && (
                    <>
                      <b>Teams</b>
                      <div
                        style={{
                          maxHeight: toDelete.trainingPrograms?.length
                            ? "150px"
                            : "350px",
                          overflowY: "auto"
                        }}
                      >
                        <ul style={{ marginLeft: "35px" }}>
                          {toDelete?.teams?.map((team) => (
                            <li key={team.teamId}>
                              <span
                                style={{
                                  color: "#007AFF",
                                  cursor: "pointer",
                                  wordWrap: "break-word"
                                }}
                                onClick={() =>
                                  window.open(`/teams/${team.teamId}`, "_blank")
                                }
                              >
                                {team.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <br />
                    </>
                  )}
                  {!!toDelete?.trainingPrograms?.length && (
                    <>
                      <b>Training Programs</b>
                      <div
                        style={{
                          maxHeight: toDelete.teams?.length ? "150px" : "350px",
                          overflowY: "auto"
                        }}
                      >
                        <ul style={{ marginLeft: "35px" }}>
                          {toDelete?.trainingPrograms?.map((tp) => (
                            <li key={tp.programId}>
                              <span
                                style={{
                                  color: "#007AFF",
                                  cursor: "pointer",
                                  wordWrap: "break-word"
                                }}
                                onClick={() =>
                                  window.open(
                                    `/training-programs/${tp.programId}`,
                                    "_blank"
                                  )
                                }
                              >
                                {tp.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            `Are you sure you want to delete ${toDelete?.name}?`
          )
        }
        close={() => setToDelete(null)}
        {...(!!level?.data.teams?.length ||
        !!level?.data.trainingPrograms?.length
          ? {
              cancelBtnText: "Okay"
            }
          : {
              onConfirm: onConfirmDelete
            })}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
