import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { hasPermission } from "@services/Casbin";
import {
  ModelPosition,
  adminPositionGet,
  useAdminPositionPositionIdDelete,
  useAdminPositionPositionIdGet,
  useSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const PositionView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelPosition | null>(null);
  const { positionId } = useParams();
  const [parentOptions, setParentOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [permissions, setPermissions] = useState({
    delete: false
  });
  const {
    data: position,
    isFetching: isPositionFetching,
    error: error
  } = useAdminPositionPositionIdGet(positionId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isFetching: isSportsFetching } = useSportGet({
    pageSize: 1000
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.sports?.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId as string
      })) || [],
    [sports]
  );

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminPositionPositionIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.positionId) return;
    try {
      await deleteAsync({ positionId: toDelete.positionId });
      enqueueSnackbar("Position deleted successfully", { variant: "success" });
      setToDelete(null);
      navigate("/positions");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete position.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  useEffect(() => {
    if (!position?.data?.parentId || !position?.data?.sportId) {
      return;
    }

    adminPositionGet({
      sportId: position?.data?.sportId,
      pageSize: 1000
    }).then((parentOptions) => {
      setParentOptions(
        parentOptions?.data?.positions?.map((position) => ({
          label: position.name as string,
          value: position.positionId as string
        })) || []
      );
    });
  }, [position]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("admin.positions", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);

  const { control, reset } = useForm();
  useEffect(
    () => reset(position?.data),
    [position, parentOptions, sportOptions]
  );

  return (
    <Container>
      <Toolbar
        title="View Position"
        backBtnClick={() => navigate("/positions")}
        editBtnClick={() => navigate(`/positions/${positionId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(position!.data)
        })}
      />
      <Form>
        <Loader isLoading={isSportsFetching || isPositionFetching}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} data-testid="POSITION_VIEW_NAME">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_VIEW_ABBR">
              <FormInput
                control={control}
                name="abbreviation"
                type="text"
                label="Abbreviation"
                required={true}
                disabled={true}
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 6,
                    message: "Abbreviation must be 6 characters or less"
                  }
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_VIEW_SPORT">
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                disabled={true}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_VIEW_PARENT">
              <FormSelect
                control={control}
                name="parentId"
                label="Parent Position"
                required={false}
                options={parentOptions}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!toDelete}
        title={
          toDelete?.hasAthletes ? "Cannot Delete Position" : "Delete Position?"
        }
        body={
          toDelete?.hasAthletes
            ? "This position cannot be deleted because it is currently assigned to one or more players within teams in SportsGravy."
            : `Are you sure you want to delete ${toDelete?.name}?`
        }
        close={() => setToDelete(null)}
        {...(toDelete?.hasAthletes
          ? {
              cancelBtnText: "Okay"
            }
          : {
              onConfirm: onConfirmDelete
            })}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
