import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";
import { Loader } from "@components/crud/Loader";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Edit } from "@mui/icons-material";
import { Box, Grid, styled, Typography } from "@mui/material";
import {
  useConfigGet,
  useConfigPut,
  useFaqCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
  padding-bottom: 16px;
`;

export const FSGOWebsiteSettings = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const {
    control,
    getValues,
    reset,
    formState: { isDirty }
  } = useForm({
    mode: "onTouched"
  });

  const { data: settings, isLoading: isLoading } = useConfigGet();

  const { mutate: save, isLoading: isSaving } = useConfigPut();

  const onSave = () => {
    const data = getValues();
    save(
      {
        data: [
          {
            key: "website.personal-pricing-category",
            value: data.personal
          },
          {
            key: "website.org-pricing-category",
            value: data.organization
          }
        ]
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setDisabled(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  const { data: catrgory, isLoading: categoryLoading } = useFaqCategoryGet({
    displayChannel: "WEBSITE"
  });

  const categoryOptions = useMemo(() => {
    return (
      catrgory?.data
        ?.filter(
          (category) => category._count?.faqs && category._count?.faqs > 1
        )
        .map((category) => ({
          label: category.name!,
          value: category.helpCategoryId!
        })) || []
    );
  }, [catrgory]);

  useEffect(() => {
    if (settings?.data) {
      const formData = {
        personal: settings.data.find(
          (setting) => setting.key === "website.personal-pricing-category"
        )?.value,
        organization: settings.data.find(
          (setting) => setting.key === "website.org-pricing-category"
        )?.value
      };
      reset(formData);
    }
  }, [settings, refreshKey]);

  return (
    <>
      <Grid
        item
        container
        direction="column"
        spacing="15px"
        paddingLeft="20px"
        paddingRight="20px"
      >
        <Loader isLoading={isLoading || categoryLoading}>
          <StyledContainer style={{ padding: "10px 0px 0 30px" }}>
            <Grid container spacing={3}>
              <Grid item xs={disabled ? 6 : 12} padding="0">
                <StyledTypography
                  style={{
                    color: "#2B337A",
                    opacity: 1
                  }}
                >
                  FAQ Details for Pricing Screens
                </StyledTypography>
              </Grid>
              {disabled && (
                <Grid container item xs={6} justifyContent="flex-end">
                  <Button
                    variant="admin-primary"
                    onClick={() => setDisabled(false)}
                    startIcon={<Edit />}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
            </Grid>
          </StyledContainer>
          <HeaderUnderLine marginLeft="30px" />

          <Grid container spacing={3} paddingLeft="30px" paddingTop="24px">
            <Grid item xs={6}>
              <StyledTypography>
                Pricing for Personal Use Screen
              </StyledTypography>
              <FormSelect
                label="FAQ Help Category"
                required
                name="personal"
                options={categoryOptions}
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTypography>
                Pricing for Sports Organizations Screen
              </StyledTypography>
              <FormSelect
                label="FAQ Help Category"
                required
                name="organization"
                options={categoryOptions}
                control={control}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Loader>
      </Grid>
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isDisabled={!isDirty || isSaving}
          isLoading={isSaving}
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => {
          setRefreshKey((prev) => prev + 1);
          setDisabled(true);
        }}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </>
  );
};
