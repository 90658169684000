import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useMediaBreakpointWidth } from "../hooks/useMediaBreakpointWidth";
import { Button } from "./Button";
import colors from "theme/colors";
import { CheckCircle } from "@mui/icons-material";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "24px",
    width: 600
  },
  "@media (max-width: 656px)": {
    "& .MuiPaper-root": {
      maxWidth: "100%"
    }
  }
}));

const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "right",
  width: "100%",
  padding: "8px 24px",
  gap: "16px",
  "& .MuiButtonBase-root": {
    minWidth: "120px"
  },
  "@media (max-width: 500px)": {
    gap: "10px",

    "& .MuiButtonBase-root": {
      padding: "8px 12px"
    },
    "& .MuiTypography-root": {
      fontSize: "11px"
    }
  }
}));

interface ConfirmationDialogProps {
  body: string | JSX.Element;
  cancelBtnText?: string;
  cancelBtnVariant?: string;
  close?: () => void;
  confirmBtnText?: string;
  confirmBtnVariant?: string;
  hideCancelBtn?: boolean;
  hideCloseIcon?: boolean;
  icon?: React.ReactNode | undefined;
  isConfirming?: boolean;
  onCancel?: () => void;
  onConfirm?: () => Promise<void> | void;
  onOpen?: () => void;
  open: boolean;
  title: string;
  additionalBtn?: React.ReactNode;
  width?: string;
}

const getIconFromName = (name: string) => {
  switch (name) {
    case "error":
      return <WarningAmberIcon sx={{ color: colors.error.icon }} />;
    case "warning":
      return <WarningAmberIcon sx={{ color: colors.warning.icon }} />;
    case "info":
      return <InfoOutlinedIcon sx={{ color: colors.info.icon }} />;
    case "success":
      return <CheckCircle sx={{ color: colors.success.main }} />;
  }
};

export const ConfirmationDialog = ({
  body,
  cancelBtnText = "No",
  cancelBtnVariant = "admin-secondary",
  close,
  confirmBtnText = "Yes",
  confirmBtnVariant = "admin-primary",
  hideCancelBtn = false,
  hideCloseIcon = false,
  icon = "info",
  isConfirming = false,
  onCancel,
  onConfirm,
  onOpen,
  open,
  title,
  width,
  additionalBtn
}: ConfirmationDialogProps) => {
  const dialogBreakpoint = useMediaBreakpointWidth();
  const cancelClickHandler = () => {
    onCancel && onCancel();
    close && close();
  };

  const acceptClickHandler = async () => {
    onConfirm && (await onConfirm());
    close && close();
  };

  useEffect(() => {
    if (open) {
      onOpen && onOpen();
    }
  }, [open]);
  return (
    <StyledDialog
      className="confirmation-dialog"
      open={open}
      disableScrollLock={true}
      maxWidth={dialogBreakpoint || "md"}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "24px",
          width: width || 600
        }
      }}
    >
      <Grid container sx={{ padding: "24px", flexWrap: "nowrap" }}>
        {icon && (
          <div style={{ paddingTop: "16px" }}>
            <div
              style={{
                background:
                  typeof icon === "string"
                    ? colors?.[icon]?.light
                    : colors.error.main,
                height: "40px",
                width: "40px",
                borderRadius: "99px",
                padding: "8px"
              }}
            >
              {icon && typeof icon === "string" ? getIconFromName(icon) : icon}
            </div>
          </div>
        )}
        <Grid
          xs={icon && !hideCloseIcon ? 10 : icon || !hideCloseIcon ? 11 : 12}
        >
          <DialogTitle variant="h3">{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{body}</Typography>
          </DialogContent>
        </Grid>
        {!hideCloseIcon && (
          <Grid xs={1} sx={{ paddingTop: "16px" }}>
            <CloseIcon
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main"
                }
              }}
              onClick={cancelClickHandler}
            />
          </Grid>
        )}
      </Grid>
      <DialogActions style={{ background: "#F3F4F7" }}>
        <ButtonContainer data-testid="CONFIRM_BUTTONS">
          {!hideCancelBtn && (
            <Button variant={cancelBtnVariant} onClick={cancelClickHandler}>
              {cancelBtnText}
            </Button>
          )}
          {additionalBtn}
          {onConfirm && (
            <Button
              variant={confirmBtnVariant}
              onClick={acceptClickHandler}
              isLoading={isConfirming}
              disabled={isConfirming}
            >
              {confirmBtnText}
            </Button>
          )}
        </ButtonContainer>
      </DialogActions>
    </StyledDialog>
  );
};
