import { Box, Button, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { useState } from "react";

const BasicText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  color: "#B3B3B3";
`;

export const ScoreScale = ({ onClick }: { onClick }) => {
  const [selectedScore, setSelectedScore] = useState<number | null>(null);
  const handleClick = (value: number) => {
    setSelectedScore(value);
    onClick(value);
  };
  return (
    <Grid container spacing={3} alignItems="center">
      {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
        <Grid key={value}>
          <Button
            variant="contained"
            onClick={() => handleClick(value)}
            sx={{
              minWidth: "34px",
              width: "34px",
              height: "34px",
              color: "#FFFFFF",
              backgroundColor:
                value <= 6
                  ? "#FD7573"
                  : value >= 7 && value <= 8
                    ? "#F9BE00"
                    : "#76CE1D",
              "&:hover": {
                backgroundColor:
                  value <= 6
                    ? "#e65c5c"
                    : value >= 7 && value <= 8
                      ? "#e6b853"
                      : "#57a957"
              },
              border: selectedScore === value ? "2px solid #2B337A" : "none",
              boxShadow:
                selectedScore === value
                  ? "0px 0px 6px rgba(0, 0, 0, 0.4)"
                  : "none"
            }}
          >
            {value}
          </Button>
        </Grid>
      ))}
      <Grid xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "-14px"
          }}
        >
          <BasicText>Not likely at all</BasicText>
          <BasicText>Extremely likely</BasicText>
        </Box>
      </Grid>
    </Grid>
  );
};
