import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { hasPermission } from "@services/Casbin";
import { getCrmSurveys } from "@services/Network";
import {
  ModelCustomerSurvey,
  useAdminCrmCustomerSurveyGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatDate } from "@utils/formatDate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const formatSubscriptionPeriod = (
  originalStartDate,
  originalEndDate,
  revisedStartDate,
  revisedEndDate
) => {
  const startDate = revisedStartDate ?? originalStartDate;
  let endDate = revisedEndDate ? revisedEndDate : originalEndDate;

  if (!originalEndDate && !revisedEndDate) {
    const start = new Date(startDate);
    start.setDate(start.getDate() + 1);
    endDate = start;
  }
  return `${formatDate(new Date(startDate))} to ${formatDate(new Date(endDate))}`;
};

export const formatCustomerType = (accountId, personId) => {
  return accountId != null ? "B2B" : personId != null ? "B2C" : "";
};

export const NpsAndCsat = () => {
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const handleView = (survey) => navigate(`/crm/nps-csat/${survey.surveyId}`);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      setPermissionsLoading(true);
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.accounts", "ADD");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const view = await checkPermission("crm.accounts", "VIEW");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const { data: surveys } = useAdminCrmCustomerSurveyGet();

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All Accounts",
      value: "ALL"
    },
    options: [
      {
        label: "All Accounts - Pending",
        value: "PENDING"
      },
      {
        label: "All Accounts - Completed",
        value: "COMPLETED"
      },
      ...(surveys?.data.numRows && surveys?.data.numRows > 0
        ? [
            {
              label: "My Accounts",
              value: "MINE"
            },
            {
              label: "My Accounts - Pending",
              value: "MINE_PENDING"
            },
            {
              label: "My Accounts - Completed",
              value: "MINE_COMPLETED"
            }
          ]
        : [])
    ]
  };

  const SURVEY_COLUMNS: GridColDef<ModelCustomerSurvey>[] = [
    {
      headerName: "Account/User",
      field: "account",
      minWidth: 210,
      flex: 1,
      renderCell: ({ row }) => row.account?.name
    },
    {
      headerName: "Customer Type",
      field: "customerType",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatCustomerType(row.accountId, row.personId)
    },
    {
      headerName: "Survey Type",
      field: "surveyType",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) => row.type
    },
    {
      headerName: "Survey Sent",
      field: "surveySent",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => formatDate(new Date(row.createdAt))
    },
    {
      headerName: "Survey Response",
      field: "surveyResponse",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => formatDate(new Date(row.filledAt))
    },
    {
      headerName: "Score",
      field: "score",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) => row.score
    },
    {
      headerName: "Subscription Period",
      field: "subscriptionPeriod",
      minWidth: 175,
      flex: 1,
      renderCell: ({ row }) => {
        const periodText = formatSubscriptionPeriod(
          row.order?.originalStartDate,
          row.order?.originalEndDate,
          row.order?.revisedStartDate,
          row.order?.revisedEndDate
        );

        const [startDate, endDate] = periodText.split(" to ");

        return (
          <div>
            {startDate} to <br /> {endDate}
          </div>
        );
      }
    }
  ];
  return (
    <Loader isLoading={isLoadingPermissions}>
      <TableView
        title="NPS & CSAT Surveys"
        useGet={getCrmSurveys}
        columns={SURVEY_COLUMNS}
        getRowId={(row) => row.surveyId}
        filterConfig={filterConfig}
        defaultSort={[{ field: "account", sort: "asc" }]}
        onView={permissions?.view ? handleView || undefined : undefined}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        getRowHeight={() => 66}
        pinnedColumns={{ left: ["action", "account"] }}
      />
    </Loader>
  );
};
