import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { LeadDetailsForm } from "./LeadDetailsForm";
import { Footer } from "@components/crud/Footer";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelDemoSession,
  ModelLead,
  useAdminCrmContactContactIdPut,
  useAdminCrmLeadLeadIdGet,
  useAdminCrmLeadLeadIdPut,
  usePutAdminCrmAccountAccountId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import formatFullName from "@utils/formatFullName";
import { FullName } from "@utils/types";
import { hasPermission } from "@services/Casbin";

export const LeadEdit = () => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const form = useForm({
    mode: "onTouched"
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.leads", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
      details: ContactResponse;
    }[]
  >([]);

  const {
    reset,
    getValues,
    formState: { isDirty, isValid, dirtyFields }
  } = form;

  const [leadOwner, setLeadOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { data: leadData, isLoading: isLoading } = useAdminCrmLeadLeadIdGet(
    leadId!
  );

  useEffect(() => {
    if (leadData?.data) {
      const lead = leadData.data as ModelLead & {
        demoDetail: ModelDemoSession;
        referralUrl?: string;
      };
      reset({
        name: lead.name,
        source: lead.source?.type,
        status: lead.status,
        leadOwner: lead.owner?.personId,
        account: {
          name: lead.account?.name || lead.demoDetail.organizationName,
          parent: lead.account?.parent?.name || "",
          category: lead.account?.category,
          type: lead.account?.type || lead.demoDetail?.organizationType,
          officeAddress:
            lead.account?.officeAddress ||
            (lead.demoDetail ? lead.demoDetail.address : ""),
          email: lead.account?.email || lead.demoDetail?.email,
          website: lead.account?.website || lead.demoDetail?.website,
          sportsOffered:
            lead.account?.sports?.map((sp) => sp.sportId) ||
            (lead.demoDetail?.sport
              ? lead.demoDetail?.sport?.map((s) => s.sportId)
              : []),
          noOfAthletes:
            lead.account?.numberOfAthletes || lead.demoDetail?.noOfAthletes,
          aeOwner: lead.account?.aeOwner?.person
            ? formatFullName(lead.account?.aeOwner?.person)
            : ""
        },
        contact: {
          name:
            formatFullName(lead.contact as FullName) ||
            lead.demoDetail?.primaryContact,
          workEmail: lead.contact?.email || lead?.demoDetail?.email,
          homeAddress: lead.contact?.location,
          phoneType: lead?.demoDetail?.phoneType || "MOB",
          whatsappNumber: lead.contact?.whatsappNumber,
          ...(!lead.contact?.phone && !lead.accountId
            ? {
                ...(lead.demoDetail.phoneType === "MOB"
                  ? {
                      phone: lead.demoDetail.phone
                    }
                  : {
                      workPhone: lead.demoDetail.phone
                    })
              }
            : {
                workPhone: lead.contact?.phone
              })
        },
        referralUrl: lead.referralUrl,
        interestInSG: lead.demoDetail?.message
      });
      setSelectedAccount(lead.accountId || "");
      setLeadOwner(lead.owner?.personId || "");
    }
  }, [leadData]);

  const { mutate: save, isLoading: isSaving } = useAdminCrmLeadLeadIdPut();
  const { mutate: accountEdit, isLoading: isEditingAccount } =
    usePutAdminCrmAccountAccountId();

  const { mutate: contactEdit, isLoading: isEditingContact } =
    useAdminCrmContactContactIdPut();

  const onSave = () => {
    const formvalues = getValues();
    const data = {
      name: formvalues.name,
      ownerId: leadOwner,
      source: {
        sourceId: leadData?.data?.sourceId,
        name: "",
        type: formvalues.source
      },
      status: formvalues.status,
      ...(leadData?.data?.accountId
        ? {
            accountId: selectedAccount,
            contactId: selectedContact
          }
        : {
            account: {
              name: formvalues.account?.name || "",
              ...(formvalues.account?.parent && {
                parent: formvalues.account?.parent
              }),
              category: formvalues.account?.category,
              type: formvalues.account?.type,
              officeAddress: formvalues.account?.officeAddress,
              email: formvalues.account?.email,
              website: formvalues.account?.website || undefined,
              sportsOffered: formvalues.account?.sportsOffered,
              aeOwnerId: formvalues.account?.aeOwner,
              noOfAthletes: formvalues.account?.noOfAthletes
                ? parseInt(formvalues.account?.noOfAthletes)
                : 0
            },
            contact: {
              name: formvalues.contact?.name,
              email: formvalues.contact?.personalEmail || undefined,
              homeAddress: formvalues.contact?.homeAddress || undefined,
              phone: formvalues.contact?.phone || undefined,
              whatsappNumber: formvalues.contact?.whatsappNumber || undefined,
              workPhone: formvalues?.contact?.workPhone || undefined,
              workEmail: formvalues?.contact?.workEmail || undefined,
              jobTitle: formvalues?.contact?.jobTitle || undefined
            }
          }),
      accountId: selectedAccount || undefined,
      contactId: selectedContact || undefined
    };

    if (dirtyFields["account"] && data.account)
      accountEdit({
        accountId: selectedAccount,
        data: data.account
      });
    if (dirtyFields["contact"] && data.contact) {
      const contact = data.contact;
      contactEdit({
        contactId: selectedContact,
        data: {
          ...contact,
          links: [],
          accounts: [
            {
              accountId: selectedAccount,
              isPrimary: true,
              jobTitle: contact.jobTitle,
              workEmail: contact.workEmail,
              workPhone: contact.workPhone,
              workPhoneType: "WORK"
            }
          ]
        }
      });
    }

    save(
      {
        leadId: leadId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Lead Edited successfully!", {
            variant: "success"
          });
          if (!isLeadQualifed) navigate("/crm/leads");
          else navigate(`/crm/opportunities/create?leadId=${leadId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Lead!", {
            variant: "error"
          });
        }
      }
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && leadData?.data && leadData.data.accountId) {
        try {
          const data =
            await getAdminCrmAccountAccountIdContact(selectedAccount);

          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || "",
              details: c.contact as ContactResponse
            }))
          );
          if (leadData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(leadData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, leadData]);

  const [isLeadQualifed, setIsLeadQualified] = useState(false);
  return (
    <Container>
      <Toolbar title="Edit Lead" />
      <Loader isLoading={isLoading}>
        <Form>
          <LeadDetailsForm
            form={form}
            leadOwner={leadOwner}
            setLeadOwner={setLeadOwner}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            isMQL={!leadData?.data?.accountId}
            mode="EDIT"
            setContacts={setContacts}
            setQualifiedLead={(val) => setIsLeadQualified(val)}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        saveBtnLabel={isLeadQualifed ? "Save & Add Opportunity" : "Save"}
        isDisabled={
          !isDirty ||
          !isValid ||
          isSaving ||
          isEditingAccount ||
          isEditingContact ||
          !selectedAccount ||
          selectedAccount === "" ||
          !selectedContact ||
          selectedContact === ""
        }
        isLoading={
          isLoading || isSaving || isEditingAccount || isEditingContact
        }
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/leads")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
