import { Form } from "@components/crud/Form";
import { FormInput } from "@components/FormInput";
import Grid from "@mui/system/Unstable_Grid";
import { useFormContext } from "react-hook-form";

export const NpsAndCsatDetailsForm = ({ mode }: { mode }) => {
  const { control } = useFormContext();

  return (
    <Form gap="24px">
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormInput
            name="account"
            label="Account/User"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            name="customerType"
            label="Customer Type"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormInput
            name="surveyType"
            label="Survey Type"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            name="subscriptionPeriod"
            label="Subscription Period"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormInput
            name="surveySent"
            label="Survey Sent"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            name="surveyResponse"
            label="Survey Response"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormInput
            name="score"
            label="Score"
            control={control}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormInput
            name="description"
            label="What is the primary reason for your score?"
            control={control}
            multiline={true}
            rows={4}
            type="text"
            disabled={mode === "VIEW"}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
