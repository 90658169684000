import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { FilterList } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MediaPhotosVideosFilterAtom, organizationAtom } from "@recoil/auth";
import { SkeletonFeedCommentLoader } from "@components/SkeletonFeedCommentLoader";
import {
  AdminMediaGetParams,
  useAdminAlbumGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { getDateRangeBounds } from "../../utils/GetDateRange";
import { GalleryItem } from "@pages/photos-videos/GalleryItem";
import { PhotosVideoFilterModal } from "@pages/photos-videos/PhotosVideosFilterModal";
import { useNavigate } from "react-router-dom";
import { AddToAlbum } from "@pages/live-stream/clips/AddToAlbum";
export const PAGE_SIZE = 10;
export const Albums = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [page, setPage] = useState(0);
  const [albumAdd, setAlbumAdd] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [filterState, setFilterState] = useState<
    | undefined
    | {
        category: string;
        options: { label: string; value: string; details? }[];
        selected: string[];
        queryKey: string;
      }[]
  >();
  const [filters, setFilters] = useState({});

  const albumGet = useAdminAlbumGet(
    {
      organizationId: organizationId!,
      ...(textSearch && textSearch !== "" ? { textSearch } : {}),
      ...filters
    },
    {
      query: {
        queryKey: [page, ...Object.entries(filters).flat(), textSearch],
        refetchOnWindowFocus: false
      }
    }
  );
  const setMediaPhotosVideosFilterAtom = useSetRecoilState(
    MediaPhotosVideosFilterAtom
  );
  const { data, isLoading, refetch } = albumGet;

  useEffect(() => {
    if (filterState) {
      const obj = {} as AdminMediaGetParams;
      filterState
        .filter((f) => f.selected.length)
        .map((f) => {
          if (f.queryKey !== "dtStart") obj[f.queryKey] = [...f.selected];
          else {
            const { dtStart, dtEnd } = getDateRangeBounds(f.selected);
            obj["dtStart"] = dtStart;
            obj["dtEnd"] = dtEnd;
          }
        });
      setPage(0);
      setFilters({ ...obj });
      setMediaPhotosVideosFilterAtom({ ...obj });
    }
  }, [filterState]);
  return (
    <Container>
      <Toolbar
        title="Albums"
        addBtnClick={() => setAlbumAdd(true)}
        addBtnLabel="Add"
      />

      <Form>
        <Grid container direction="row" marginBottom="20px" spacing="20px">
          <Grid item width="90%">
            <SearchInput
              placeholder="Search"
              onChange={(e) => setTextSearch(e.target.value)}
              required={false}
              InputProps={{
                sx: {
                  border: "1px solid #E5E5E5"
                }
              }}
            />
          </Grid>
          <Grid item width="10%">
            <Button
              variant="outlined"
              startIcon={<FilterList />}
              onClick={() => {
                setOpenFilter(true);
              }}
              style={{
                textTransform: "none",
                width: "100%",
                height: "50px",
                color: "#666666",
                backgroundColor:
                  filters && Object.keys(filters).length > 0
                    ? "#F3F4F7"
                    : "transparent",
                borderRadius: "6px",
                border:
                  filters && Object.keys(filters).length > 0
                    ? "1px solid #F3F4F7"
                    : "1px solid #E5E5E5"
              }}
            >
              Filter
            </Button>
          </Grid>
        </Grid>
        {data?.data && data.data.length > 0 && (
          <div
            className="gallery-grid"
            style={{
              marginTop: "10px"
            }}
          >
            {data.data.map((item) => (
              <GalleryItem
                key={item.albumId!}
                item={{
                  id: item.albumId!,
                  type: "ALBUM",
                  thumbnailSrc: item.media?.[0]?.baseUrl
                    ? item.media?.[0]?.baseUrl +
                      `${item.media?.[0]?.path?.replace(
                        ".m3u8",
                        ".0000001.jpg"
                      )}`
                    : "",
                  mediaDetails: item,
                  title: item.name,
                  count: item?._count?.media
                }}
                onClick={() => navigate(`/albums/${item.albumId}`)}
              />
            ))}
          </div>
        )}
        {isLoading && <SkeletonFeedCommentLoader type="media" />}
        {albumAdd && (
          <AddToAlbum
            type="CREATE_ALBUM"
            onClose={() => setAlbumAdd(false)}
            onSave={() => refetch()}
          />
        )}
        <PhotosVideoFilterModal
          open={openFilter}
          handleClose={(filters) => {
            setOpenFilter(false);
            setFilterState(filters);
          }}
          excludeFilters={["Videos/Photos"]}
          selectedFiterState={filterState}
        />
      </Form>
    </Container>
  );
};
