import { Button } from "@components/Button";
import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";

export const FeedbackResponse = ({ score }: { score: number }) => {
  const getSentence = (number) => {
    console.log(number);
    if (number >= 9 && number <= 10) {
      return "Thanks for your awesome feedback! Your support means the world to us.";
    } else if (number >= 7 && number < 9) {
      return "Thanks for sharing your thoughts. We're always looking for ways to improve your experience.";
    } else if (number < 7) {
      return "We appreciate you taking the time to share your honest feedback. Your input helps us improve.";
    } else {
      return "Invalid input.";
    }
  };

  const getResponse = (number) => {
    if (number >= 9 && number <= 10) {
      return "You're a Fan!";
    } else if (number >= 7 && number < 9) {
      return "Let's Improve Together";
    } else if (number < 7) {
      return "Your Feedback Matters";
    } else {
      return "Invalid input.";
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "300",
            marginBottom: 1,
            fontSize: "26px",
            color: "#000000"
          }}
        >
          {getResponse(score)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#000000",
            marginBottom: 2,
            fontWeight: "400",
            fontSize: "12px"
          }}
        >
          {getSentence(score)}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Button variant="primary" onClick={() => window.close()}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
};
