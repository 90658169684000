import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelCustomerSurvey,
  useAdminCrmCustomerSurveySurveyIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { NpsAndCsatDetailsForm } from "./components/NpsAndCsatDetailsForm";
import { formatCustomerType, formatSubscriptionPeriod } from "./NpsAndCsat";
import { formatDate } from "@utils/formatDate";
import { Loader } from "@components/crud/Loader";

export const ViewNpsAndCsat = () => {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;
  const { data: surveyData, isLoading: isLoading } =
    useAdminCrmCustomerSurveySurveyIdGet(surveyId!);
  const handleBack = () => {
    navigate("/crm/nps-csat");
  };

  useEffect(() => {
    if (surveyData?.data) {
      const survey = surveyData.data as ModelCustomerSurvey;
      reset({
        account: survey.account?.name,
        customerType: formatCustomerType(survey.accountId, survey.personId),
        surveyType: survey.type,
        subscriptionPeriod: formatSubscriptionPeriod(
          survey.order?.originalStartDate,
          survey.order?.originalEndDate,
          survey.order?.revisedStartDate,
          survey.order?.revisedEndDate
        ),
        surveySent: formatDate(new Date(survey.filledAt)),
        surveyResponse: formatDate(new Date(survey.createdAt)),
        score: survey.score,
        description: survey.scoreDescription
      });
    }
  }, [surveyData]);

  return (
    <Container>
      <Loader isLoading={isLoading}>
        <Toolbar title="View NPS Survey" backBtnClick={handleBack} />
        <FormProvider {...form}>
          <NpsAndCsatDetailsForm mode={"VIEW"} />
        </FormProvider>
      </Loader>
    </Container>
  );
};
